export const labels = {
  'FIRST NAME': 'FIRST NAME',
  'LAST NAME': 'LAST NAME',
  Gender: 'Gender',
  Age: 'Age',
  'Resident Country': 'Resident Country',
  EDUCATION: 'EDUCATION',
  'FIRST PRIORITY': 'FIRST PRIORITY',
  'SECOND PRIORITY': 'SECOND PRIORITY',
  'THIRD PRIORITY': 'THIRD PRIORITY',
  COMPANY: 'COMPANY',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  'CONFIRM PASSWORD': 'CONFIRM PASSWORD',
  'ROLE AT Organization': 'ROLE AT Organization',
  'ORGANIZATION TYPE': 'ORGANIZATION TYPE',
  'NUMBER OF PEOPLE': 'NO. OF PEOPLE',
  'INVESTMENT STAGE': 'INVESTMENT STAGE',
  'VIRTUAL WORKERS': '% OF VIRTUAL WORKERS',
  'CURRENT STATUS': 'CURRENT STATUS',
  'PREFERRED LANGUAGE': 'PREFERRED LANGUAGE',
  ETHNICITY: 'ETHNICITY / RACE',
  'Current Password': 'Current Password',
  Password: 'Password',
  'Confirm password': 'Confirm password',
  Organization: 'Organization',
  TEAM: 'TEAM',
  'NEW PASSWORD': 'NEW PASSWORD',
  'CONFIRM NEW PASSWORD': 'CONFIRM NEW PASSWORD',
  Title: 'Title',
  'Card Number': 'Card Number',
  'Expiration Date': 'Expiration Date',
  CVV: 'CVV',
}
export const placeholders = {
  Email: 'Email',
  Password: 'Password',
  'Remember Me': 'Remember Me',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Select if you are currently in a career transition':
    'Select if you are currently in a career transition',
  'Confirm password': 'Confirm password',
  Company: 'Company',
  Team: 'Team',
  Language: 'Language',
  Name: 'Name',
  Message: 'Message',
  'New Password': 'New Password',
  'Confirm New Password': 'Confirm New Password',
  Age: 'Age',
  Select: 'Select',
  'Select One': 'Select One',
  'Virtual Workers': 'Virtual Workers',
  'Current Password': 'Current Password',
  'Email Here': 'Email Here',
  'Eight Zeros': '0000 0000 0000 0000',
  MM: 'MM',
  YY: 'YY',
  '000': '000',
  Organization: 'Organization',
  Title: 'Title',
  'Have a Promo Code?': 'Have a Promo Code?',
  Loading: 'Loading...',
}
