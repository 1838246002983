import React, { useState, useEffect } from 'react'
import { useErrorService, FeatherLoader } from '@abroad/components'
import API from '../../utils/API'
import { ProgramModule } from '../../components'

const ContinueYourProgram = () => {
  const Error = useErrorService()
  const [module, setModule] = useState(null)
  const [isLoadingBreakthrough, setIsLoadingBreakthrough] = useState(true)

  useEffect(() => {
    const getCurrentStatus = async () => {
      try {
        const { data } = await API.programs.getCurrentStatus()
        if (data) {
          setModule(data)
        }
        setIsLoadingBreakthrough(false)
      } catch (e) {
        Error.showError(e)
        setIsLoadingBreakthrough(false)
      }
    }
    getCurrentStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoadingBreakthrough) {
    return (
      <div className='text-center my-2'>
        <FeatherLoader />
      </div>
    )
  }

  return (
    <>
      <div className='s3 mb-3 mt-32px'>Continue Your Program</div>
      <ProgramModule
        isLoading={isLoadingBreakthrough}
        program={module}
        programId={module?.parentId}
      />
    </>
  )
}

export default ContinueYourProgram
