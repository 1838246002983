import React, { useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { PlayIcon } from '@abroad/components'
import { LayoutContext, UpgradePlanModalContext } from '../../utils/contexts'
import { ABSwiperSlider } from '../slider'
import classNames from 'classnames'
import useStartMediaPlayer from './useStartMediaPlayer'

// todo - gtm
const MediaList = ({ category, rowMarginBottom = 'mb-40px' }) => {
  const { setContextJourneyId } = useContext(LayoutContext)
  const { setMediaParams } = useStartMediaPlayer({ disabledControls: false })

  const { onShow, setModalData } = useContext(UpgradePlanModalContext)

  const openUpgradePlanModal = () => {
    onShow()
    setModalData({
      header: `Unlock the Full \nAbroad Media Library`,
      subHeader: `You're exploring the tip of an insightful iceberg! Our media library is packed with bite-sized audios designed to enhance your understanding and cultivate growth. Enjoy unrestricted access to the entire library, diving into all 108 transformative audios by upgrading to a Growth Plan`,
      btnText: 'Upgrade To Growth Plan',
      plan: 'growth',
    })
  }

  return (
    <Container className={classNames('p-0', rowMarginBottom)} fluid>
      <Row className='g-0'>
        <Col className='g-0'>
          <div className='s3 mb-3'>{category.title}</div>
          <ABSwiperSlider containerClassName='card-swiper'>
            {category.medias.map((media) => {
              return (
                <div key={media.id}>
                  <div
                    className='cursor-pointer'
                    onClick={(e) => {
                      if (media.isUpgradeRequired) {
                        openUpgradePlanModal()
                        return
                      }
                      setContextJourneyId(null)
                      if (!media.isUpgradeRequired) {
                        setMediaParams({
                          mediaId: media.id,
                          mediaSubCategoryId:
                            media?.subCategory?._id || media?.subCategoryId,
                          isProgramMedia: media?.isProgram,
                        })
                      }
                    }}>
                    <div className='position-relative border-radius-10 overflow-hidden'>
                      <Imgix
                        className='lazyload border-radius-10 img-cover'
                        imgixParams={{
                          fit: 'crop',
                          crop: 'edges',
                          w: 400,
                          h: 400,
                          ar: '1:1',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}`}
                        width={'100%'}
                        alt='image'
                        attributeConfig={{
                          src: 'data-src',
                          srcSet: 'data-srcset',
                          sizes: 'data-sizes',
                        }}
                        htmlAttributes={{
                          src: `${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}?fit=crop&fill=blur&h=400&w=400&ar=1:1&blur=300&px=16&auto=format`, // low quality image here
                        }}
                      />
                      <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10 hover:bg-black-100 hover:bg-opacity-75 breakthrough-media-card'>
                        <PlayIcon className='play-icon' />
                      </div>
                    </div>
                  </div>
                  <div className='d-block mt-15px mb-3px'>
                    <div
                      className={classNames('s7 text-truncate', {
                        'cursor-pointer': media.isUpgradeRequired,
                      })}
                      onClick={() => {
                        if (media.isUpgradeRequired) {
                          openUpgradePlanModal()
                        }
                      }}>
                      {media.isUpgradeRequired && (
                        <span className='icon icon-padlock me-1 font-16 font-saffron-700' />
                      )}
                      {media.title}
                    </div>
                  </div>
                  <div className='s11 font-saffron-700'>
                    {Math.round(media.duration / 60)} minutes
                  </div>
                </div>
              )
            })}
          </ABSwiperSlider>
        </Col>
      </Row>
    </Container>
  )
}

export default MediaList
