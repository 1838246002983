import React from 'react'
import classNames from 'classnames'
import { Image } from 'react-bootstrap'
import AssessmentSampleImage from '../../assets/images/assessment-sample-image.png'

const AssessmentVideo = ({ videoClasses = '', isShowImage = true }) => {
  const src = ''
  return (
    <>
      {isShowImage ? (
        <div className='position-relative'>
          <Image
            width='100%'
            className='assessment-video img-fluid mb-52px'
            src={AssessmentSampleImage}
            alt='assessment-image'
          />
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='42'
            height='42'
            viewBox='0 0 42 42'
            className='video-play-icon'
            fill='none'>
            <path
              d='M28.6105 20.1479C29.2466 20.5382 29.2466 21.4625 28.6105 21.8527L18.4638 28.0771C17.7975 28.4859 16.9409 28.0064 16.9409 27.2247L16.9409 14.7759C16.9409 13.9942 17.7975 13.5148 18.4638 13.9235L28.6105 20.1479Z'
              fill='white'
            />
            <path
              d='M21 41C32.0457 41 41 32.0457 41 21C41 9.95431 32.0457 1 21 1C9.95431 1 1 9.95431 1 21C1 32.0457 9.95431 41 21 41Z'
              stroke='white'
              stroke-width='2'
            />
          </svg>
        </div>
      ) : (
        <video
          controls
          width='100%'
          className={classNames('assessment-video', videoClasses)}>
          <source src={src} type='video/mp4' />
          Sorry, your browser doesn't support embedded videos.
        </video>
      )}
    </>
  )
}

export default AssessmentVideo
