import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useErrorService } from '@abroad/components'
import moment from 'moment'

import { AbroadGoldenIcon, OnboardTitle } from '../components/onboarding'
import API from '../utils/API'
import { surveyType } from '../utils/constants'

const FetchingResult = ({ history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  let timeleft = 12
  useEffect(() => {
    const getSurveys = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.survey.getSurveys(
          'personal',
          surveyType.AWARENESS,
        ) // (surveyType, filterBy)
        if (data) {
          const surveys = data.surveys.slice(0, 5)
          for (let survey of surveys) {
            const seconds = moment(moment().toISOString()).diff(
              survey.submittedAt,
              'seconds',
            )
            if (seconds <= 86400) {
              history.replace(`/survey/${survey._id}?source=fetching-result`)
              break
            }
          }
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getSurveys()
    const interval = setInterval(function () {
      if (timeleft <= 3) {
        clearInterval(interval)
      }
      --timeleft
      getSurveys()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push('/survey')
    }, 60000)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main>
      <section>
        <Container fluid className='px-0'>
          <Row className='vh-100 row g-0'>
            <Col className='onboard bg-abroad-blue'>
              <div className='h-100 d-flex flex-column align-items-center text-center'>
                <AbroadGoldenIcon />
                <section className='generating-results px-lg-0 px-3 mt-lg-80px mt-32px text-start'>
                  <OnboardTitle className='mb-3'>
                    Generating Your Results!
                  </OnboardTitle>
                  <span className='s6 text-white'>
                    Thank you for completing the Awareness Assessment! We're
                    currently compiling your personalized insights. This usually
                    takes just a few seconds.
                  </span>
                  <div className='text-center mt-lg-80px mt-32px mb-40px'>
                    {isLoading && (
                      <Spinner
                        size='lg'
                        animation='border'
                        className='text-saffron-700 bt-spinner'
                        role='status'
                      />
                    )}
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default FetchingResult
