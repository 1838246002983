import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import TagManager from 'react-gtm-module'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  leaderAssessmentsUrl,
  simple360,
  nameRegex,
  useUserService,
  useNotificationService,
  useErrorService,
} from '@abroad/components'
import LeaderManagerEvent from '../../constants/events'
import { leader360Type } from '../../utils/constants'
import { emailRegex } from '../../utils/utility'
import API from '../../utils/API'
import AddNewRequestInput from '../survey/AddNewRequestInput'

const RequestFooterButtons = ({
  type,
  addNewInputs,
  disabledParticularInput,
  canAddMembers,
  canViewResult,
}) => {
  const { isValid, touched, values, handleSubmit } = useFormikContext()
  const source =
    type === leader360Type.SIMPLE360
      ? leader360Type.SIMPLE360
      : leader360Type.LEADER
  const maximumRequestsCreated = type === leader360Type.SIMPLE360 ? 50 : 15
  const [isDisabledAddNewInput, setIsDisabledAddNewInput] = useState(false)
  const [isDisabledRequestBtn, setIsDisabledRequestBtn] = useState(true)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { id } = useParams()
  const history = useHistory()
  const url = `/survey/requests/${leaderAssessmentsUrl}`
  const { user } = useUserService()
  const userId = user?.id
  const numberOfInputs = leader360Type.SIMPLE360 ? 1 : 3

  useEffect(() => {
    if (values) setIsDisabledBtn(false)
  }, [values])

  useEffect(() => {
    if (
      maximumRequestsCreated <= values?.[source]?.length ||
      !canAddMembers ||
      canViewResult
    ) {
      setIsDisabledAddNewInput(true)
    } else {
      setIsDisabledAddNewInput(false)
    }
  }, [
    isValid,
    values,
    source,
    maximumRequestsCreated,
    canAddMembers,
    canViewResult,
  ])

  useEffect(() => {
    if (!isValid && values?.[source]?.length <= numberOfInputs) {
      setIsDisabledRequestBtn(true)
    } else {
      setIsDisabledRequestBtn(false)
    }
  }, [isValid, source, values, numberOfInputs])

  const filterUserRequestData = () => {
    const validUserData = values?.[source]?.filter(
      (user) =>
        user.firstName.match(nameRegex) &&
        user.email.match(emailRegex) &&
        !user.hasOwnProperty('_id'),
    )
    if (validUserData.length === 0) return []
    return validUserData
  }

  const disabledValidInputs = (data) => {
    for (let i = 0; i < data.length; i++) {
      const index = values?.[source]?.findIndex(
        (user) =>
          user.firstName === data[i].firstName && user.email === data[i].email,
      )
      disabledParticularInput(index, {
        firstName: data[i].firstName,
        email: data[i].email,
        _id: index,
        ...(type === leader360Type.LEADER && {
          hasRequestSent: true,
        }),
      })
    }
  }

  const foundDuplicateEmail = () => {
    const getDuplicateEmailRecord = values?.[source]?.reduce((a, e) => {
      a[e.email] = ++a[e.email] || 0
      return a
    }, {})

    const duplicateEmailObject = values?.[source]?.filter(
      (e) =>
        getDuplicateEmailRecord[e.email] &&
        e.email.match(emailRegex) &&
        e.firstName.match(nameRegex),
    )

    if (duplicateEmailObject.length > 0) {
      Notification.showNotification(
        'You seem to have provided same email more than once. Please provide unique email addresses.',
        'danger',
      )
      return true
    }
    return false
  }

  const updateUsersRequests = (data) => {
    if (type === leader360Type.SIMPLE360) {
      return API.survey.sendRequestedUsers({
        type: '360',
        surveyRequestData: data,
      })
    }
    if (id === 'new') {
      return API.survey.newLeaderRequests({ requestData: data })
    } else {
      return API.survey.updateLeaderRequests(id, { requestData: data })
    }
  }

  const gtmSendRequestAction = () => {
    if (type === leader360Type.SIMPLE360) {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: LeaderManagerEvent.event.submitManagerRequest,
          eventProps: {
            category: LeaderManagerEvent.category.submitLARequestCategory,
            action: `User send ${simple360?.toLowerCase()} request`,
            label: `Send ${simple360?.toLowerCase()} request`,
            value: `User send ${simple360?.toLowerCase()} request.`,
            userId,
          },
        },
      })
    } else {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: LeaderManagerEvent.event.submitLARequest,
          eventProps: {
            category: LeaderManagerEvent.category.submitManagerRequestCategory,
            action: 'User send leader assessment request',
            label: 'Send leader assessment request',
            value: `User send leader assessment request.`,
            userId,
          },
        },
      })
    }
  }

  const checkNewFieldsAreEmpty = () => {
    const isMatch = values?.[source]?.find(
      (data) =>
        (data?.email !== '' || data?.firstName !== '') &&
        !data.hasOwnProperty('_id'),
    )
    return isMatch ? false : true
  }

  const chekcAnyFormInputIsInvalid = () => {
    const isMatch = values?.[source]?.find(
      (data) =>
        (data?.email === '' ||
          !data?.email.match(emailRegex) ||
          data?.firstName === '' ||
          !data?.firstName.match(nameRegex)) &&
        !(data?.email === '' && data?.firstName === ''),
    )
    return isMatch
  }

  const SendUserRequestshandle = async () => {
    if (type === leader360Type.LEADER && canViewResult) {
      // push user to view leader survey results
      history.push(`/survey/leader/${id}`)
      return
    }
    if (
      values?.[source]?.length > maximumRequestsCreated ||
      isDisabledRequestBtn
    )
      return

    const hasDuplicateEmail = foundDuplicateEmail()

    if (hasDuplicateEmail) return

    handleSubmit()

    const newFieldsAreEmpty = checkNewFieldsAreEmpty()

    if (newFieldsAreEmpty) return

    const isAnyInvalidInput = chekcAnyFormInputIsInvalid()

    if (isAnyInvalidInput) {
      Notification.showNotification(
        'Please provide valid name and email',
        'danger',
      )
      return
    }

    const surveyRequestData = filterUserRequestData()
    if (
      (Array.isArray(surveyRequestData) && surveyRequestData.length === 0) ||
      !surveyRequestData
    ) {
      Notification.showNotification(
        'Please provide valid name and email',
        'danger',
      )
      return
    }
    setIsLoading(true)
    try {
      const { data } = await updateUsersRequests(surveyRequestData)
      if (data) {
        disabledValidInputs(surveyRequestData)
        setIsDisabledBtn(true)
        setIsLoading(false)
        Notification.showNotification(
          `${
            type === leader360Type.SIMPLE360 ? simple360 : 'Leader'
          } requests have been sent`,
          'success',
        )
        gtmSendRequestAction()
        if (type === leader360Type.LEADER && id === 'new') {
          history.replace(`${url}/${data.id}`)
        }
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  return (
    <div className='d-flex justify-content-between mb-3 w-100 mt-4 pe-2'>
      <AddNewRequestInput
        type={type}
        addNewInputs={addNewInputs}
        isDisabled={isDisabledAddNewInput}
      />
      <Button
        variant='saffron'
        className={classNames('border-radius-normal border-none', {
          'me-2': type === leader360Type.SIMPLE360,
        })}
        disabled={
          canViewResult
            ? false
            : isDisabledRequestBtn ||
              isLoading ||
              Object.keys(touched).length === 0 ||
              !canAddMembers ||
              isDisabledBtn
        }
        onClick={SendUserRequestshandle}
        isLoading={isLoading}>
        {type === leader360Type.LEADER && canViewResult
          ? 'view results'
          : 'send requests'}
      </Button>
    </div>
  )
}

export default RequestFooterButtons
