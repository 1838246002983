import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import {
  PlayIcon,
  JournalIcon,
  useUserService,
  useErrorService,
  ABSpinner,
} from '@abroad/components'
import {
  JournalModal,
  ModuleInfo,
  NextModuleBtn,
  MediaControlBarMiddle,
} from '../components'
import { LayoutContext } from '../utils/contexts'
import API from '../utils/API'
import {
  checkIsProgramAudio,
  getProgramName,
  isLGScreen,
} from '../utils/utility'
import TrackAudioPlayer from '../constants/events'

const Media = ({ setBannerImage, setCategory, handleClosePage }) => {
  let { categoryId, mediaId, mediaCategory } = useParams()
  const {
    mediaObject,
    setShowPlayBar,
    setMediaObject,
    setContextJourneyId,
    setContextJourneyAudioId,
    setIsJourneyModuleAudio,
    setJourneyAudioType,
    setJourneyGroupId,
  } = useContext(LayoutContext)
  const Error = useErrorService()
  let getMediaId = useRouteMatch('/media/:mediaCategory/:categoryId/:mediaId')
  const isMediaLibraryAudio = getMediaId?.params?.mediaCategory === 'category'
  const [showJournalModal, setShowJournalModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [moduleInfo, setModuleInfo] = useState(null)
  const [planUpgradeRequire, setPlanUpgradeRequire] = useState(false)
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const source = query.get('source')
  const urlJourneyId = query.get('journeyId')
  const urlJourneyAudioId = query.get('sequenceId')
  const urlJourneyGroupId = query.get('journeyGroupId')
  const { user } = useUserService()
  const isProgramAudio = checkIsProgramAudio(moduleInfo?.category?.key)
  const programName = getProgramName(moduleInfo?.category?.key)

  useEffect(() => {
    if (urlJourneyId) {
      setContextJourneyId(urlJourneyId)
    }
    if (urlJourneyAudioId) {
      setContextJourneyAudioId(urlJourneyAudioId)
    }
    if (urlJourneyGroupId) {
      setJourneyGroupId(urlJourneyGroupId)
    }
  }, [
    urlJourneyId,
    urlJourneyAudioId,
    setContextJourneyId,
    setContextJourneyAudioId,
    urlJourneyGroupId,
    setJourneyGroupId,
  ])

  const getMedia = () => {
    setIsLoading(true)
    if (mediaCategory === 'program') {
      return API.programs.getMediaById(
        categoryId,
        mediaId,
        urlJourneyId,
        urlJourneyAudioId,
      )
    } else {
      return API.mediaLibrary.getMediaById(
        categoryId,
        mediaId,
        urlJourneyId,
        urlJourneyAudioId,
      )
    }
  }

  useEffect(() => {
    const getMediaData = async () => {
      if (mediaObject && mediaId !== mediaObject.id) {
        try {
          const { data } = await getMedia()
          if (data) {
            if (!data?.journey?.sequenceId) setContextJourneyId(null)
            setIsJourneyModuleAudio(data?.journey?.type === 'module')
            setJourneyAudioType(data?.journey?.journeyType)
            setModuleInfo(data)
            setBannerImage(
              checkIsProgramAudio(data?.category?.key)
                ? data?.subCategory.imageFilename
                : data?.imageFilename,
            )
            setCategory(data?.category._id)
          }
          setPlanUpgradeRequire(false)
          setIsLoading(false)
        } catch (e) {
          if (
            e?.code === 'ValidationError' ||
            e?.code === 'resource_not_found' ||
            e?.code === 'not_found' ||
            e?.code === 'media_not_found'
          ) {
            history.replace('/404')
          } else if (
            e?.code === 'previous_media_not_completed' ||
            e?.code === 'previous_module_not_completed'
          ) {
            Error.showError(e)
            if (mediaCategory === 'program') {
              history.push('/breakthrough')
            }
          } else if (e?.code === 'upgrade_plan') {
            setIsLoading(false)
            setPlanUpgradeRequire(true)
          } else if (e?.code === 'not_taken_survey') {
            history.push('/home')
            Error.showError(e)
          } else {
            setIsLoading(false)
            Error.showError(e)
          }
        }
      } else if (
        mediaObject &&
        mediaObject.id === mediaId &&
        mediaObject.subCategory._id === categoryId
      ) {
        setShowJournalModal(false)
        setModuleInfo(mediaObject)
        setBannerImage(
          checkIsProgramAudio(mediaObject?.category?.key)
            ? mediaObject?.subCategory.imageFilename
            : mediaObject?.imageFilename,
        )
        setCategory(mediaObject?.category._id)
      } else {
        try {
          const { data } = await getMedia()
          if (data) {
            if (!data?.journey?.sequenceId) setContextJourneyId(null)
            setIsJourneyModuleAudio(data?.journey?.type === 'module')
            setJourneyAudioType(data?.journey?.journeyType)
            if (source === 'session') {
              setMediaObject({ ...data, disabledControls: true })
            } else {
              setMediaObject(data)
            }
            setModuleInfo(data)
            setBannerImage(
              checkIsProgramAudio(data?.category?.key)
                ? data?.subCategory.imageFilename
                : data?.imageFilename,
            )
            setCategory(data?.category._id)
          }
          setPlanUpgradeRequire(false)
          setIsLoading(false)
        } catch (e) {
          if (
            e?.code === 'ValidationError' ||
            e?.code === 'resource_not_found' ||
            e?.code === 'not_found' ||
            e?.code === 'media_not_found'
          ) {
            history.replace('/404')
          } else if (
            e?.code === 'previous_media_not_completed' ||
            e?.code === 'previous_module_not_completed'
          ) {
            Error.showError(e)
            if (mediaCategory === 'program') {
              history.push('/breakthrough')
            }
          } else if (e?.code === 'upgrade_plan') {
            setIsLoading(false)
            setPlanUpgradeRequire(true)
          } else if (e?.code === 'not_taken_survey') {
            history.push('/home')
            Error.showError(e)
          } else {
            setIsLoading(false)
            Error.showError(e)
          }
        }
      }
    }

    if (mediaId && categoryId) {
      getMediaData()
    } else {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, mediaId])

  const onJournalDataChange = (data, id) => {
    setModuleInfo((prev) => {
      if (prev?.id === id) {
        return { ...prev, journal: data }
      }
      return prev
    })
  }

  useEffect(() => {
    if (planUpgradeRequire) {
      setShowPlayBar(false)
      history.push('/home')
    }
  }, [planUpgradeRequire, history, setShowPlayBar])

  const favoritesAPI = () => {
    if (checkIsProgramAudio(moduleInfo?.category?.key)) {
      return API.programs.favoriteModuleAudio(
        moduleInfo.subCategory._id,
        moduleInfo.id,
        {
          isFavorite: !moduleInfo.isFavorite,
        },
      )
    } else {
      return API.mediaLibrary.toggleFavorite(
        {
          isFavorite: !moduleInfo.isFavorite,
        },
        moduleInfo.id,
      )
    }
  }

  const toggleIsFavorite = async () => {
    try {
      const { data } = await favoritesAPI()
      if (data) {
        setModuleInfo({
          ...moduleInfo,
          isFavorite: !moduleInfo.isFavorite,
        })

        if (mediaObject?._id === moduleInfo?._id) {
          setMediaObject({
            ...mediaObject,
            isFavorite: !mediaObject.isFavorite,
          })
        }

        //GTM code
        TagManager.dataLayer({
          dataLayer: {
            event: TrackAudioPlayer.event.media,
            eventProps: {
              category: TrackAudioPlayer.category.mediaCategory,
              action: 'User like/dislike media',
              label: 'Audio like/dislike',
              value: `isFavorite - ${!moduleInfo.isFavorite} of  mediaID - ${
                moduleInfo.id
              } from Audio player`,
              userId: user?.id,
            },
          },
        })
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{'Media | Abroad'}</title>
        <meta name='title' content={'Media | Abroad'}></meta>
        <meta property='og:title' content={'Media | Abroad'}></meta>
        <meta property='twitter:title' content={'Media | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/media/${mediaCategory}/${categoryId}/${mediaId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/media/${mediaCategory}/${categoryId}/${mediaId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/media/${mediaCategory}/${categoryId}/${mediaId}`}></meta>
      </Helmet>
      <Container>
        <ModuleInfo
          type={isProgramAudio ? moduleInfo?.subCategory?.subTitle : ''}
          title={moduleInfo?.title}
          description={moduleInfo?.description}
          author={moduleInfo?.expert}
          handleClosePage={() =>
            handleClosePage(urlJourneyId, urlJourneyAudioId)
          }
          programName={programName}
        />

        {mediaId !== mediaObject?.id && !isLGScreen && (
          <>
            <Row className='justify-content-center mt-5 media-action'>
              <Col className='col-12 col-lg-4 text-center space-y-12'>
                <PlayIcon
                  onClick={() => {
                    if (source === 'session') {
                      setMediaObject({
                        ...moduleInfo,
                        disabledControls: true,
                        source: 'srq',
                      })
                    } else {
                      setMediaObject(moduleInfo)
                    }
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className='d-none mt-5 d-lg-flex media-action media-action-content'>
          {!moduleInfo?.isExtra && (
            <div>
              {moduleInfo?.isFavorite ? (
                <span
                  className='icon icon-fav font-22 cursor-pointer text-white'
                  onClick={toggleIsFavorite}
                />
              ) : (
                <span
                  className='icon icon-fav-outline text-white cursor-pointer font-22'
                  onClick={toggleIsFavorite}
                />
              )}
            </div>
          )}
          <div>
            <div
              onClick={() => setShowJournalModal(true)}
              className='d-flex align-items-center cursor-pointer space-y-1'>
              <JournalIcon />
            </div>
          </div>
        </Row>
        <Row className='mt-5 mt-lg-0 justify-content-center flex-grow-1 overflow-auto d-lg-none d-block media-action'>
          <Col className='col-12 col-lg-4'>
            <Row className='media-action-content'>
              <div>
                <span className='icon icon-fav font-22 cursor-pointer text-white' />
              </div>
            </Row>
            <JournalModal
              show={showJournalModal}
              onHide={() => {
                setShowJournalModal(false)
              }}
              journalData={moduleInfo?.journal}
              mediaId={moduleInfo?.id}
              onJournalDataChange={(data, id) => {
                onJournalDataChange(data, id)
              }}
              closingQuestion={
                moduleInfo?.closingQuestion || moduleInfo?.question
              }
              isMediaLibraryAudio={isMediaLibraryAudio}
            />
          </Col>
        </Row>
        {mediaId && categoryId && (
          <MediaControlBarMiddle
            moduleInfo={moduleInfo}
            showJournalModal={showJournalModal}
          />
        )}
        {checkIsProgramAudio(moduleInfo?.category?.key) &&
          !urlJourneyId &&
          mediaObject?.isLast && (
            <Row className='align-items-lg-end mb-lg-5 pb-lg-3 flex-grow-1'>
              <Col className='text-center text-lg-right mx-lg-5 pb-4'>
                <NextModuleBtn
                  activeAudioFromSamePage={
                    mediaId === mediaObject?.id || mediaObject?.isLast
                  }
                />
              </Col>
            </Row>
          )}
      </Container>
    </>
  )
}

export default Media
