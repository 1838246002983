import React, { useCallback, useContext, useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { Card, Spinner } from 'react-bootstrap'
import { ModelContext, useErrorService } from '@abroad/components'
import moment from 'moment'
import { secondsToMin, isSafari } from '../../utils/utility'
import API from '../../utils/API'
import { MediaPlayPauseIcon } from '../assetsComponents'
import { LayoutContext } from '../../utils/contexts'

const SanitizeHtml = loadable.lib(() =>
  import(/* webpackChunkName: "sanitizeHtml" */ 'sanitize-html'),
)

const GlobalJournalCard = ({
  journalObj,
  setMediaUrl,
  journalAudioEleId,
  loadedAudioId,
  setLoadedAudioId,
  mediaMetadata,
}) => {
  const { onShow, setModalData } = useContext(ModelContext)

  const { setShowPlayBar } = useContext(LayoutContext)

  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()

  const [audio, setAudio] = useState()
  const [clickedTime, setClickedTime] = useState()
  const [bufferSize, setBufferSize] = useState(0)
  const [duration, setDuration] = useState()
  const [tempTime, setTempTime] = useState()
  const [curTime, setCurTime] = useState()

  const getJournalDescription = (data = '') => {
    return (
      <SanitizeHtml fallback={'Loading...'}>
        {({ default: sanitizeHtml }) =>
          sanitizeHtml(data, {
            allowedTags: [],
            allowedAttributes: {},
          })?.slice(0, 130)
        }
      </SanitizeHtml>
    )
  }

  const progressHandler = (event) => {
    if (audio?.buffered?.length > 0) {
      setBufferSize(
        (100 * audio.buffered.end(audio.buffered.length - 1)) / audio.duration,
      )
    }
  }

  const canPlayHandler = (event) => {
    if (!audio) return
    const promise = audio.play()
    if (promise !== undefined) {
      promise
        .then((_) => {
          if (audio && !isSafari) {
            audio.play()
          }
        })
        .catch((err) => {
          // Auto play disabled error
        })
    }
  }

  const setAudioTime = useCallback(
    (e) => {
      if (tempTime) {
        setCurTime('tempTime', tempTime)
      } else if (e.target.currentTime !== 0) {
        setCurTime(e.target.currentTime)
      }
      // setTempTime(null)
    },
    [tempTime, setCurTime],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const errorHandler = (e) => {
    if (!isLoading) {
      setTempTime(audio.currentTime)
      getSignedUrl()
    }
  }

  const audioEndedHandler = useCallback(() => {
    audio.pause()
    setAudio(null)
    setLoadedAudioId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio])

  useEffect(() => {
    if (audio) {
      audio.addEventListener('error', errorHandler)
    }
    return () => {
      if (audio) {
        audio.removeEventListener('error', errorHandler)
      }
    }
  }, [audio, errorHandler])

  useEffect(() => {
    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime
      setClickedTime(0)
    }
  }, [audio, clickedTime, curTime])

  useEffect(() => {
    if (audio) {
      // audio.autoPlay = true
      // audio.playsInline= true
      audio.volume = 0.8
      audio.loop = false
      try {
        setDuration(audio.duration)
        audio.addEventListener('canplaythrough', canPlayHandler)
        audio.addEventListener('progress', progressHandler)
        audio.addEventListener('ended', audioEndedHandler)
      } catch (err) {
        // error
      }
    }
    return () => {
      if (audio) {
        audio.removeEventListener('canplaythrough', canPlayHandler)
        audio.removeEventListener('progress', progressHandler)
        audio.removeEventListener('ended', audioEndedHandler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio])

  useEffect(() => {
    let setAudioData

    if (audio) {
      try {
        setAudioData = (e) => {
          setDuration(e.target.duration)
          if (tempTime) {
            // && mediaObject.id === e.target.dataset.id
            audio.currentTime = tempTime
          }
          setTempTime(null)
        }

        audio.addEventListener('timeupdate', setAudioTime)
        audio.addEventListener('loadeddata', setAudioData)
      } catch (err) {
        // error
      }
    }
    return () => {
      if (audio) {
        audio.removeEventListener('loadeddata', setAudioData)
        audio.removeEventListener('timeupdate', setAudioTime)
      }
    }
  }, [audio, tempTime, setAudioTime, setTempTime, setDuration])

  const getSignedUrl = useCallback(async () => {
    try {
      if (audio) {
        audio.pause()
        setLoadedAudioId(null)
      }
      setIsLoading(true)
      const { data } = await API.journal.getGlobalJournalsSignedURL(
        journalObj._id,
      )
      setIsLoading(false)
      setLoadedAudioId(journalObj._id)
      setMediaUrl(data.url)
      const bottomAudioPlayer = document.getElementById('audio')
      if (bottomAudioPlayer && !bottomAudioPlayer.paused) {
        bottomAudioPlayer.pause()
      }
      setShowPlayBar(false)
      const journalAudioEle = document.getElementById(journalAudioEleId)
      setAudio(journalAudioEle)
      journalAudioEle.load()
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (journalObj.type === 'audio') {
    return (
      <>
        {loadedAudioId === journalObj._id ? (
          <Card
            className='bg-abroad-blue border-0 border-radius-10 cursor-pointer h-100'
            onClick={() => {}}>
            <Card.Body className='px-4 py-3 d-flex align-items-center'>
              <JournalMediaPlayer
                curTime={curTime}
                duration={duration}
                setClickedTime={(time) => setClickedTime(time)}
                bufferSize={bufferSize}
                identifier={journalObj._id}
                isLoading={isLoading}
                stopPlayer={() => {
                  audio.pause()
                  setAudio(null)
                  setLoadedAudioId(null)
                }}
              />
            </Card.Body>
          </Card>
        ) : (
          <Card
            className='bg-abroad-blue border-0 border-radius-10 cursor-pointer'
            onClick={getSignedUrl}>
            <Card.Body className='px-4 py-3 space-y-3'>
              <Card.Title
                className={
                  's10a d-flex justify-content-between font-saffron-300'
                }>
                {moment(journalObj.createdAt).format('MMM Do YYYY')}
              </Card.Title>
              <Card.Text className='s6 font-white d-flex justify-content-between align-items-start'>
                {journalObj?.title || journalObj?.media?.title}
                <div className='d-flex align-items-center space-x-3'>
                  <span className='s7 font-gray-100 opactiy-50'>
                    {secondsToMin(journalObj.duration)}
                  </span>
                  {isLoading ? (
                    <Spinner
                      animation='border'
                      size='sm'
                      className={'font-saffron-700'}
                      role='status'
                    />
                  ) : (
                    <span className='icon icon-play font-saffron-300 s7' />
                  )}
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        )}
      </>
    )
  }

  return (
    <Card
      className='custom-gray-bg-100 border-0 border-radius-10 cursor-pointer'
      onClick={() => {
        onShow()
        setModalData({ ...journalObj })
      }}>
      <Card.Body className='px-4 py-3 space-y-3'>
        <Card.Title
          className={'s10a d-flex justify-content-between font-saffron-700'}>
          {moment(journalObj.createdAt).format('MMM Do YYYY')}
        </Card.Title>
        <Card.Text className='s6 font-black'>
          {getJournalDescription(journalObj?.journal)}
          {(journalObj?.media?.title || mediaMetadata?.title) && (
            <div className='mt-3'>
              Journal Logged from -{' '}
              <span className='font-saffron-700'>
                {journalObj?.media?.title || mediaMetadata?.title}
              </span>
            </div>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const JournalMediaPlayer = ({
  curTime,
  duration,
  setClickedTime,
  bufferSize,
  identifier,
  isLoading,
  stopPlayer,
}) => {
  const [hover, setHover] = useState(false)

  const bufferColor = '#6F6F6F'
  const remainingBarColor = '#6F6F6F'
  const curPercentage = (curTime / duration) * 100

  const toggleHover = () => {
    setHover(!hover)
  }

  const formatDuration = (duration) => {
    return moment.duration(duration, 'seconds').format('mm:ss', { trim: false })
  }

  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX
    let bar = document.querySelector(`#media-control-progress-bar${identifier}`)
    if (!bar) {
      bar = document.querySelector(`#media-control-progress-bar-2${identifier}`)
    }
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
    return timePerPixel * clickPositionInBar
  }

  const handleTimeDrag = (e) => {
    setClickedTime(calcClickedTime(e))

    const updateTimeOnMove = (eMove) => {
      setClickedTime(calcClickedTime(eMove))
    }

    document.addEventListener('mousemove', updateTimeOnMove)

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove)
    })
  }

  if (isLoading) {
    return (
      <Spinner
        animation='border'
        size='sm'
        className={'font-saffron-700'}
        role='status'
      />
    )
  }

  return (
    <>
      <MediaPlayPauseIcon
        showPlay={false}
        isLoading={false}
        // isLoading={audio?.readyState < 3 || isLoading}
        onClick={stopPlayer}
      />
      <div className='bar ms-3'>
        <span className='s11 text-white opacity-50'>
          {formatDuration(curTime)}
        </span>
        <div
          className='bar-progress'
          id={`media-control-progress-bar${identifier}`}
          style={{
            background: `linear-gradient(to right, rgba(255,255,255,0.30) ${bufferSize}%, ${remainingBarColor} 0), linear-gradient(to right, ${
              hover ? '#EFC030' : 'white'
            } ${curPercentage}%, ${bufferColor} 0)`,
          }}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          onMouseDown={handleTimeDrag}>
          <span
            className='bar-progress-knob'
            style={{ left: `calc(${curPercentage}% - 7px)` }}
          />
        </div>
        {/* <span className='s11 opacity-50'>{formatDuration(duration)}</span> */}
      </div>
    </>
  )
}

export default GlobalJournalCard
