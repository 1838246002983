const gtm = {
  event: {
    breakthroughModule: 'breakthrough_like_dislike',
    evolutionModule: 'evolution_like_dislike',
    resilienceModule: 'resilience_like_dislike',
    media: 'media_like_dislike',
    login: 'login',
    signUp: 'signup',
    audioPlayer: 'audio_controls',
    transcriptDownload: 'transcript_file_download',
    freeUserTakeAssessment: 'first_assessment_taken',
    TakeAssessment: 'take_assessment',
    AddNewInsight: 'add_new_insight',
    AddNewIntention: 'add_new_intention',
    singleMediaOfMudule: 'module_audio',
    singleMediaOfLibrary: 'listen_audio',
    journalDataSave: 'save_journal',
    help: 'submit_question',
    profile: 'profile_update',
    resetPassword: 'reset_password',
    resendEmail: 'resend_email',
    updatePassword: 'update_password',
    compareAssessment: 'compare_assessment',
    nextModule: 'next_module',
    learnMore: 'learn_more',
    listenIntro: 'listen_intro_module',
    cookie: 'cookie_accept',
    payment: 'plan_upgrade',
    slider: 'growth_plan_slider',
    recommendedAudio: 'recommended_audio',
    applyPromoCode: 'apply_promocode',
    ListenPSPAudio: 'listen_session_practices_audio',
    deleteIntention: 'delete_intention',
    purposeVision: 'purpose_vision',
    valuesPrinciples: 'values_principles',
    notifications: 'notifications',
    unreadNotifications: 'read_particular_notification',
    laResult: 'la_result',
    laRequestForm: 'la_request_form',
    managerRequestForm: 'manager_feedback_request_form',
    selectPendingLA: 'select_pending_la',
    submitLARequest: 'submit_la_request',
    submitManagerRequest: 'submit_direct_manager_feedback_request',
    sessionDetails: 'session_details',
    sessionKeySkills: 'session_key_skills',
    userJourneysAudio: 'coach_recommends_audio',
    //orgJourneysAudio: 'org_journeys_audio',
    groupJourneysAudio: 'group_coach_recommends_audio',
    favoriteModule: 'favorite_module',
    favoriteAudio: 'favorite_audio',
    practicalOrPoeticAudio: 'practical_or_poetic_audio',
    keySkills: 'key_skills',
    moduleAudio: 'module_audio_like_dislike',
    favoritedAudioDislike: 'favorited_audio_dislike',
    surveyCategoryDetails: 'survey_category_details',
    assessmentRecommendedAudio: 'assessment_recommended_audio',
    laLearnMoreBtn: 'la_learn_more',
    moduleFavorite: 'module_like_dislike',
  },
  category: {
    breakthroughModuleCategory: 'Breakthrough Module',
    evolutionModuleCategory: 'Evolution Module',
    resilienceModuleCategory: 'Resilience Module',
    mediaCategory: 'User like/dislike media',
    loginCategory: 'User Sign In successfully',
    signUpCategory: 'User Sign Up successfully',
    audioPlayerCategory: 'audioPlayer tracking',
    transcriptDownloadCategory: 'File download',
    freeUserTakeAssessmentCategory: 'Take assessment by freeUser',
    UserTakeAssessmentCategory: 'Take assessment by user',
    AddNewInsightCategory: 'Add new insight',
    AddNewIntentionCategory: 'Add new intention',
    singleMediaOfMuduleCategory: 'Module audio listen',
    singleMediaOfLibraryCategory: 'Media-Library audio listen',
    journalDataSaveCategory: 'User save journal',
    helpCategory: 'submit User their Question',
    profileCategory: 'User update profile',
    resetPasswordCategory: 'User reset password successfully',
    resendEmailCategory: 'User click on resend email button',
    updatePasswordCategory: 'User update password successfully',
    compareAssessmentCategory: 'User compare two assessment',
    nextModuleCategory: 'User go to next module',
    learnMoreCategory: 'User click on learnMore botton',
    listenIntroCategory: 'User Listen Intro. Module',
    cookieCategory: 'User Accept Cookie',
    paymentCategory: 'User update plan',
    sliderCategory: 'growth Plan Slider',
    recommendedAudioCategory: 'Recommended Audio',
    applyPromoCodeCategory: 'User apply promocode',
    ListenPSPAudioCategory: 'User listen POST-SESSION-PRACTICES audio',
    deleteIntentionCategory: 'User delete Intention',
    purposeVisionCategory: 'Visit purpose & vision tab',
    valuesPrinciplesCategory: 'Visit values & principles tab',
    notificationsCategory: 'Click on notification icon',
    unreadNotificationsCategory: 'Read particular notification',
    laResultCategory: 'See leader assessment result',
    laRequestFormCategory: 'Click on leader assessment card',
    managerRequestFormCategory: 'Click on direct manager feedback card',
    selectPendingLACategory: 'Select pending leader assessment',
    submitLARequestCategory: 'Send leader assessment request',
    submitManagerRequestCategory: 'Send direct manager feedback request',
    sessionDetailsCategory: 'Open Session Modal',
    sessionKeySkillsCategory: 'Download recommended key skills',
    userJourneysAudioCategory: 'Play individual coach recommends audio',
    // orgJourneysAudioCategory: 'Play organization journeys audio',
    groupJourneysAudioCategory: 'Play group coach recommends audio',
    favoriteModuleCategory: 'Open favorite program module',
    favoriteAudioCategory: 'Play favorite media library audio',
    practicalOrPoeticAudioCategory: 'Play practical or poetic audio',
    keySkillsCategory: 'Download key skills',
    moduleAudioCategory: 'Module audio like/dislike',
    favoritedAudioDislikeCategory: 'Favorited audio dislike',
    surveyCategoryDetailsCategory: 'Open survey category details',
    assessmentRecommendedAudioCategory: 'Listen assessment recommended audio',
    laLearnMoreBtnCategory: 'Open learn more modal',
    moduleFavoriteCategory: 'module like/dislike',
  },
}
export default gtm
