import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { InputField } from '../form'
import { groupId, localStorageItemName, uniqueId } from '../../utils/constants'
import API from '../../utils/API'

const EmailField = () => {
  let { state } = useLocation()
  const Error = useErrorService()
  const { setFieldValue, setFieldTouched, validateField } = useFormikContext()
  const surveyRequestId = localStorage.getItem(localStorageItemName)
  const groupRequestId = localStorage.getItem(groupId)
  const uniqueRequestId = localStorage.getItem(uniqueId)

  useEffect(() => {
    const getEmailBySurveyRequestId = async () => {
      try {
        const { data } = await API.survey.getEmailBySurveyRequestId(
          surveyRequestId,
        )
        setFieldValue('email', data.receiverEmail)
        setFieldTouched('email', true, false)
      } catch (e) {
        Error.showError(e)
      }
    }

    const getEmailOfLeaderRequest = async () => {
      try {
        const { data } = await API.survey.getEmailForLeaderRequest(
          groupRequestId,
          uniqueRequestId,
        )
        setFieldValue('email', data.receiverEmail)
        setFieldTouched('email', true, false)
      } catch (e) {
        Error.showError(e)
      }
    }

    if (surveyRequestId) getEmailBySurveyRequestId()
    if (groupRequestId && uniqueRequestId) getEmailOfLeaderRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyRequestId, groupRequestId, uniqueRequestId])

  useEffect(() => {
    if (state?.email) {
      setFieldValue('email', state.email)
      setFieldTouched('email', true, false)
    }
  }, [state, setFieldValue, setFieldTouched, validateField])
  return <InputField name='email' type='email' label='EMAIL' size='lg' />
}

export default EmailField
