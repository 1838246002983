import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import sysend from 'sysend'
import {
  Button,
  useNotificationService,
  useUserService,
  useErrorService,
} from '@abroad/components'
import {
  OnboardTitle,
  ReturnBtn,
  AbroadGoldenIcon,
} from '../components/onboarding'
import API from '../utils/API'
import AuthService from '../utils/AuthService'

const TwoFactorAuthLogin = () => {
  const [otp, setOtp] = useState('')
  const handleChange = (otp) => setOtp(otp)
  const history = useHistory()
  const Notification = useNotificationService()
  const { user, setUser } = useUserService()
  const location = useLocation()
  const Error = useErrorService()
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [hasErrored, setHasErrored] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()
    if (otp.length !== 6) {
      setHasErrored(true)
      Notification.showNotification('Enter correct OTP!', 'danger')
      return
    }
    setIsBtnLoading(true)
    try {
      const { data } = await API.onboarding.login2fa({
        code: otp,
      })
      setUser({
        ...user,
        isTwoFactorVerified: data?.isTwoFactorVerified,
      })
      const cameFrom = location?.state?.cameFrom
      const next = location?.state?.next
      if (
        data?.isTwoFactorVerified &&
        (user?.isAdmin || user?.isCoach) &&
        next
      ) {
        setIsBtnLoading(false)
        if (cameFrom === 'client') {
          const url = new URL(next)
          history.replace(url.pathname)
        } else {
          window.location.replace(next)
        }
      } else if (data?.isTwoFactorVerified) {
        setIsBtnLoading(false)
        history.push('/home')
      } else {
        setHasErrored(true)
        Notification.showNotification('Incorrect OTP!', 'danger')
        setIsBtnLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsBtnLoading(false)
    }
  }

  useEffect(() => {
    if (otp) {
      setHasErrored(false)
    }
  }, [otp])

  const logout = async (e) => {
    e.preventDefault()
    try {
      await API.onboarding.logout()
      setUser(null)
      AuthService.removeData()
      sysend.broadcast('client_logout', { message: 'client_logout' })
      history.push('/')
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <section>
      <Helmet>
        <title>{'Two Factor Auth Login | Abroad'}</title>
        <meta name='title' content={'Two Factor Auth Login | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Two Factor Auth Login | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Two Factor Auth Login | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/2fa-login`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/2fa-login`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/2fa-login`}></meta>
      </Helmet>
      <Container fluid className='px-0'>
        <Row className='vh-100 row g-0'>
          <Col className='onboard bg-abroad-blue'>
            <div className='h-100 d-flex flex-column align-items-center'>
              <AbroadGoldenIcon />
              <section className='onboard-wrapper otp-screen h-75'>
                <OnboardTitle>One Time Password</OnboardTitle>
                <div className='justify-content-center align-items-center'>
                  <div className='s6 mt-2 text-white'>
                    Please enter the 2 factor authentication PIN from
                    Authenticator app.
                  </div>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className='w-100 align-items-center justify-content-center mt-40px'>
                      <OtpInput
                        hasErrored={hasErrored}
                        errorStyle='two-fa-otp-error'
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        isInputNum={true}
                        containerStyle='two-fa-otp-input justify-content-between'
                        inputStyle={{ color: 'white' }}
                      />
                    </div>
                    <div className='d-flex flex-column onbording-action-btn'>
                      <Button
                        isLoading={isBtnLoading}
                        disabled={isBtnLoading}
                        type='submit'
                        className='btn w-100 s9 mb-4'
                        variant='saffron'>
                        Confirm
                      </Button>
                      <Button
                        onClick={() => history.push('/2fa-email')}
                        variant='outline-dark'
                        className='btn w-100 s9'>
                        Email Based Authentication
                      </Button>
                    </div>
                  </form>
                </div>
              </section>
              <ReturnBtn onClick={logout} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TwoFactorAuthLogin
