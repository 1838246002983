import React from 'react'
import Imgix from 'react-imgix'
import { useResolutionService } from '@abroad/components'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import { isLGScreen } from '../../utils/utility'

const BreakThroughBanner = ({ bannerImage }) => {
  useResolutionService()
  if (!bannerImage) {
    return null
  }

  return (
    <div className='d-block h-100 overflow-hidden'>
      {isLGScreen ? (
        <Imgix
          className='lazyload img-cover'
          imgixParams={{
            w: window.innerWidth,
            h: window.innerHeight,
            fit: 'crop',
            crop: 'edges',
          }}
          src={`${process.env.REACT_APP_IMG_SOURCE}/${bannerImage}`}
          width={window.innerWidth}
          height={window.innerHeight}
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
          htmlAttributes={{
            src: `${process.env.REACT_APP_IMG_SOURCE}/${bannerImage}?w=${window.innerWidth}&h=${window.innerHeight}&crop=edges&fit=crop&fill=blur&blur=300&px=16&auto=format`, // low quality image here
          }}
        />
      ) : (
        <Imgix
          className='lazyload img-cover'
          imgixParams={{
            w: window.innerWidth,
            h: window.innerHeight,
            'fp-x': '.55',
            'fp-y': '.55',
            'fp-z': 2,
            fit: 'crop',
          }}
          src={`${process.env.REACT_APP_IMG_SOURCE}/${bannerImage}`}
          width={window.innerWidth}
          height={window.innerHeight}
          // srcSet={`${process.env.REACT_APP_IMG_SOURCE}/${bannerImage} 576w, ${process.env.REACT_APP_IMG_SOURCE}/${bannerImage} 992W, ${process.env.REACT_APP_IMG_SOURCE}/${bannerImage} 1200w`}
          // sizes='(max-width: 576px) 50vw, (max-width: 992px) 70vw, (max-width: 1200px) 100vw'
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
          htmlAttributes={{
            src: `${process.env.REACT_APP_IMG_SOURCE}/${bannerImage}?w=${window.innerWidth}&h=${window.innerHeight}&fp-x=.55&fp-y=.55&fp-z=2&crop=edges&fit=crop&fill=blur&blur=300&px=16&auto=format`, // low quality image here
          }}
        />
      )}
      <div className='position-absolute bg-black bg-opacity-25 inset-0' />
    </div>
  )
}

export default BreakThroughBanner
