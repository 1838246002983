import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@abroad/components'

const AddNewCardButton = ({ isLoading, disabled, btnClasses, onClick }) => {
  return (
    <Button
      variant='saffron'
      className={btnClasses}
      disabled={disabled}
      isLoading={isLoading}
      size='md'
      onClick={onClick}>
      Add a new card
    </Button>
  )
}

AddNewCardButton.defaultProps = {
  isLoading: false,
  disabled: false,
  btnClasses: '',
  onClick: () => {},
}

AddNewCardButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  btnClasses: PropTypes.string,
  onClick: PropTypes.func,
}

export default AddNewCardButton
