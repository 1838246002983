import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  useUserService,
  plans,
  leaderAssessments,
  leaderAssessmentsUrl,
} from '@abroad/components'
import FreeUserLA from './FreeUserLA'
import { MediaBanner, PageHeader } from '../common'

const imgCropFPObject = {
  // for leader assessment beginning
  'fp-x': 0,
  'fp-y': 0,
  'fp-z': 0,
}

const title = 'Elevate Your Leadership Potential'

const description = `Welcome to the next step in your Abroad journey. The Leader Assessment is designed to provide invaluable insights into how you're perceived as a leader by those who work closely with you. By inviting a minimum of three direct reports to participate, you'll gain a comprehensive understanding in two pivotal areas: "Manager Efficacy" and "Perceived Awareness". This confidential process offers a clear reflection of your leadership strengths and areas for growth, further enriching your learning and coaching experience here at Abroad. Dive in and discover the leader you are, and envision the leader you can become.`

const LABeginning = ({ hasNotLARequest = true }) => {
  const { user } = useUserService()

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{`${leaderAssessments} | Abroad`}</title>
        <meta name='title' content={`${leaderAssessments} | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${leaderAssessments} | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${leaderAssessments} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}`}></meta>
      </Helmet>
    )
  }

  const renderPageHeader = () => <PageHeader title={leaderAssessments} />

  const BeginningPage = ({ url }) => {
    return (
      <section className='mt-32px'>
        <h3 className='mb-12px s3'>{title}</h3>
        <p className='s5 mb-52px'>{description}</p>
        <Container fluid>
          <Row>
            <Col className='col-6 px-0'>
              <MediaBanner
                imageFilename='eagle-leader-survey.png'
                imgCropFP={imgCropFPObject}
              />
            </Col>
            <Col className='col-6 bg-abroad-blue d-flex justify-content-center align-items-center begin-section px-0'>
              <Link className='text-white d-flex text-decoration-none' to={url}>
                <div className='text-white s9 mr-12px'>Begin</div>
                <span className='icon icon-right-arrow text-white' />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  return (
    <>
      {renderHelmet()}
      <main>
        {renderPageHeader()}
        {user?.planCode === plans.free.code && <FreeUserLA />}
        {hasNotLARequest && (
          <BeginningPage url={`/survey/requests/${leaderAssessmentsUrl}/new`} />
        )}
      </main>
    </>
  )
}

export default LABeginning
