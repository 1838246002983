import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FeatherLoader, plans, useErrorService } from '@abroad/components'

import { ProgramsList } from '../breakthrough'
import HomeMiniSurveyResult from './HomeMiniSurveyResult'
import { GrayInfoCard, MediaBanner, UpgradeNow } from '../common'
import { MediaList } from '../media'
import API from '../../utils/API'

const imgCropFPObject = {
  // free plan user without survey
  'fp-x': 0.5,
  'fp-y': 0.48,
  'fp-z': 1.45,
}

const FreePlanHome = ({ surveyTaken }) => {
  if (!surveyTaken) {
    return <FreePlanNoSurvey />
  } else {
    return <FreePlanWithSurvey />
  }
}

export const FreePlanNoSurvey = () => {
  const [link, setLink] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getUrl = async () => {
      try {
        const { data } = await API.survey.getLink()
        setLink(data.link)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }
    getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='mt-40px mb-40px'>
      <div className='s3 mb-12px'>Your Journey Starts with Awareness</div>
      <div className='s5 mb-52px'>
        Welcome to Abroad. To get started, please take our confidential{' '}
        <strong>15 minute Awareness Assessment</strong>. Please answer the
        questions honestly, as your learning, coaching, and overall experience
        will be shaped by the results of this assessment.
      </div>
      <Container fluid>
        <Row>
          <Col className='col-6 px-0'>
            <MediaBanner
              imageFilename='freePlanWithoutSurvey.png'
              imgCropFP={imgCropFPObject}
            />
          </Col>
          <Col className='col-6 bg-abroad-blue d-flex justify-content-center align-items-center begin-section px-0'>
            <Link
              className={classNames('text-white d-flex text-decoration-none', {
                'pointer-events-none': isLoading,
              })}
              to={{
                pathname: link,
              }}
              target='_blank'>
              <div className='text-white s9 mr-12px'>Begin</div>
              <span className='icon icon-right-arrow text-white' />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const FreePlanWithSurvey = () => {
  const Error = useErrorService()

  const [threeLowestCatData, setThreeLowestCatData] = useState([])
  const [threeLowestCatLoader, setThreeLowestCatLoader] = useState(true)

  useEffect(() => {
    const getThreeLowestCategory = async () => {
      try {
        setThreeLowestCatLoader(true)
        const { data } = await API.mediaLibrary.getThreeLowestCategory()
        if (data) {
          setThreeLowestCatData(data)
        }
        setThreeLowestCatLoader(false)
      } catch (e) {
        setThreeLowestCatLoader(false)
        Error.showError(e)
      }
    }
    getThreeLowestCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (threeLowestCatLoader) {
    return (
      <div className='text-center m-3'>
        <FeatherLoader />
      </div>
    )
  }

  return (
    <div>
      <GrayInfoCard
        className='mb-52px mt-32px'
        text='Based on the results of your assessment, as part of your free plan, we
        have included some audios to help address your key areas for growth.'
      />
      {threeLowestCatData.map((category) => (
        <MediaList
          category={category}
          key={category.title}
          rowMarginBottom='mb-40px'
        />
      ))}
      <UpgradeNow
        wrapperClasses='mt-52px border-radius-10'
        titleFn={() => {
          return (
            <h3 className='s2 text-center text-white mb-0'>
              Unlock the Full
              <br />
              Abroad Media Library
            </h3>
          )
        }}
        buttonClasses='w-lg-30 leading-4'
        plan={plans.growth.code}
      />
      <HomeMiniSurveyResult />
      <div className='mt-52px mb-52px'>
        <div className='s1 mb-3'>Unlock doors to unparalleled growth</div>
        <div className='s5'>
          Our signature programs, curated by industry-leading experts, offer
          bespoke modules that empower, enlighten, and evolve your leadership
          skills. Get a taste of what's in store with a glimpse into the
          introductory modules.
        </div>
        <ProgramsList />
      </div>
      <UpgradeNow
        wrapperClasses='my-4 border-radius-full'
        title='Ready to Unlock the Full Experience?'
        subTitle={`Dive into each program's expansive journey, filled with tailored modules designed to redefine your leadership prowess. Upgrade to the Coaching Plan and embark on an odyssey of purposeful growth.`}
        buttonClasses='w-lg-30 leading-4'
        plan={plans.coaching.code}
      />
    </div>
  )
}

export default FreePlanHome
