import React, { useContext, useEffect, useState } from 'react'
import {
  NavLink,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { matchPath } from 'react-router'
import {
  useUserService,
  useErrorService,
  SidebarDropdown,
  coachRecommends,
  plans,
  coachRecommendsUrl,
  leaderAssessmentsUrl,
} from '@abroad/components'
import classnames from 'classnames'
import { Nav } from 'react-bootstrap'
import { SidebarAbroadIcon } from '../assetsComponents'
import { LayoutContext } from '../../utils/contexts'
import { isLGScreen } from '../../utils/utility'
import API from '../../utils/API'

const assessmentsLinks = [
  {
    to: '/survey',
    name: 'Awareness',
    pageUrls: [
      '/survey',
      '/fetching-result',
      '/survey/:id',
      '/survey/category/:category',
    ],
    onlyRC: false,
  },
  {
    to: `/survey/requests/${leaderAssessmentsUrl}`,
    name: 'Leader',
    pageUrls: [
      `/survey/requests/${leaderAssessmentsUrl}`,
      '/survey/leader/:id',
      `/survey/requests/${leaderAssessmentsUrl}/:id`,
    ],
    onlyRC: false,
  },
  {
    to: '/survey/requests/simple-360',
    name: 'Simple 360',
    pageUrls: ['/survey/requests/simple-360'],
    onlyRC: false,
  },
]

const homeLink = {
  to: '/home',
  name: 'Home',
  icon: <span className='icon icon-home'></span>,
}

const Sidebar = () => {
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { user } = useUserService()
  const Error = useErrorService()
  const [journeyMetadata, setJourneyMetadata] = useState()

  const [coachingLinks, setcoachingLinks] = useState([
    {
      to: '/coaching/intentions',
      name: 'Growth Plan',
      urlWords: ['intentions'],
      onlyRC: false,
    },
    {
      to: '/coaching/sessions',
      name: 'Sessions',
      urlWords: ['sessions', 'coaching/session'],
      onlyRC: false,
    },
  ])
  const learningLinks = [
    {
      to: '/programs',
      name: 'Programs',
      urlWords: ['programs', 'program', 'module'],
      onlyRC: false,
    },
    {
      to: '/media-library',
      name: 'Media Library',
      urlWords: ['media-library'],
      onlyRC: false,
    },
    {
      to: '/favorites',
      name: 'Favorites',
      urlWords: ['favorites'],
      onlyRC: false,
    },
  ]
  const { showPlayBar, setShowSidebar } = useContext(LayoutContext)

  useEffect(() => {
    if (!user) {
      history.push('/')
    }
  }, [user, history])

  useEffect(() => {
    const getJourneyMetadata = async () => {
      try {
        const { data } = await API.journey.getJourneyMetadata()
        if (data) {
          setJourneyMetadata(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getJourneyMetadata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      journeyMetadata &&
      (journeyMetadata?.clientJourneyExist ||
        journeyMetadata?.teamJourneyExist) &&
      user?.planCode === plans.coaching.code
    ) {
      const cloneLinks = [...coachingLinks]
      if (cloneLinks.findIndex((e) => e.name === coachRecommends) === -1) {
        cloneLinks.splice(2, 0, {
          to: `/${coachRecommendsUrl}#${
            journeyMetadata?.teamJourneyExist ? 'group' : 'individual'
          }`,
          name: coachRecommends,
          urlWords: [`${coachRecommendsUrl}`],
        })
        setcoachingLinks(cloneLinks)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyMetadata, user])

  useEffect(() => {
    if (!isLGScreen) {
      setShowSidebar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <aside id='sidebar' className='position-relative'>
      <Nav
        className={classnames(
          'flex-column flex-nowrap vh-100 sidebar-wrapper',
          {
            'sidebar-wrapper-with-play-bar': showPlayBar,
          },
        )}>
        <Nav.Item className='leading-none'>
          <div className='d-none d-lg-inline-block'>
            <SidebarAbroadIcon
              onClick={() => history.push('/home')}
              className='ms-4 mt-3'
            />
          </div>
        </Nav.Item>
        {(user?.isAdmin || user?.isCoach) && (
          <div className='nav-item down-separator'>
            <a
              className='nav-link text-decoration-none sidebar-link'
              id='sidebar-navlink'
              href={process.env.REACT_APP_ALTERNATE_DOMAIN}>
              <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center nav-link-icon justify-content-start'>
                  <span className='icon icon-briefcase font-20'></span>
                </div>
                <div className='d-flex align-items-center justify-content-start s6 office-platform-link'>
                  Office Platform
                </div>
              </div>
            </a>
          </div>
        )}
        <NavItem key={homeLink.name} link={homeLink} />

        <SidebarDropdown
          links={assessmentsLinks}
          name='Assessments'
          path={path}
          pathname={pathname}
          NavLink={NavLink}
          linkClasses='s8'
          listWrapperClasses='s6'
          isRenderOnClientSide={true}
          icon={<span className='icon icon-assessments font-20 me-3'></span>}
          removeDefaultActive={true}
          matchPath={matchPath}
        />

        <SidebarDropdown
          links={coachingLinks}
          name='Coaching'
          path={path}
          pathname={pathname}
          NavLink={NavLink}
          linkClasses='s8'
          listWrapperClasses='s6'
          isRenderOnClientSide={true}
          icon={<span className='icon icon-coaching font-20 me-3'></span>}
        />

        <SidebarDropdown
          links={learningLinks}
          name='Learning'
          path={path}
          pathname={pathname}
          NavLink={NavLink}
          linkClasses='s8'
          listWrapperClasses='s6'
          isRenderOnClientSide={true}
          icon={<span className='icon icon-learning font-20 me-3'></span>}
        />
      </Nav>
    </aside>
  )
}

const NavItem = ({ link, className = '' }) => {
  return (
    <Nav.Item className=''>
      <Nav.Link
        className={classnames('sidebar-link', className)}
        id='sidebar-navlink'
        isActive={(match, location) => {
          if (
            match?.url === link.to ||
            (match?.url.includes('journey') && link.to.includes('journey'))
          ) {
            return true
          }
          return false
        }}
        as={NavLink}
        to={link.to}>
        <div className='d-flex align-items-center'>
          {link?.icon && (
            <div
              className={classnames(
                'd-flex align-items-center nav-link-icon font-20 justify-content-start',
                {
                  'icon-hover': link.hoverIcon,
                },
              )}>
              {link.icon}
              {link.hoverIcon}
            </div>
          )}
          <div className='d-flex align-items-center justify-content-start s6 ms-3'>
            {link.name}
          </div>
        </div>
      </Nav.Link>
    </Nav.Item>
  )
}

export default Sidebar
