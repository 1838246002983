const countriesData = {
  Afghanistan: { ct: 'Asia' },
  'Åland Islands': { ct: 'Europe' },
  Albania: { ct: 'Europe' },
  Algeria: { ct: 'Africa' },
  'American Samoa': { ct: 'Oceania' },
  Andorra: { ct: 'Europe' },
  Angola: { ct: 'Africa' },
  Anguilla: { ct: 'North America' },
  Antarctica: { ct: 'Antarctica' },
  'Antigua & Barbuda': { ct: 'North America' },
  Argentina: { ct: 'South America' },
  Armenia: { ct: 'Asia' },
  Aruba: { ct: 'North America' },
  Australia: { ct: 'Oceania' },
  Austria: { ct: 'Europe' },
  Azerbaijan: { ct: 'Asia' },
  Bahamas: { ct: 'North America' },
  Bahrain: { ct: 'Asia' },
  Bangladesh: { ct: 'Asia' },
  Barbados: { ct: 'North America' },
  Belarus: { ct: 'Europe' },
  Belgium: { ct: 'Europe' },
  Belize: { ct: 'North America' },
  Benin: { ct: 'Africa' },
  Bermuda: { ct: 'North America' },
  Bhutan: { ct: 'Asia' },
  Bolivia: { ct: 'South America' },
  'Bosnia & Herzegovina': { ct: 'Europe' },
  Botswana: { ct: 'Africa' },
  Brazil: { ct: 'South America' },
  'British Indian Ocean Territory': { ct: 'Africa' },
  'British Virgin Islands': { ct: 'North America' },
  Brunei: { ct: 'Asia' },
  Bulgaria: { ct: 'Europe' },
  'Burkina Faso': { ct: 'Africa' },
  Burundi: { ct: 'Africa' },
  Cambodia: { ct: 'Asia' },
  Cameroon: { ct: 'Africa' },
  Canada: { ct: 'North America' },
  'Cape Verde': { ct: 'Africa' },
  'Cayman Islands': { ct: 'North America' },
  'Central African Republic': { ct: 'Africa' },
  Chad: { ct: 'Africa' },
  Chile: { ct: 'South America' },
  China: { ct: 'Asia' },
  'Christmas Island': { ct: 'Oceania' },
  'Cocos (Keeling) Islands': { ct: 'Oceania' },
  Colombia: { ct: 'South America' },
  Comoros: { ct: 'Africa' },
  'Congo - Brazzaville': { ct: 'Africa' },
  'Congo - Kinshasa': { ct: 'Africa' },
  'Cook Islands': { ct: 'Oceania' },
  'Costa Rica': { ct: 'North America' },
  'Côte d’Ivoire': { ct: 'Africa' },
  Croatia: { ct: 'Europe' },
  Cuba: { ct: 'North America' },
  Curaçao: { ct: 'North America' },
  Cyprus: { ct: 'Asia' },
  Czechia: { ct: 'Europe' },
  Denmark: { ct: 'Europe' },
  Djibouti: { ct: 'Africa' },
  Dominica: { ct: 'North America' },
  'Dominican Republic': { ct: 'North America' },
  Ecuador: { ct: 'South America' },
  Egypt: { ct: 'Africa' },
  'El Salvador': { ct: 'North America' },
  'Equatorial Guinea': { ct: 'Africa' },
  Eritrea: { ct: 'Africa' },
  Estonia: { ct: 'Europe' },
  Ethiopia: { ct: 'Africa' },
  'Falkland Islands': { ct: 'South America' },
  'Faroe Islands': { ct: 'Europe' },
  Fiji: { ct: 'Oceania' },
  Finland: { ct: 'Europe' },
  France: { ct: 'Europe' },
  'French Guiana': { ct: 'South America' },
  'French Polynesia': { ct: 'Oceania' },
  'French Southern Territories': { ct: 'Africa' },
  Gabon: { ct: 'Africa' },
  Gambia: { ct: 'Africa' },
  Georgia: { ct: 'Asia' },
  Germany: { ct: 'Europe' },
  Ghana: { ct: 'Africa' },
  Gibraltar: { ct: 'Europe' },
  Greece: { ct: 'Europe' },
  Greenland: { ct: 'North America' },
  Grenada: { ct: 'North America' },
  Guadeloupe: { ct: 'North America' },
  Guam: { ct: 'Oceania' },
  Guatemala: { ct: 'North America' },
  Guernsey: { ct: 'Europe' },
  Guinea: { ct: 'Africa' },
  'Guinea-Bissau': { ct: 'Africa' },
  Guyana: { ct: 'South America' },
  Haiti: { ct: 'North America' },
  Honduras: { ct: 'North America' },
  'Hong Kong SAR China': { ct: 'Asia' },
  Hungary: { ct: 'Europe' },
  Iceland: { ct: 'Europe' },
  India: { ct: 'Asia' },
  Indonesia: { ct: 'Asia' },
  Iran: { ct: 'Asia' },
  Iraq: { ct: 'Asia' },
  Ireland: { ct: 'Europe' },
  'Isle of Man': { ct: 'Europe' },
  Israel: { ct: 'Asia' },
  Italy: { ct: 'Europe' },
  Jamaica: { ct: 'North America' },
  Japan: { ct: 'Asia' },
  Jersey: { ct: 'Europe' },
  Jordan: { ct: 'Asia' },
  Kazakhstan: { ct: 'Asia' },
  Kenya: { ct: 'Africa' },
  Kiribati: { ct: 'Oceania' },
  Kosovo: { ct: 'Europe' },
  Kuwait: { ct: 'Asia' },
  Kyrgyzstan: { ct: 'Asia' },
  Laos: { ct: 'Asia' },
  Latvia: { ct: 'Europe' },
  Lebanon: { ct: 'Asia' },
  Lesotho: { ct: 'Africa' },
  Liberia: { ct: 'Africa' },
  Libya: { ct: 'Africa' },
  Liechtenstein: { ct: 'Europe' },
  Lithuania: { ct: 'Europe' },
  Luxembourg: { ct: 'Europe' },
  'Macau SAR China': { ct: 'Asia' },
  Macedonia: { ct: 'Europe' },
  Madagascar: { ct: 'Africa' },
  Malawi: { ct: 'Africa' },
  Malaysia: { ct: 'Asia' },
  Maldives: { ct: 'Asia' },
  Mali: { ct: 'Africa' },
  Malta: { ct: 'Europe' },
  'Marshall Islands': { ct: 'Oceania' },
  Martinique: { ct: 'North America' },
  Mauritania: { ct: 'Africa' },
  Mauritius: { ct: 'Africa' },
  Mayotte: { ct: 'Africa' },
  Mexico: { ct: 'North America' },
  Micronesia: { ct: 'Oceania' },
  Moldova: { ct: 'Europe' },
  Monaco: { ct: 'Europe' },
  Mongolia: { ct: 'Asia' },
  Montenegro: { ct: 'Europe' },
  Montserrat: { ct: 'North America' },
  Morocco: { ct: 'Africa' },
  Mozambique: { ct: 'Africa' },
  'Myanmar (Burma)': { ct: 'Asia' },
  Namibia: { ct: 'Africa' },
  Nauru: { ct: 'Oceania' },
  Nepal: { ct: 'Asia' },
  Netherlands: { ct: 'Europe' },
  'New Caledonia': { ct: 'Oceania' },
  'New Zealand': { ct: 'Oceania' },
  Nicaragua: { ct: 'North America' },
  Niger: { ct: 'Africa' },
  Nigeria: { ct: 'Africa' },
  Niue: { ct: 'Oceania' },
  'Norfolk Island': { ct: 'Oceania' },
  'North Korea': { ct: 'Asia' },
  'Northern Mariana Islands': { ct: 'Oceania' },
  Norway: { ct: 'Europe' },
  Oman: { ct: 'Asia' },
  Pakistan: { ct: 'Asia' },
  Palau: { ct: 'Oceania' },
  'Palestinian Territories': { ct: 'Asia' },
  Panama: { ct: 'North America' },
  'Papua New Guinea': { ct: 'Oceania' },
  Paraguay: { ct: 'South America' },
  Peru: { ct: 'South America' },
  Philippines: { ct: 'Asia' },
  'Pitcairn Islands': { ct: 'Oceania' },
  Poland: { ct: 'Europe' },
  Portugal: { ct: 'Europe' },
  'Puerto Rico': { ct: 'North America' },
  Qatar: { ct: 'Asia' },
  Réunion: { ct: 'Africa' },
  Romania: { ct: 'Europe' },
  Russia: { ct: 'Europe' },
  Rwanda: { ct: 'Africa' },
  Samoa: { ct: 'Oceania' },
  'San Marino': { ct: 'Europe' },
  'São Tomé & Príncipe': { ct: 'Africa' },
  'Saudi Arabia': { ct: 'Asia' },
  Senegal: { ct: 'Africa' },
  Serbia: { ct: 'Europe' },
  Seychelles: { ct: 'Africa' },
  'Sierra Leone': { ct: 'Africa' },
  Singapore: { ct: 'Asia' },
  'Sint Maarten': { ct: 'North America' },
  Slovakia: { ct: 'Europe' },
  Slovenia: { ct: 'Europe' },
  'Solomon Islands': { ct: 'Oceania' },
  Somalia: { ct: 'Africa' },
  'South Africa': { ct: 'Africa' },
  'South Georgia & South Sandwich Islands': { ct: 'South America' },
  'South Korea': { ct: 'Asia' },
  'South Sudan': { ct: 'Africa' },
  Spain: { ct: 'Europe' },
  'Sri Lanka': { ct: 'Asia' },
  'St. Barthélemy': { ct: 'North America' },
  'St. Helena': { ct: 'Africa' },
  'St. Kitts & Nevis': { ct: 'North America' },
  'St. Lucia': { ct: 'North America' },
  'St. Martin': { ct: 'North America' },
  'St. Pierre & Miquelon': { ct: 'North America' },
  'St. Vincent & Grenadines': { ct: 'North America' },
  Sudan: { ct: 'Africa' },
  Suriname: { ct: 'South America' },
  'Svalbard & Jan Mayen': { ct: 'Europe' },
  Swaziland: { ct: 'Africa' },
  Sweden: { ct: 'Europe' },
  Switzerland: { ct: 'Europe' },
  Syria: { ct: 'Asia' },
  Taiwan: { ct: 'Asia' },
  Tajikistan: { ct: 'Asia' },
  Tanzania: { ct: 'Africa' },
  Thailand: { ct: 'Asia' },
  'Timor-Leste': { ct: 'Asia' },
  Togo: { ct: 'Africa' },
  Tokelau: { ct: 'Oceania' },
  Tonga: { ct: 'Oceania' },
  'Trinidad & Tobago': { ct: 'North America' },
  'Tristan da Cunha': { ct: 'Africa' },
  Tunisia: { ct: 'Africa' },
  Turkey: { ct: 'Asia' },
  Turkmenistan: { ct: 'Asia' },
  'Turks & Caicos Islands': { ct: 'North America' },
  Tuvalu: { ct: 'Oceania' },
  'U.S. Outlying Islands': { ct: 'Oceania' },
  'U.S. Virgin Islands': { ct: 'North America' },
  Uganda: { ct: 'Africa' },
  Ukraine: { ct: 'Europe' },
  'United Arab Emirates': { ct: 'Asia' },
  'United Kingdom': { ct: 'Europe' },
  'United States': { ct: 'North America' },
  Uruguay: { ct: 'South America' },
  Uzbekistan: { ct: 'Asia' },
  Vanuatu: { ct: 'Oceania' },
  'Vatican City': { ct: 'Europe' },
  Venezuela: { ct: 'South America' },
  Vietnam: { ct: 'Asia' },
  'Wallis & Futuna': { ct: 'Oceania' },
  'Western Sahara': { ct: 'Africa' },
  Yemen: { ct: 'Asia' },
  Zambia: { ct: 'Africa' },
  Zimbabwe: { ct: 'Africa' },
}

const labels = {
  languages: [
    'Afrikaans',
    'Albanian',
    'Arabic',
    'Armenian',
    'Basque',
    'Bengali',
    'Bulgarian',
    'Catalan',
    'Cambodian',
    'Chinese (Mandarin)',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Fiji',
    'Finnish',
    'French',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Korean',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Russian',
    'Samoan',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish ',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tonga',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhos',
  ],
  genders: ['Male', 'Female', 'Non-Binary', 'Other'],
  countries: countriesData,
  ethnicity: {
    'African, African-American': 'African',
    'Chinese, Chinese-American': 'Asian',
    'Caucasian, European, European-American': 'Caucasian',
    'Filipino, Filipino-American': 'Asian',
    'Japanese, Japanese-American': 'Asian',
    'Korean, Korean-American': 'Asian',
    'Southeast Asian (e.g., Vietnam, Cambodia, etc.)': 'Asian',
    'Other Asian': 'Asian',
    'Mexican, Mexican-American, Chicano': 'Latino',
    'Other Latino': 'Latino',
    'Middle Eastern (e.g., Arab, Persian)': 'Middle Eastern',
    'Native American, First Nation': 'Native American',
  },
  education: [
    'No schooling completed',
    'Nursery school to 8th grade',
    'Some high school',
    'High school graduate or equivalent',
    'Some college',
    'Trade, technical or vocational',
    "Associate's degree",
    "Bachelor's degree",
    "Master's degree",
    'Professional degree',
    'Doctorate degree',
  ],
  growthList: [
    'Leadership Development (e.g. Strategy, Executive Presence)',
    'Well-Being (e.g. Stress, Burnout, Mental Health)',
    'Team Culture (e.g. Cohesion, Trust, Employee Experience)',
    'Purpose & Life Alignment (e.g. Career Change, Fulfillment)',
    'Emotional Intelligence & Communication (e.g. Soft Skills)',
    'Practical Manager Skills (Prioritization, Focus, Running 1:1s)',
    'Developing Team Members (e.g. Coaching, Career Paths)',
    'Interpersonal Relationships (e.g. Colleagues, Co-Founders)',
    'Succession Planning (e.g. Role Transition, Leadership Competencies)',
    'Organizational Development (e.g. OKRs, Operational Mapping)',
  ],
  userStatus: ['I am in career transition', 'I am currenly in operating role'],
  roleListForUser: [
    {
      label: 'Employee',
      value: 'ic',
      url: 'https://www.abroad.io/coaching-plan-500',
    },
    {
      label: 'First-Line Manager',
      value: 'manager',
      url: 'https://www.abroad.io/coaching-plan-1000',
    },
    {
      label: 'Middle Manager',
      value: 'leader',
      url: 'https://www.abroad.io/coaching-plan-1500',
    },
    {
      label: 'Top-Level Manager',
      value: 'executive',
      url: 'https://www.abroad.io/coaching-plan-2500',
    },
    {
      label: 'CEO / FOUNDER / Board of Directors',
      value: 'ceo',
      url: 'https://www.abroad.io/coaching-plan-2500',
    },
  ],
  companyType: [
    'Large Enterprise/Corporation',
    'Small-to-Medium Sized Enterprise (SME)',
    'Small Business',
    'Non-Profit/NGO',
    'High Growth Startup',
    'Family Office',
    'Venture Capital/PE Fund',
    'Hedge Fund',
  ],
  companyPeople: [
    'Self-employed',
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1000 employees',
    '1001-5000 employees',
    '5001-10,000 employees',
    '10,001+ employees',
  ],
  investmentStage: [
    'Not Applicable',
    "Ideation Stage - we're getting our vision in order.",
    'Self Funded - our team has financed most of it.',
    'Friends & Family - most of our funding has come from personal contacts.',
    'Crowdfunding - the masses have spoken.',
    'Angel Investment - an individual or individuals have kickstarted our company.',
    "Accelerator - we're part of an organization providing a little capital, a lot of mentoring, and some office space.",
    "Seed Investment - we've secured between $500k -$1 million from investors.",
    "Series A - we've secured between $1-6 million from investors.",
    "Series B - we've secured between $6.1-11 million from investors.",
    "Series C - we've secured between $11.1-100 million from investors.",
    'Series D & Beyond - we want to see how far the rabbit hole goes.',
    'Publicly traded company',
  ],
}

export default labels
