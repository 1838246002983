import React from 'react'
import { Helmet } from 'react-helmet'
import {
  simple360,
  simple360Url,
  plans,
  useUserService,
} from '@abroad/components'
import { Simple360Request } from '../components'
import { LearnAboutCoaching, PageHeader } from '../components/common'

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{`${simple360} | Abroad`}</title>
      <meta name='title' content={`${simple360} | Abroad`}></meta>
      <meta property='og:title' content={`${simple360} | Abroad`}></meta>
      <meta property='twitter:title' content={`${simple360} | Abroad`}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/survey/requests/${simple360Url}`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${simple360Url}`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${simple360Url}`}></meta>
    </Helmet>
  )
}

const renderPageHeader = () => <PageHeader title={simple360} />

const detailsText = `Hearing from those closest to you can illuminate blind spots and accelerate growth. With a dedicated coach, gain access to the Simple 360 – a straightforward feedback tool that harnesses insights from your inner circle. Begin your coaching journey to unlock this transformative experience.`

const Simple360 = () => {
  const { user } = useUserService()
  return (
    <>
      {renderHelmet()}
      <main>
        {renderPageHeader()}
        {[plans.free.code, plans.growth.code].includes(user?.planCode) ? (
          <section className='mt-32px w-36rem'>
            <LearnAboutCoaching
              titleFn={() => {
                return (
                  <h3 className='s2 text-black mb-0'>
                    Deepen Self-Understanding
                    <br />
                    with a Simple 360
                  </h3>
                )
              }}
              detailsText={detailsText}
              buttonText='LEARN MORE ABOUT COACHING'
              plan={plans.coaching.code}
              isDiscardWidth={true}
            />
          </section>
        ) : (
          <Simple360Request />
        )}
      </main>
    </>
  )
}

export default Simple360
