import React, { useContext } from 'react'
import classNames from 'classnames'
import Imgix from 'react-imgix'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useNotificationService, coachRecommendsUrl } from '@abroad/components'
import Logo from '../../../assets/images/abroad.jpg'
import { LayoutContext } from '../../../utils/contexts'

const NotificationList = ({
  data,
  source,
  messageClasses,
  wrapperClasses = '',
  readNotificationhandler = () => {},
  clickNotificationhandler = () => {},
}) => {
  const { setJourneyGroupId } = useContext(LayoutContext)
  const Notification = useNotificationService()

  const notificationType = {
    1: 'New Individual Session Notes',
    2: 'New Group Session Notes',
    3: `${data?.senderName} has requested simple 360 feedback from you.`,
    4: `${data?.senderName} has requested leadership assessment from you.`,
    5: 'Your Leader Assessment is ready',
    6: 'Your coach has curated a new set of learning audios for you. Discover the latest recommendations tailored to your journey.',
    7: `Your coach has updated the learning audio recommendations for the ${data?.teamName} group. Engage with the newest selections to stay aligned with your group's progression.`,
  }

  const notificationMessage = {
    3: 'You have already submitted the simple 360 feedback assessment.',
    4: 'You have already submitted the leadership assessment.',
  }

  const getURL = (type) => {
    if (type === 1 || type === 2) {
      return `/coaching/session/${data?.sessionId}`
    }
    if (type === 6) {
      return `/${coachRecommendsUrl}#individual`
    }
    if (type === 7) {
      setJourneyGroupId(data?.teamId)
      return `/${coachRecommendsUrl}#group`
    }
    return `/survey/leader/${data?.leaderRequestId}`
  }

  const getNotificationRelativeTime = () => {
    const time = moment(data?.createdAt)
    moment.updateLocale('en', {
      relativeTime: {
        past: '%s',
        s: '1m',
        ss: '1m',
        m: '1m',
        mm: '%dm',
        h: '%dh',
        hh: '%dh',
        d: '%dd',
        dd: function (number) {
          return number < 7 ? `${number}d` : `${(number / 7).toFixed()}w` //toFixed() for week
        },
        M: '%dmo',
        MM: '%dmo',
        y: '%dy',
        yy: '%dy',
      },
    })

    return time.fromNow()
  }

  const NotificationLinkWrapper = ({ data, children }) => {
    return (
      <>
        {data?.type === 3 || data?.type === 4 ? (
          <a
            href={
              data?.typeformLink
                ? `${data?.typeformLink}`
                : // eslint-disable-next-line no-script-url
                  'javascript:void(0)'
            }
            className='text-decoration-none notification-link'
            target={data?.typeformLink ? '_blank' : '_self'}
            rel='noreferrer'
            onClick={(e) => {
              if (data?.typeformLink) {
                readNotificationhandler(data?.uniqueId, data)
                clickNotificationhandler()
              } else {
                e.preventDefault()
                Notification.showNotification(
                  notificationMessage[data?.type],
                  'danger',
                )
              }
            }}>
            {children}
          </a>
        ) : (
          <Link
            className='text-decoration-none notification-link'
            to={getURL(data?.type)}
            onClick={() => {
              readNotificationhandler(data?.uniqueId, data)
              clickNotificationhandler()
            }}>
            {children}
          </Link>
        )}
      </>
    )
  }

  return (
    <div className={classNames('px-0 notification-section', wrapperClasses)}>
      <NotificationLinkWrapper data={data}>
        <div className='d-flex d-inline-block w-100'>
          {data?.imageFilename ? (
            <Imgix
              className='border-radius-5 align-self-center notification-img'
              imgixParams={{ fit: 'scale', w: 32, h: 32 }}
              src={`${source}/${data?.imageFilename}`}
              height={32}
              width={32}
              alt='image'
            />
          ) : (
            <>
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img
                src={Logo}
                height={32}
                width={32}
                alt='image'
                className='align-self-center notification-img'
              />
            </>
          )}
          <span
            className={classNames(
              'notification-message text-black align-self-center',
              messageClasses,
            )}>
            {notificationType[data?.type]}
          </span>
          <span className='mb-0 notification-time ms-2 s-small-description text-dark-900-5 text-decoration-none me-1 align-self-center'>
            {getNotificationRelativeTime()}
          </span>
        </div>
      </NotificationLinkWrapper>
    </div>
  )
}

export default NotificationList
