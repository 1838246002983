import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { Row, Col } from 'react-bootstrap'
import {
  ABSpinner,
  // BottomAccordion,
  // Button,
  useErrorService,
  useUserService,
  plans,
} from '@abroad/components'
// import { isLGScreen } from '../utils/utility'
import API from '../utils/API'
import {
  LearnAboutCoaching,
  PageHeader,
  IntentionCard,
} from '../components/common'
import SliderEvent from '../constants/events'
import { uuidv4 } from '../utils/utility'
import { ArchivedIntentionsSection } from '../components'

const GrowthPlan = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isUpgradeRequired, setIsUpgradeRequired] = useState(false)
  const [intentions, setIntentions] = useState([])
  const { user } = useUserService()
  // eslint-disable-next-line no-unused-vars
  // const [arrowHeight, setArrowHeight] = useState(0)
  const Error = useErrorService()
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: SliderEvent.event.slider,
        eventProps: {
          category: SliderEvent.category.sliderCategory,
          action: 'User go to intention tab',
          label: 'go to intention tab',
          value: `User go to intention tab in Coaching page`,
          userId: user?._id,
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getintentions = async () => {
      try {
        let { data } = await API.intention.getIntentions({ archived: false })
        setIsUpgradeRequired(data.isUpgradeRequired)
        if (data.intentions.length < 2) {
          setIntentions([
            ...data.intentions,
            ...Array.from({ length: 2 - data.intentions.length }, (x, i) => ({
              _id: uuidv4(),
              isLocal: true,
              title:
                'Click here to create a new intention for generating additional insights',
              createdAt: new Date(),
            })),
          ])
        } else {
          setIntentions([
            ...data.intentions,
            ...Array.from({ length: 1 }, (x, i) => ({
              _id: uuidv4(),
              isLocal: true,
              title:
                'Click here to create a new intention for generating additional insights',
              createdAt: new Date(),
            })),
          ])
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getintentions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLoading = () => {
    if (isLoading) {
      return (
        <div className='mt-32px'>
          <ABSpinner />
        </div>
      )
    }
  }

  const renderBody = () => {
    if (user?.planCode === plans.free.code || isUpgradeRequired) {
      return (
        <LearnAboutCoaching
          wrapperClasses={'mt-32px'}
          titleFn={() => {
            return (
              <h3 className='s2 text-black mb-0'>
                Deep Dive into Your
                <br />
                Assessment with the Growth Plan
              </h3>
            )
          }}
          detailsText={`Discover how your team perceives you as a leader. With the Growth Plan, send out our exclusive 'Leader Assessment' to gain invaluable feedback on your managerial efficacy and perceived awareness. Upgrade today and uncover the feedback that can transform your leadership journey.`}
          buttonText='UPGRADE TO GROWTH PLAN'
          plan={plans.growth.code}
        />
      )
    } else {
      return (
        <>
          <div className='custom-gray-info-text s5 mb-52px mt-32px'>
            Craft your personal growth plan by outlining at least two
            intentions, then enrich them with written or recorded insights as
            they arise.
          </div>
          <div className='s3'>Your Intentions</div>
          {isLoading && renderLoading()}
          {!isLoading && renderIntentions()}
        </>
      )
    }
  }

  const renderIntentions = () => {
    return (
      <>
        <Row>
          {intentions?.map((intention, index) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              key={intention._id}
              className='my-3 px-3'>
              <IntentionCard index={index + 1} intention={intention} />
            </Col>
          ))}
        </Row>
      </>
    )
  }

  return (
    <main>
      <>
        <Helmet>
          <title>{'Growth Plan | Abroad'}</title>
          <meta name='title' content={'Growth Plan | Abroad'}></meta>
          <meta property='og:title' content={'Growth Plan | Abroad'}></meta>
          <meta
            property='twitter:title'
            content={'Growth Plan | Abroad'}></meta>
          <link
            rel='canonical'
            href={`${process.env.REACT_APP_DOMAIN}/coaching/intentions`}></link>
          <meta
            property='og:url'
            content={`${process.env.REACT_APP_DOMAIN}/coaching/intentions`}></meta>
          <meta
            property='twitter:url'
            content={`${process.env.REACT_APP_DOMAIN}/coaching/intentions`}></meta>
        </Helmet>
        <PageHeader title='Growth Plan' />
        {renderBody()}
        <ArchivedIntentionsSection
          setIntentions={setIntentions}
          intentions={intentions}
        />
      </>
    </main>
  )
}

export default GrowthPlan
