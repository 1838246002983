import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Col, Row, Spinner } from 'react-bootstrap'
import {
  PolygonChart,
  LeaderSelect,
  useResolutionService,
  useErrorService,
} from '@abroad/components'
import API from '../../utils/API'
import { surveyType } from '../../utils/constants'

const AwarenessGapAnalysis = ({
  selfAwarenessKeyTitle,
  getValueByIdFromLeaderSurvey,
  awarenessSurveyTitlePercent,
  comparisonChartTitleArray,
  perceivedArray,
  selfAwarenessArray,
  setAwarenessSurvey,
}) => {
  const [isDateArrayLoading, setIsDateArrayLoading] = useState(true)
  const [isFetchData, setIsFetchData] = useState(false)
  const [dateArray, setDateArray] = useState([])
  const { isXSScreen } = useResolutionService()
  const Error = useErrorService()

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await API.survey.getSurveys(
          'personal',
          surveyType.AWARENESS,
        ) // (surveyType, filterBy)
        if (data) {
          if (data?.surveys?.length > 0) {
            let sortedSurveyData = data?.surveys?.sort((a, b) => {
              return moment(a.submittedAt) > moment(b.submittedAt) ? -1 : 1
            })
            setDateArray(
              sortedSurveyData.map((e) => ({
                value: e?.id || e?._id,
                label: moment(e?.submittedAt).format('MM/DD/YY'),
              })),
            )
            setIsDateArrayLoading(false)
          }
          setIsDateArrayLoading(false)
        }
      } catch (e) {
        setIsDateArrayLoading(false)
        Error.showError(e)
      }
    }
    getSurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const compareDateWise = async (id) => {
    try {
      setIsFetchData(true)
      const { data } = await API.survey.getSurveyById(id)
      if (data) {
        setAwarenessSurvey(data)
        setIsFetchData(false)
      }
    } catch (e) {
      setIsFetchData(false)
      Error.showError(e)
    }
  }

  return (
    <div className='awareness-gap mb-52px'>
      <h3 className='s3 title'>Awareness Gap Analysis</h3>
      <p className='s5 mb-52px'>
        This is a tool to compare your Self Awareness scores from your Abroad
        Awareness Assessment with the Perceived Awareness scores provided by
        your direct reports. As leaders, there may be times when we are not
        seeing ourselves clearly. For example, we might believe we are handling
        our stress gracefully, while our team members feel that our stress is
        having an impact on our energy and actions.
      </p>
      <Row>
        <Col
          lg={4}
          md={12}
          sm={12}
          className='d-flex flex-column justify-content-between'>
          <div className='order-lg-1 order-2'>
            <div className='d-flex align-items-center me-3 mb-4'>
              <div className='bg-blue-manager manager-result-compare-circle m-0 me-3' />
              <div
                className={classNames('s5', {
                  'overflow-category-name': !isXSScreen,
                })}>
                {selfAwarenessKeyTitle} (
                {getValueByIdFromLeaderSurvey(
                  '5b46b176ce416b175ff98e17',
                  'percent',
                )}
                %)
              </div>
            </div>
            <div className='d-flex align-items-center me-3 mb-4'>
              <div className='bg-light-blue-manager manager-result-compare-square m-0 me-3' />
              <div className='s5'>
                {awarenessSurveyTitlePercent?.title} (
                {awarenessSurveyTitlePercent?.percent}%)
              </div>
            </div>
          </div>
          <div className='leader-select order-lg-2 order-1 pb-lg-0 pb-3'>
            {isDateArrayLoading ? (
              <Spinner
                animation='border'
                className='text-saffron-300'
                role='status'
                size='sm'
              />
            ) : (
              <React.Fragment>
                <LeaderSelect
                  setFirstDefault='true'
                  name='user-pl'
                  options={dateArray}
                  onChange={(e) => compareDateWise(e?.value)}
                  isLoading={isFetchData}
                  isRenderOnClientSide={true}
                />
              </React.Fragment>
            )}
          </div>
        </Col>
        <Col lg={2} md={12} sm={12}></Col>
        <Col lg={4} md={12} sm={12}>
          <div className='d-flex flex-column align-items-center'>
            <PolygonChart
              className='mb-0'
              categories={comparisonChartTitleArray}
              backgroundColor='#f3f6f6'
              // height='215'
              colors={['#345995', '#69b1d8']}
              surveyOne={perceivedArray}
              surveyTwo={selfAwarenessArray}
            />
          </div>
        </Col>
        <Col lg={2} md={12} sm={12}></Col>
      </Row>
    </div>
  )
}

export default AwarenessGapAnalysis
