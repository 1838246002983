import React, { useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useUserService } from '@abroad/components'
import {
  groupId,
  localStorageItemName,
  uniqueId,
  urlParamsForSurveyRequestId,
} from '../utils/constants'

const PublicRoute = ({ children, component: Component, render, ...rest }) => {
  const { user } = useUserService()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const querySurveyRequestId = query.get(urlParamsForSurveyRequestId)
  const queryGroupId = query.get(groupId)
  const queryUniqueId = query.get(uniqueId)

  useEffect(() => {
    if (querySurveyRequestId) {
      localStorage.setItem(localStorageItemName, querySurveyRequestId)
    }
    if (queryGroupId && queryUniqueId) {
      localStorage.setItem(groupId, queryGroupId)
      localStorage.setItem(uniqueId, queryUniqueId)
    }
  }, [querySurveyRequestId, queryGroupId, queryUniqueId])
  return (
    <Route
      {...rest}
      render={(props) => {
        if (render) {
          render(props)
          return
        }
        if (user) {
          return <Redirect to={'/home'} />
        } else {
          return children || <Component {...props} />
        }
      }}
    />
  )
}

export default PublicRoute
