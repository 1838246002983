import React, { useContext } from 'react'
import classNames from 'classnames'
import { Dropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { LayoutContext } from '../../utils/contexts'

const GroupJourneyDropdown = ({ groupList, setGroupObject }) => {
  const { journeyGroupId } = useContext(LayoutContext)
  const location = useLocation()

  const renderDownArrow = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='13'
        viewBox='0 0 12 13'
        fill='none'
        className='ms-4'>
        <path
          d='M2.04688 4.10078L6.12351 8.30078'
          stroke='white'
          stroke-width='1.5'
          stroke-linecap='round'
        />
        <path
          d='M10.1938 4.10078L6.11719 8.30078'
          stroke='white'
          stroke-width='1.5'
          stroke-linecap='round'
        />
      </svg>
    )
  }

  return (
    <div className='group-journey-dropdown'>
      <Dropdown>
        <Dropdown.Toggle
          id='group-journey-dropdown'
          variant='none'
          className={classNames(
            'd-flex justify-content-center align-items-center',
            { active: location.hash === '#group' },
          )}>
          Group {renderDownArrow()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {groupList.map((group, index) => (
            <Dropdown.Item
              as='span'
              className={classNames(
                'cursor-pointer p-0 s9 text-truncate d-block',
                {
                  active: group?.value === journeyGroupId,
                  'mb-0': index + 1 === groupList?.length,
                },
              )}
              onClick={() => {
                if (group?.value !== journeyGroupId) {
                  setGroupObject(group)
                }
              }}
              key={group?.value}>
              {group?.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default GroupJourneyDropdown
