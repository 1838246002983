import React from 'react'

const UserProfileIcon = ({ isActive, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className='user-profile-icon ms-3'
      fill={isActive ? '#bf9000' : 'none'}
      {...rest}>
      <path
        d='M6.35557 13.8445L6.3622 13.8238L6.367 13.8025C6.59261 12.8055 7.71944 12.3256 8.58745 12.8487L10.6818 14.1109C11.4712 14.5865 12.4576 14.5865 13.247 14.1109L15.3414 12.8487C16.2094 12.3256 17.3362 12.8055 17.5618 13.8025L17.5667 13.8241L17.5735 13.8451L19.4505 19.6863C19.5277 20.0672 19.3511 20.4986 18.9337 20.8775C18.5117 21.2606 17.9323 21.5 17.4348 21.5H6.49403C5.99682 21.5 5.41999 21.2609 5.00043 20.8783C4.58558 20.4999 4.40963 20.0683 4.48736 19.686L6.35557 13.8445ZM16.4425 7C16.4425 9.48735 14.4355 11.5 11.9644 11.5C9.49329 11.5 7.48632 9.48735 7.48632 7C7.48632 4.51265 9.49329 2.5 11.9644 2.5C14.4355 2.5 16.4425 4.51265 16.4425 7Z'
        stroke={isActive ? '#bf9000' : 'black'}
      />
    </svg>
  )
}

export default UserProfileIcon
