import React from 'react'
import { Table } from 'react-bootstrap'
import InfoIconOverlay from './InfoIconOverlay'

const CompareSubCategoryTable = ({
  comparisonArray,
  getCategoryDescription,
}) => {
  return (
    <div className='mb-52px'>
      <Table responsive className='custom-table compare-subcatgory-table'>
        <thead>
          <tr>
            <th width='40%' className='ps-0'>
              SUBCATEGORY
            </th>
            <th width='15%' className='text-center'>
              <div className='bg-blue-manager manager-result-compare-circle small-size-circle m-0-auto' />
            </th>
            <th width='15%' className='text-center'>
              <div className='bg-light-blue-manager manager-result-compare-square small-size-square m-0-auto' />
            </th>
            <th width='20%' className='text-center'>
              DIFFERENCE (+/-)
            </th>
            <th width='15%'></th>
          </tr>
        </thead>
        <tbody>
          {comparisonArray.map((e, index) => (
            <tr key={index} className='cursor-pointer'>
              <td width='40%' className='ps-0'>
                {e?.title}
              </td>
              <td width='15%' className='text-center text-blue-manager'>
                {e?.perceivedValue}%
              </td>
              <td width='20%' className='text-center text-light-blue-manager'>
                {e?.selfAwarenessValue}%
              </td>
              <td width='10%' className='text-center'>
                {e?.perceivedValue - e?.selfAwarenessValue}%
              </td>
              <td className='text-right pe-0'>
                <InfoIconOverlay contentText={getCategoryDescription(e?.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default CompareSubCategoryTable
