import React from 'react'
import { FeatherLoader } from '@abroad/components'
import classNames from 'classnames'

const ModuleActions = ({
  className = 'font-24 mb-4',
  isFavorite = false,
  toggleIsFavorite = () => {},
  rightMostIcon = '',
  handleRightMostIconClick = () => {},
  isFavoriteLoading = false,
  playIconLoading = false,
  // showInfoIcon = false,
  // showCheckmarkIcon = false,
}) => {
  return (
    <div className={classNames('d-flex align-items-center', className)}>
      <span
        onClick={toggleIsFavorite}
        className={`icon icon-bookmark${
          isFavorite ? '-gold' : ''
        } text-black cursor-pointer align-self-center`}
      />
      {isFavoriteLoading && (
        <span className='ms-3'>
          <FeatherLoader />
        </span>
      )}
      {/* todo */}
      {/* {playIconLoading && (
        <span className='ms-auto'>
          <FeatherLoader />
        </span>
      )} */}
      {rightMostIcon && (
        <div className='ms-auto'>
          <span
            className={`icon ${rightMostIcon} font-saffron-700 cursor-pointer align-self-center`}
            onClick={handleRightMostIconClick}
          />
        </div>
      )}
    </div>
  )
  // return (
  //   <div className={classNames('d-flex justify-content-between', className)}>
  //     <div>
  //       {/* <span className='icon icon-extra-settings text-black cursor-pointer align-self-center me-4' /> */}
  //       <div className='d-flex align-items-center me-4'>
  //         <span
  //           onClick={toggleIsFavorite}
  //           className={`icon icon-bookmark${
  //             isFavorite ? '-gold' : ''
  //           } text-black cursor-pointer align-self-center`}
  //         />
  //         {isFavoriteLoading && (
  //           <span className='ms-3'>
  //             <FeatherLoader />
  //           </span>
  //         )}
  //       </div>
  //       {showInfoIcon && (
  //         <span className='icon icon-info text-black cursor-pointer align-self-center me-4' />
  //       )}
  //       {showCheckmarkIcon && (
  //         <span className='icon icon-check-gold align-self-center me-4' />
  //       )}
  //     </div>
  //     {showPlayIcon && (
  //       <div>
  //         <span className='icon icon-play font-saffron-700 cursor-pointer align-self-center' />
  //       </div>
  //     )}
  //   </div>
  // )
}

export default ModuleActions
