import React, { useState, useEffect } from 'react'
import { Form as RBForm, Modal } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import { number, object, string } from 'yup'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ShimmerTitle } from 'react-shimmer-effects'
import {
  Button,
  AddCreditCard,
  useErrorService,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import confirmation from './PromoCodeStatusModal'
import API from '../../utils/API'
import PaymentEvent from '../../constants/events'
import { getCurrentExpiresDate } from '../../utils/utility'

const PaymentDetailsFormInitialValues = {
  ccNumber: '',
  cardMonth: '',
  cardYear: '',
  securityCode: '',
}

const validationSchema = object().shape({
  ccNumber: string().min(19).required(),
  cardMonth: number().min(1).max(12).required(),
  cardYear: number()
    .min(parseInt(moment().format('YY')))
    .required(),
  securityCode: number().max(999).required(),
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const PaymentDetailsForm = ({
  applyPromoFormRef,
  promoFormResult,
  planCode,
  oldPlan,
  newPlan,
  isPromoCodeAvailable,
  renderPromoForm,
  renderPaymentInformation,
  ...props
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const Error = useErrorService()
  const [stripeLoading, setStripeLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const Notification = useNotificationService()
  const { user, setUser } = useUserService()
  const [showPromoModal, setShowPromoModal] = useState(false)

  useEffect(() => {
    if (stripe) {
      setStripeLoading(false)
    }
  }, [stripe])

  useEffect(() => {
    if (promoFormResult?.finalPrice === 0) {
      setShowPromoModal(true)
    } else {
      setShowPromoModal(false)
    }
  }, [promoFormResult])

  const addCard = async () => {
    if (promoFormResult && promoFormResult.finalPrice === 0) {
      return { paymentMethodId: null }
    }
    try {
      setIsLoading(true)
      const data = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('cardNumber'),
      })
      if (data && !data?.error) {
        const { data: cardData } = await API.payment.attachPayMethod({
          paymentMethodId: data?.paymentMethod?.id,
        })
        if (cardData) {
          return { paymentMethodId: data?.paymentMethod?.id }
        }
      } else {
        setIsLoading(false)
        return { paymentMethodId: null, error: data?.error }
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
      return { paymentMethod: null, error: e }
    }
  }

  const upgrade = async ({ promoToken, stripeToken }) => {
    setIsLoading(true)
    const cardData = await addCard()
    if (cardData?.error || !cardData) return
    try {
      const { data } = await API.payment.pay({
        planCode,
        ...(cardData?.paymentMethodId && {
          paymentMethodId: cardData?.paymentMethodId,
        }),
        ...(promoToken && { promoToken: promoToken }),
      })
      if (data) {
        setUser({ ...user, planCode: planCode.toLowerCase() })
        Notification.showNotification('Your plan has been Upgraded!', 'success')
        setIsLoading(false)
        setShowPromoModal(false)
        props.setLink(data.link)
      }
    } catch (e) {
      setIsLoading(false)
      setShowPromoModal(false)
      if (e?.code === 'payment_fail') {
        Notification.showNotification(e.message)
      } else {
        Error.showError(e)
      }
    }
  }

  const onSubmit = (values) => {
    return values
  }

  if (stripeLoading) {
    return (
      <div className='text-center border-0'>
        <ShimmerTitle
          line={1}
          variant='primary'
          className='input-shimmer mb-2-rem'
        />
        <ShimmerTitle
          line={1}
          variant='primary'
          className='input-shimmer mb-2-rem'
        />
        <ShimmerTitle
          line={1}
          variant='primary'
          className='input-shimmer mb-2-rem'
        />
      </div>
    )
  }

  return (
    <>
      <Formik
        initialValues={PaymentDetailsFormInitialValues}
        onSubmit={onSubmit}
        {...(promoFormResult && promoFormResult.finalPrice === 0
          ? {}
          : { validationSchema: validationSchema })}>
        {({ submitForm, handleBlur, values, setFieldValue }) => (
          <>
            <RBForm
              as={Form}
              onSubmit={async (e) => {
                e.preventDefault()
                const currentExpires = getCurrentExpiresDate()
                document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_promo_code=;expires=${currentExpires};path=/`
                if (isPromoCodeAvailable && !promoFormResult) {
                  const result = await confirmation()
                  if (!result) {
                    return
                  }
                }
                //setIsLoading(true)
                if (promoFormResult && promoFormResult.finalPrice === 0) {
                  //GTM code
                  TagManager.dataLayer({
                    dataLayer: {
                      event: PaymentEvent.event.payment,
                      eventProps: {
                        category: PaymentEvent.category.paymentCategory,
                        action: 'User update plan',
                        label: 'Update plan',
                        value: `User update plan and user has oldPlan is ${oldPlan} newPlan is ${newPlan} in payment page`,
                        userId: user?.id,
                      },
                    },
                  })
                  upgrade({ promoToken: promoFormResult.token })
                } else {
                  // GTM code
                  TagManager.dataLayer({
                    dataLayer: {
                      event: PaymentEvent.event.payment,
                      eventProps: {
                        category: PaymentEvent.category.paymentCategory,
                        action: 'User update plan',
                        label: 'Update plan',
                        value: `User update plan and user has oldPlan is ${oldPlan} newPlan is ${newPlan} in payment page`,
                        userId: user?.id,
                      },
                    },
                  })
                  upgrade({
                    ...(promoFormResult && {
                      promoToken: promoFormResult.token,
                    }),
                  })
                }
              }}>
              <AddCreditCard />
              {renderPromoForm()}
              {renderPaymentInformation()}
              <Button
                type='submit'
                variant='saffron'
                className='text-uppercase line-height-normal w-100 s9 make-payment border-radius-normal'
                disabled={isLoading}
                isLoading={isLoading}
                size='md'>
                MAKE PAYMENT
              </Button>
            </RBForm>
          </>
        )}
      </Formik>
      <Modal
        show={showPromoModal}
        onHide={() => {}}
        centered
        contentClassName='border-radius-10 mx-1 px-2 py-4'>
        <Modal.Body className='px-4 border-0'>
          <h2 className='font-24 font-open-sans font-bold pb-4 leading-9'>
            100% Promo Code Applied
          </h2>
          <p className='font-14 font-open-sans fw-semibold leading-6'>
            You have applied a 100% promo code. Select submit to continue to the
            platform.
          </p>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-end px-4 pt-3 space-x-8'>
          <Button
            type='submit'
            className='px-2 px-lg-4 fw-semibold font-10 leading-6 py-2 tracking-lg-wide-point-25 secondary-popup-button promo-return-btn'
            variant='black'
            disabled={isLoading}
            onClick={() => {
              applyPromoFormRef.current.reset()
              setShowPromoModal(false)
            }}>
            Return
          </Button>
          <Button
            type='submit'
            className='px-2 px-lg-4 fw-semibold font-10 leading-6 py-2 tracking-lg-wide-point-25 border-radius-normal'
            variant='saffron'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => {
              upgrade({ promoToken: promoFormResult?.token })
            }}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const PaymentCardWrapperWithStripe = ({ ...props }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
          },
        ],
      }}>
      <PaymentDetailsForm {...props} />
    </Elements>
  )
}

export default PaymentCardWrapperWithStripe
