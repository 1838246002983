import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ABSpinner } from '@abroad/components'
import {
  AbroadGoldenIcon,
  SignupForm,
  SignupLeftSection,
  ReturnBtn,
} from '../components'
import AuthService from '../utils/AuthService'
import API from '../utils/API'
import {
  checkUserGetOldPlan,
  convertOldPlanWithNewPlan,
  getExpiresDate,
} from '../utils/utility'
import { InviteDetailsContext } from '../utils/contexts'

const Signup = ({ location: { search }, history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const query = new URLSearchParams(search)
  const { inviteLinkDetails } = useContext(InviteDetailsContext)

  useEffect(() => {
    if (search) {
      const expires = getExpiresDate(7) // 7 days after expired
      document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_search=${search};expires=${expires};path=/`
      const query = new URLSearchParams(search)
      const queryPlanCode = query.get('plan') || query.get('planCode')
      if (queryPlanCode) {
        // replace url & setPlan with new plan value
        const isOldPlanMatch = checkUserGetOldPlan(queryPlanCode)
        if (isOldPlanMatch) {
          const newPlanCode = convertOldPlanWithNewPlan(queryPlanCode)
          if (query.has('plan')) {
            query.set('plan', newPlanCode)
          } else {
            query.set('planCode', newPlanCode)
          }
          if (!AuthService.isLogedIn()) {
            history.replace({ search: query.toString() })
          }
        }
        const needPlanUpgrade = async () => {
          if (query.has('promo')) {
            query.set('promocode', query.get('promo'))
            query.delete('promo')
          }
          if (query.has('planCode')) {
            query.set('plan', query.get('planCode'))
            query.delete('planCode')
          }
          try {
            const { data } = await API.user.needPlanUpgrade(
              `?newPlan=${query.get('plan') || query.get('planCode')}`,
            )
            if (data?.needUpgrade) {
              history.replace(`/user-payment?${query.toString()}`)
            } else {
              history.replace('/home')
            }
          } catch (e) {
            history.replace('/home')
          }
        }
        if (AuthService.isLogedIn()) {
          setIsLoading(true)
          needPlanUpgrade()
        }
      }
    } else {
      if (AuthService.isLogedIn()) {
        history.replace('/home')
      }
    }
  }, [search, history])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{'Sign up | Abroad'}</title>
        <meta name='title' content={'Sign up | Abroad'}></meta>
        <meta property='og:title' content={'Sign up | Abroad'}></meta>
        <meta property='twitter:title' content={'Sign up | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/account`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/account`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/account`}></meta>
      </Helmet>
      <main>
        <Container className='px-0' fluid>
          <Row
            lg={currentStep === 0 ? 2 : 12}
            className='vh-100 row g-0 onboard bg-abroad-blue'>
            {currentStep === 0 && (
              <Col className='d-none d-lg-flex justify-content-center signup-left-section bg-abroad-blue'>
                <SignupLeftSection />
              </Col>
            )}
            <Col className='bg-abroad-blue'>
              <div className='h-100 d-flex flex-column align-items-center'>
                {!isLoading && <AbroadGoldenIcon />}
                <SignupForm
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                />
                {currentStep === 1 ? (
                  <ReturnBtn
                    onClick={() => {
                      setCurrentStep(0)
                    }}
                  />
                ) : (
                  <div className='page-bottom-link my-3'>
                    <span className='text-white'>
                      Already Have an Account?&nbsp;
                    </span>
                    <Link
                      to={`/?${query ? query.toString() : ''}`}
                      title='Sign In'>
                      <span
                        className={
                          inviteLinkDetails?.buttonColorHex
                            ? ''
                            : 'text-saffron'
                        }
                        {...(inviteLinkDetails?.buttonColorHex && {
                          style: {
                            color: inviteLinkDetails?.buttonColorHex,
                          },
                        })}>
                        Log In
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default Signup
