import React from 'react'
import classNames from 'classnames'
import { Button, useUserService } from '@abroad/components'
import labels from '../../constants/labels'
import { workStatus } from '../../utils/constants'

const CoachingPlanBtn = ({
  buttonClasses = '',
  buttonText = 'Upgrade To Growth Plan',
}) => {
  const { user } = useUserService()
  return (
    <Button
      variant='saffron'
      className={classNames('btn s10a text-uppercase', buttonClasses)}
      onClick={() => {
        const role = labels.roleListForUser.find(
          (role) => role.value === user?.profile?.roleInCompany,
        )
        if (user?.workStatus === workStatus.TRANSITION) {
          // redirect to abroad.io coaching plan based on role (CEO)
          window.open(labels.roleListForUser[4].url, '_blank') // labels.roleListForUser[4].url=ceo
        } else if (role) {
          // redirect to abroad.io coaching plan based on role
          window.open(role.url, '_blank')
        } else {
          // redirect to abroad.io coaching plan based on role (ic)
          window.open(labels.roleListForUser[0].url, '_blank') // labels.roleListForUser[0].url=ic
        }
      }}>
      {buttonText}
    </Button>
  )
}

export default CoachingPlanBtn
