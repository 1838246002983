import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import { useUserService } from '@abroad/components'
import InfoIconOverlay from './InfoIconOverlay'

const SortedSubCategoriesForLA = ({
  results,
  getCategoryName,
  getCategoryDescription,
}) => {
  const { user } = useUserService()
  const [sortedSubCategories, setSortedSubCategories] = useState([])
  const managerEfficacyId = '62963961a279e16eeb3aa867'
  const perceivedAwarenessId = '5b46b176ce416b175ff98e17'

  useEffect(() => {
    if (results) {
      const managerEfficacySubcategories = results?.[
        managerEfficacyId
      ].order.map((cat) => ({
        ...results?.[managerEfficacyId].subcategories[cat],
        key: cat,
        parentId: managerEfficacyId,
      }))

      const perceivedAwarenessSubcategories = results?.[
        perceivedAwarenessId
      ].order.map((cat) => ({
        ...results?.[perceivedAwarenessId].subcategories[cat],
        key: cat,
        parentId: perceivedAwarenessId,
      }))
      const subCategories = [
        ...managerEfficacySubcategories,
        ...perceivedAwarenessSubcategories,
      ].sort((a, b) => b.percent - a.percent)
      setSortedSubCategories(subCategories)
    }
  }, [results, user])

  const renderSubCategoriesBox = (category, index) => {
    return (
      <div className='d-flex category-details'>
        <Col className='col-9'>
          <h3 className='mb-0 s7'>{getCategoryName(category?.key)}</h3>
        </Col>
        <Col className='col-3 d-flex justify-content-end'>
          <p
            className={classNames('mb-0 align-self-center', {
              'text-manager-efficacy': category?.parentId === managerEfficacyId,
              'text-blue-manager': category?.parentId !== managerEfficacyId,
            })}>{`${category.percent}%`}</p>
          <InfoIconOverlay
            contentText={getCategoryDescription(category?.key)}
          />
          {/* <Link
            to={`/survey/category/${category?.key}`}
            className='text-decoration-none'> */}
          {/* <InfoIcon className='info-icon align-self-center cursor-pointer font-20 ms-3 text-decoration-none' /> */}
          {/* </Link> */}
        </Col>
      </div>
    )
  }

  return (
    <Row className='d-flex flex-lg-row flex-column mb-52px'>
      <Col
        lg={12}
        md={12}
        sm={12}
        className='mb-lg-0 mb-3 d-flex flex-lg-row flex-column'>
        <div className='mr-lg-12px w-100 mb-lg-0 mb-4'>
          <h3 className='s2 text-black mb-3'>Celebrations</h3>
          <div className='box-content'>
            {sortedSubCategories.slice(0, 3).map((category, index) => (
              <React.Fragment key={index}>
                {renderSubCategoriesBox(category, index)}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className='ml-lg-12px w-100'>
          <h3 className='s2 text-black mb-3'>Key Areas for Growth</h3>
          <div className='box-content'>
            {sortedSubCategories.slice(-3).map((category, index) => (
              <React.Fragment key={index}>
                {renderSubCategoriesBox(category, index)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default SortedSubCategoriesForLA
