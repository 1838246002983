import React, { useState, useEffect, useRef, useContext } from 'react'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'
import {
  Button,
  useErrorService,
  useUserService,
  plans,
  nameRegex,
} from '@abroad/components'
import { string, object } from 'yup'
import { Formik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { InputField, Checkbox } from '../form'
import Cookies from 'js-cookie'
import SignupEmailField from './SignupEmailField'
import SignupPasswordField from './SignupPasswordField'
import OnboardTitle from './OnboardTitle'
import OnboardSubTitle from './OnboardSubTitle'
import ClearCompanyValue from './ClearCompanyValue'
import API from '../../utils/API'
import { bugsnagClient } from '../../utils/BugsnagClient'
import SignUpEvent from '../../constants/events'
import { InviteDetailsContext } from '../../utils/contexts'
import { getCurrentExpiresDate, getExpiresDate } from '../../utils/utility'

const SignUpFormInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  password: '',
  confirmPassword: '',
  companyTeamName: '',
  teams: [],
  transition: false,
  selection: false,
}

const SignupForm = ({ setCurrentStep, currentStep }) => {
  const nodeRef = useRef(null)
  const [plan, setPlan] = useState(plans.free.code)
  const [isAlreadyEmailExist, setIsAlreadyEmailExist] = useState(false)
  const [referralCode, setReferralCode] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const User = useUserService()
  const Error = useErrorService()
  const history = useHistory()
  const location = useLocation()
  const expires = getExpiresDate(7) // 7 days after expired
  const currentExpires = getCurrentExpiresDate()
  let search = location.search
  const searchCookie = document.cookie
    ?.split('; ')
    ?.find((row) =>
      row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_search`),
    )
  if (searchCookie) {
    search = searchCookie?.substring(searchCookie?.indexOf('=') + 1)
  }
  const query = new URLSearchParams(search)
  const queryCompanyCode = query.get('c')
  const { inviteLinkDetails, isInviteLinkError } =
    useContext(InviteDetailsContext)
  const [disabledButton, setDisabledButton] = useState(false)
  const isMatch = !queryCompanyCode || (queryCompanyCode && isInviteLinkError)

  const validationSchema = object().shape({
    firstName: string().matches(nameRegex).max(35).required('Required'),
    lastName: string().matches(nameRegex).max(35).required('Required'),
    email: string()
      .email('Please enter a valid Email address')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      .max(255)
      .required('Please enter a valid Email address'),
    ...(isMatch && {
      company: string().when('transition', {
        is: false,
        then: string().required('Required').max(100),
        otherwise: string().notRequired(),
      }),
    }),
    password: string()
      .matches('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$', '')
      // eslint-disable-next-line no-useless-escape
      .matches(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/)
      .required('Required'),
  })

  useEffect(() => {
    if (search) {
      const queryPlanCode = query.get('plan') || query.get('planCode')
      if (queryPlanCode) {
        setPlan(queryPlanCode)
      }
      if (query.get('referralCode')) {
        setReferralCode(query.get('referralCode'))
      }
      const queryPromocode = query.get('promo') || query.get('promocode')
      if (queryPromocode) {
        document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_promo_code=${queryPromocode};expires=${expires};path=/`
      } else {
        document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_promo_code=;expires=${currentExpires};path=/`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`
    document.body.appendChild(script)
  }, [])

  const executeRecaptcha = async (action) => {
    let token = ''
    await window.grecaptcha
      .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: action })
      .then((res) => {
        token = res
      })
    return token
  }

  const gtmUserCookieAcceptAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SignUpEvent.event.cookie,
        eventProps: {
          category: SignUpEvent.category.cookieCategory,
          action: 'User accept cookie',
          label: 'User accept cookie',
          value: `User accept cookie analyticsCookies :
          ${Cookies.get('rcl_statistics_consent') === 'true' ? true : false},
        thirdPartyCookies:
          ${
            Cookies.get('rcl_marketing_consent') === 'true' ? true : false
          } ,necessaryCookies : true`,
        },
      },
    })
  }

  const gtmUserSignupAction = (data) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SignUpEvent.event.signUp,
        eventProps: {
          category: SignUpEvent.category.signUpCategory,
          action: 'User Sign Up successfully',
          label: 'User Sign Up',
          value: `User Sign Up with Id - ${data.id} and plan is ${data.planCode}`,
          userId: data?.id,
        },
      },
    })
  }

  const onSubmit = async (values) => {
    let reqData = {
      fname: values.firstName.trim(),
      lname: values.lastName.trim(),
      email: values.email.toLowerCase(),
      ...(isMatch && {
        transition: values.transition,
      }),
      password: values.password,
      plan: plan,
      ...(!values.transition &&
        isMatch && {
          companyName: values.company,
        }),
      ...(referralCode && { referralCode: referralCode }),
      ...(values.company &&
        values.companyTeamName !== '' && {
          companyTeamName: values.companyTeamName,
        }),
      ...(queryCompanyCode &&
        !isInviteLinkError && {
          companyCode: queryCompanyCode,
        }),
    }

    if (query.get('e') && !isInviteLinkError) {
      reqData.networkCode = query.get('e')
    }
    if (query.get('promo') || query.get('promocode')) {
      reqData.promoCode = query.get('promo') || query.get('promocode')
    }
    try {
      setIsLoading(true)
      setIsAlreadyEmailExist(false)
      const reCaptchaToken = await executeRecaptcha('submit')
      reqData.recaptchaToken = reCaptchaToken
      const { data } = await API.onboarding.signup(reqData)
      setIsLoading(false)
      document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_search=;expires=${currentExpires};path=/`
      if (data) {
        const userData = {
          _id: data.id,
          id: data.id,
          email: data.email,
          fullName: data.fullName,
          firstName: data.fname,
          lastName: data.lname,
          emailVerified: data?.emailVerified,
          showAffiliation: data.showAffiliation,
          surveyTaken: data.surveyTaken,
          workStatus: data.workStatus,
          planCode: data.planCode,
          paid: data.paid,
          isCoach: data.isCoach,
          isAdmin: data.isAdmin || data.admin,
          companyAdmin: data.companyAdmin,
          company: data.company,
          affiliation: data?.affiliation || [],
          referralCode: data?.referralCode,
          expiresOn: data?.expiresOn,
        }

        User.setUser(userData)

        //Cookie Accept code
        if (
          Cookies.get('rcl_consent_given') === 'true' &&
          Cookies.get('is_cookie_accept') === undefined
        ) {
          await API.user.acceptCookie(
            {
              cookiePreference: {
                analyticsCookies:
                  Cookies.get('rcl_statistics_consent') === 'true'
                    ? true
                    : false,
                thirdPartyCookies:
                  Cookies.get('rcl_marketing_consent') === 'true'
                    ? true
                    : false,
                cookiesAccepted: true,
              },
            },
            data.id,
          )
          gtmUserCookieAcceptAction()
          const date = new Date()
          date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
          document.cookie = `is_cookie_accept=true;expires=${date.toUTCString()};path=/`
        }
        gtmUserSignupAction(data)
        bugsnagClient.setUser({ id: data.id })
        setTimeout(() => {
          history.push(`/verify-email-sent${search ? search : ''}`, {
            email: values.email,
            firstName: values.firstName.trim(),
            from: '/account',
            inviteLinkDetails: inviteLinkDetails,
          })
        })
      }
    } catch (e) {
      setIsLoading(false)
      bugsnagClient.leaveBreadcrumb('Error State', e, 'error')
      bugsnagClient.notify('Signup Error')
      if (e?.code !== 'email_exists' || e?.code !== 'validation_error') {
        Error.showError(e)
      } else {
        setIsAlreadyEmailExist(true)
      }
    }
  }

  return (
    <Formik
      initialValues={SignUpFormInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ handleSubmit, values, errors }) => (
        <>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(e)
            }}
            className='d-flex mt-32px h-75'>
            <div
              ref={nodeRef}
              className='onboard-wrapper signup-form d-flex w-100'>
              {currentStep === 0 ? (
                <div className='d-flex flex-column'>
                  <div className='name-fields d-flex justify-content-between'>
                    <InputField
                      label='FIRST NAME'
                      name='firstName'
                      type='text'
                      formGroupProps={{ className: 'form-group' }}
                      size='lg'
                    />
                    <InputField
                      label='LAST NAME'
                      name='lastName'
                      type='text'
                      formGroupProps={{ className: 'form-group' }}
                      size='lg'
                    />
                  </div>
                  <SignupEmailField
                    label='EMAIL'
                    isAlreadyEmailExist={isAlreadyEmailExist}
                    setDisabledButton={setDisabledButton}
                  />
                  <SignupPasswordField
                    label='PASSWORD'
                    formGroupProps={{
                      className: 'd-flex flex-column position-relative',
                    }}
                    size='lg'
                  />
                  <Button
                    {...(inviteLinkDetails?.buttonColorHex
                      ? { variant: 'none' }
                      : { variant: 'saffron' })}
                    {...(inviteLinkDetails?.buttonColorHex && {
                      style: {
                        backgroundColor: inviteLinkDetails?.buttonColorHex,
                        color: inviteLinkDetails?.fontColorHex,
                        borderColor: inviteLinkDetails?.buttonColorHex,
                      },
                    })}
                    className={classNames('w-100 btn s9 onbording-action-btn', {
                      'btn-custom': inviteLinkDetails?.buttonColorHex,
                    })}
                    disabled={
                      isLoading ||
                      !values.firstName ||
                      !values.lastName ||
                      !values.email ||
                      !values.password ||
                      (!queryCompanyCode &&
                        (errors.firstName ||
                          errors.lastName ||
                          errors.email ||
                          errors.password)) ||
                      disabledButton
                    }
                    isLoading={isLoading}
                    {...(queryCompanyCode && !isInviteLinkError
                      ? { type: 'submit' }
                      : {
                          onClick: () => {
                            setCurrentStep(1)
                          },
                        })}>
                    {queryCompanyCode && !isInviteLinkError
                      ? 'SIGN UP'
                      : 'NEXT'}
                  </Button>
                </div>
              ) : (
                <div className='flex-column'>
                  <div>
                    <OnboardTitle>You’re Nearly There</OnboardTitle>
                    <OnboardSubTitle commonClass='s6 opacity-75 mt-2'>
                      Please let us know what company you are working for at the
                      moment, or if you are currently in a career transition.
                    </OnboardSubTitle>
                  </div>
                  <div>
                    <InputField
                      name='company'
                      label='COMPANY'
                      type='text'
                      formGroupProps={{ className: 'mb-3 mt-40px' }}
                      className={values.transition ? 'bg-image-none' : ''}
                      disabled={values.transition}
                      size='lg'
                    />
                    <ClearCompanyValue />
                    <Checkbox
                      className='mt-1'
                      name='transition'
                      type='checkbox'
                      label='Select if you are currently in a career transition'
                      labelProps={{
                        className: 'cursor-pointer text-white ms-1 s8',
                      }}
                      disabled={queryCompanyCode && !isInviteLinkError}
                    />
                  </div>
                  <Button
                    {...(inviteLinkDetails?.buttonColorHex
                      ? { variant: 'none' }
                      : { variant: 'saffron' })}
                    {...(inviteLinkDetails?.buttonColorHex && {
                      style: {
                        backgroundColor: inviteLinkDetails?.buttonColorHex,
                        color: inviteLinkDetails?.fontColorHex,
                        borderColor: inviteLinkDetails?.buttonColorHex,
                      },
                    })}
                    className={classNames('w-100 btn s9 onbording-action-btn', {
                      'btn-custom': inviteLinkDetails?.buttonColorHex,
                    })}
                    disabled={isLoading || disabledButton}
                    isLoading={isLoading}
                    {...((!queryCompanyCode ||
                      (queryCompanyCode && isInviteLinkError)) && {
                      type: 'submit',
                    })}>
                    SIGN UP
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default SignupForm
