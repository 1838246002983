import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CKEditor from '../editor'
import { Button, ModelContext, useErrorService } from '@abroad/components'
import API from '../../utils/API'

const GlobalJournalModal = ({
  updateLocalJournals,
  mediaId,
  mediaMetadata,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { show, onHide, data } = useContext(ModelContext)

  const Error = useErrorService()
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (isEditorReady) {
      setTimeout(() => {
        setDescription(data?.journal || '')
      }, 50)
    }
  }, [data, isEditorReady])

  const saveChanges = async () => {
    if (data?._id) {
      try {
        setIsLoading(true)
        await API.journal.saveGlobalJournal(data._id, {
          journal: description,
        })
        setIsLoading(false)
        onHide()
        updateLocalJournals({ ...data, journal: description }, false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    } else {
      try {
        setIsLoading(true)
        let createData = {
          type: 'text',
          journal: description,
        }
        if (mediaId) {
          createData.mediaId = mediaId
        }
        const { data: response } = await API.journal.createGlobalJournal(
          createData,
        )
        setIsLoading(false)
        onHide()
        updateLocalJournals(response, true)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
  }

  return (
    <Modal
      show={show}
      size='lg'
      onHide={onHide}
      aria-labelledby='Journal'
      centered>
      <Modal.Body>
        <div className='mb-3'>
          <span className='s3'>{mediaMetadata?.title || 'Journal'}</span>
        </div>
        {mediaMetadata?.question && (
          <div className='mb-3'>
            <div className='s3 mb-1'>Self Reflection Question</div>
            <span className='s5'>{mediaMetadata?.question}</span>
          </div>
        )}
        <CKEditor
          data={description}
          onReady={(editor) => {
            setIsEditorReady(true)
          }}
          onChange={(event, editor) => {
            setDescription(editor.getData())
          }}
        />
        <div className='d-flex justify-content-end mt-3'>
          <Button
            variant='saffron-square'
            disabled={isLoading || !description?.trim()}
            isLoading={isLoading}
            className={'text-white s10a border-radius-3'}
            onClick={saveChanges}>
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default GlobalJournalModal
