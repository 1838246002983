import React, { useLayoutEffect, useContext } from 'react'
import { Container } from 'react-bootstrap'
import classnames from 'classnames'
import { matchPath } from 'react-router'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { coachRecommendsUrl } from '@abroad/components'
import Sidebar from './Sidebar'
import { LayoutContext } from '../../utils/contexts'
import { useResolutionService } from '../../hooks'

const tableMaxWidth = 1080
const tableMinWidth = 1024

export const conditionMatched =
  window.innerWidth <= tableMaxWidth && window.innerWidth >= tableMinWidth

const Layout = ({ children }) => {
  const { showPlayBar, showSidebar } = useContext(LayoutContext)
  const location = useLocation()
  let match = useRouteMatch('/media')
  const { isLGScreen, isXSScreen } = useResolutionService()
  // if this condition matched, sidebar with be 3 columns and body content will be 7 columns

  const mArr = [
    '/profile',
    '/profile/my-information',
    '/profile/plans-payments',
    '/profile/update-password',
  ]

  const exceptionPages = [
    '/survey/comparison-report', //Compare Results for individual
    '/survey/leader-assessments', // Leader-Assessment
    '/survey/leader/:id', // Leader-Assessment Results
    '/survey/:id', // Awareness-Assessment Results
  ]

  const isexceptionPage = exceptionPages.includes(location.pathname)

  const matched = isexceptionPage
    ? false
    : matchPath(location.pathname, {
        path: mArr,
        exact: true,
        strict: false,
      })

  const modulePage = matchPath(location.pathname, {
    path: '/module/:programId/:moduleId',
    exact: true,
    strict: false,
  })

  const isCustomPaddingPage = matchPath(location.pathname, {
    path: [`/${coachRecommendsUrl}`],
    exact: true,
    strict: false,
  })

  // Scroll to top if path changes
  useLayoutEffect(() => {
    document.getElementById('content').scrollTop = 0
  }, [location.pathname])

  return (
    <Container fluid className='p-0 vh-100 overflow-hidden'>
      <div
        id='content'
        className={classnames(
          'row position-relative w-100 m-0 h-100 overflow-auto',
          {
            'layout-with-play-bar': showPlayBar,
          },
          {
            'g-0': isLGScreen || matched,
          },
          {
            'g-0': !isLGScreen && modulePage,
          },
        )}>
        <div
          className={classnames(
            'sidebar-content col-lg-2 col-12 ps-0 custom-pr-sm-0',
            {
              hide: !showSidebar,
            },
            {
              'col-lg-3': conditionMatched,
            },
          )}>
          <Sidebar />
        </div>
        <div
          className={classnames(
            'col-lg-2 ps-0 content-gutter d-none d-lg-block custom-pr-sm-0',
            {
              'col-lg-3': conditionMatched,
            },
          )}
        />
        <div
          className={classnames('col-12 pb-5 pb-lg-5', {
            'custom-pl-sm-0 custom-pr-sm-0': match,
            'offset-lg-1 col-lg-8': !matched && !conditionMatched,
            'col-lg-10 body-content': matched && !conditionMatched,
            'px-3': isXSScreen && isCustomPaddingPage,
            'col-lg-7 offset-lg-1 ': conditionMatched,
          })}>
          <div className={classnames('pt-0 pt-sm-sidebar')}>{children}</div>
        </div>
      </div>
    </Container>
  )
}

export default Layout
