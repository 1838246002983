import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, plans, useUserService } from '@abroad/components'
import { ProgramContext } from '../../utils/contexts'

const ProgramEnrollBtn = () => {
  const history = useHistory()
  const { programDetails, setShowEnrollBtn } = useContext(ProgramContext)
  const [isLesserThanTarget, setIsLesserThanTarget] = useState(false)
  const { user } = useUserService()
  const { pathname } = useLocation()
  const paymentPageURL = '/user-payment'
  const query = new URLSearchParams('')
  const isPaymentPage = pathname.includes('user-payment')
  const [urlParams, setUrlParams] = useState('')
  const userPlanCode = user?.planCode

  useEffect(() => {
    if (programDetails?.targetPlan) {
      query.set('plan', programDetails?.targetPlan)
      const userPlan = plans[userPlanCode]
      const targetPlan = plans[programDetails.targetPlan]
      setShowEnrollBtn(
        programDetails?.targetPlan &&
          programDetails?.targetPlan !== plans.free.code &&
          userPlan?.sequence < targetPlan?.sequence,
      )
      setIsLesserThanTarget(userPlan?.sequence < targetPlan?.sequence)
    } else {
      setIsLesserThanTarget(false)
      setShowEnrollBtn(false)
    }
    if (programDetails?.networkPromo) {
      query.set('promo', programDetails?.networkPromo)
    }
    setUrlParams(query.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programDetails, userPlanCode])

  return (
    <>
      {programDetails?.targetPlan &&
      programDetails?.targetPlan !== plans.free.code &&
      isLesserThanTarget &&
      !isPaymentPage ? (
        <div className='me-3'>
          <Button
            variant='abroad-blue'
            onClick={() => {
              history.push(
                `${paymentPageURL}${urlParams ? `?${urlParams}` : ''}`,
              )
            }}
            className='btn s8 text-uppercase border-radius-5 line-height-normal enroll-btn'>
            ENROLL IN YOUR PROGRAM
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default ProgramEnrollBtn
