import React, { useState, useEffect, useContext } from 'react'
import { useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { InputField, EmailExistError } from '../form'
import API from '../../utils/API'
import { bugsnagClient } from '../../utils/BugsnagClient'
import { groupId, localStorageItemName, uniqueId } from '../../utils/constants'
import { InviteDetailsContext } from '../../utils/contexts'

const SignupEmailField = ({
  label,
  isAlreadyEmailExist,
  setDisabledButton,
}) => {
  const {
    values: { email },
    handleBlur,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    handleChange,
    validateField,
    touched: { email: emailFieldTouched },
  } = useFormikContext()
  const Error = useErrorService()
  const [isEmailExist, setIsEmailExist] = useState(isAlreadyEmailExist)
  const surveyRequestId = localStorage.getItem(localStorageItemName)
  const groupRequestId = localStorage.getItem(groupId)
  const uniqueRequestId = localStorage.getItem(uniqueId)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const queryCompanyCode = query.get('c')
  const queryNetworkCode = query.get('e')
  const { isInviteLinkError } = useContext(InviteDetailsContext)
  const inviteLinkErrorCodeArray = [
    'register_with_invite_link',
    'register_with_org_provided_email',
  ]

  useEffect(() => {
    setIsEmailExist(isAlreadyEmailExist)
  }, [isAlreadyEmailExist])

  useEffect(() => {
    const getEmailBySurveyRequestId = async () => {
      try {
        const { data } = await API.survey.getEmailBySurveyRequestId(
          surveyRequestId,
        )
        setFieldValue('email', data.receiverEmail)
        setFieldTouched('email', true)
        checkEmailExist()
      } catch (e) {
        Error.showError(e)
      }
    }

    const getEmailOfLeaderRequest = async () => {
      try {
        const { data } = await API.survey.getEmailForLeaderRequest(
          groupRequestId,
          uniqueRequestId,
        )
        setFieldValue('email', data.receiverEmail)
        setFieldTouched('email', true, false)
      } catch (e) {
        Error.showError(e)
      }
    }

    if (surveyRequestId !== null) getEmailBySurveyRequestId()
    if (groupRequestId && uniqueRequestId) getEmailOfLeaderRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyRequestId, groupRequestId, uniqueRequestId])

  const checkEmailExist = async (event) => {
    if (emailFieldTouched && email) {
      try {
        const { status } = await API.onboarding.checkEmail({
          email,
          ...(queryCompanyCode &&
            !isInviteLinkError && {
              companyCode: queryCompanyCode,
            }),
          ...(queryNetworkCode &&
            !isInviteLinkError && {
              networkCode: queryNetworkCode,
            }),
        })
        if (!status) {
          setIsEmailExist(false)
          validateField('email')
        }
        setDisabledButton(false)
      } catch (e) {
        bugsnagClient.leaveBreadcrumb('Error State', e, 'error')
        bugsnagClient.notify('Email Exist Error')
        if (e?.code === 'email_exists') {
          setDisabledButton(true)
          setIsEmailExist(true)
          setFieldError('email', '\0')
        } else if (inviteLinkErrorCodeArray.includes(e?.code)) {
          setDisabledButton(true)
          Error.showError(e)
        } else {
          Error.showError(e)
        }
      }
    }
  }
  return (
    <>
      <InputField
        autoComplete='off'
        label={label}
        name='email'
        type='email'
        onChange={(event) => {
          if (isEmailExist) {
            setIsEmailExist(false)
          }
          handleChange(event)
        }}
        onBlur={(event) => {
          handleBlur(event)
          checkEmailExist(event)
        }}
        formGroupProps={{ className: isEmailExist ? '' : 'form-group' }}
        size='lg'
      />
      {isEmailExist && <EmailExistError email={email} />}
    </>
  )
}

export default SignupEmailField
