import React from 'react'
import { Switch, Redirect, useLocation } from 'react-router-dom'
import {
  useUserService,
  simple360Url,
  coachRecommendsUrl,
  leaderAssessmentsUrl,
} from '@abroad/components'
import PrivateRoute from './PrivateRoute'
import {
  NoAwarenessAssessment,
  PersonalSurveyResult,
  SurveyComparison,
  Home,
  MediaInfo,
  Favorites,
  Profile,
  SurveyCategory,
  MediaLibrary,
  NotFound,
  UserPayment,
  UnsubscribeEmail,
  UnderMaintenance,
  Program,
  ProgramsOverview,
  Simple360,
  LeaderAssessments,
  JourneysWrapper,
  GrowthPlan,
  Intention,
  LAResults,
  AskQuestion,
  DownloadApp,
  GlobalJournal,
  CoachingProvider,
  SessionDetails,
} from '../pages'
import { ModuleDetails } from '../components'
import { Layout, MediaControlWrapper } from '../components/layout'

/**
 * Navigaton routes for the app
 *
 * @info [react-router-dom](https://reactrouter.com/web/guides/quick-start)
 */
const AuthenticatedRoutes = () => {
  const { user } = useUserService()
  const location = useLocation()

  return (
    <Switch>
      <PrivateRoute exact path='/404' component={NotFound} />
      <PrivateRoute
        exact
        path='/under-maintenance'
        component={UnderMaintenance}
      />
      <PrivateRoute
        exact
        path='/email/unsubscribe'
        component={UnsubscribeEmail}
      />
      <MediaControlWrapper>
        <Switch>
          <PrivateRoute
            path='/media/:mediaCategory/:categoryId/:mediaId'
            component={MediaInfo}
          />
          <PrivateRoute
            path={[
              '/survey',
              '/survey/comparison-report',
              '/survey/:id',
              '/survey/leader/:id',
              '/survey/category/:category',
              '/module/:programId/:moduleId',
              '/breakthrough',
              '/programs',
              '/program/:programId',
              '/media-library/:id?',
              '/home',
              '/home/purpose-vision',
              '/home/values-principles',
              '/favorites',
              '/user-payment',
              '/on-demand',
              '/Profile',
              '/Profile/update-password',
              '/coaching',
              '/coaching/intentions',
              '/coaching/intentions/:intentionId',
              '/coaching/sessions',
              '/coaching/session/:sessionId',
              '/coaching/objectives',
              `/survey/requests/${leaderAssessmentsUrl}`,
              `/survey/requests/${leaderAssessmentsUrl}/:id`,
              `/survey/requests/${simple360Url}`,
              `/${coachRecommendsUrl}`,
              '/ask-question',
              '/download-app',
              '/journal',
              '/journal/:categoryType/:subCategoryId/:mediaId',
            ]}>
            <Layout>
              <Switch>
                <PrivateRoute
                  path={`/survey`}
                  component={NoAwarenessAssessment}
                  exact
                />
                <PrivateRoute
                  path={[`/survey/requests/${simple360Url}`]}
                  component={Simple360}
                  exact
                />
                <PrivateRoute
                  path={[
                    `/survey/requests/${leaderAssessmentsUrl}`,
                    `/survey/requests/${leaderAssessmentsUrl}/:id`,
                  ]}
                  component={LeaderAssessments}
                  exact
                />
                <PrivateRoute
                  exact
                  path={`/survey/comparison-report`}
                  component={SurveyComparison}
                />
                <PrivateRoute
                  exact
                  path={`/survey/:id`}
                  component={PersonalSurveyResult}
                />
                <PrivateRoute
                  exact
                  path={`/survey/leader/:id`}
                  component={LAResults}
                />
                <PrivateRoute
                  exact
                  path={`/survey/category/:category`}
                  component={SurveyCategory}
                />
                <PrivateRoute
                  path='/module/:programId/:moduleId'
                  component={ModuleDetails}
                />
                <PrivateRoute
                  exact
                  path='/program/:programId'
                  component={Program}
                />
                <PrivateRoute
                  exact
                  path='/programs'
                  component={ProgramsOverview}
                />
                <PrivateRoute
                  exact
                  path='/media-library/:id?'
                  component={MediaLibrary}
                />
                <PrivateRoute
                  exact
                  path={[
                    '/home',
                    '/home/purpose-vision',
                    '/home/values-principles',
                  ]}
                  component={Home}
                />
                <PrivateRoute path='/favorites' component={Favorites} />
                <PrivateRoute
                  exact
                  path='/user-payment'
                  component={UserPayment}
                />
                <PrivateRoute
                  path={[
                    `/Profile`,
                    '/profile/my-information',
                    '/profile/plans-payments',
                  ]}
                  component={Profile}
                  exact
                />
                <PrivateRoute
                  exact
                  path={`/Profile/update-password`}
                  component={Profile}
                />
                <PrivateRoute
                  exact
                  path={`/coaching/intentions`}
                  component={GrowthPlan}
                />
                <PrivateRoute
                  exact
                  path={`/coaching/intentions/:intentionId`}
                  component={Intention}
                />
                <PrivateRoute
                  exact
                  path={`/coaching/sessions`}
                  component={CoachingProvider}
                />
                <PrivateRoute
                  exact
                  path={`/coaching/session/:sessionId`}
                  component={SessionDetails}
                />
                <PrivateRoute
                  exact
                  path={`/${coachRecommendsUrl}`}
                  component={JourneysWrapper}
                />
                <PrivateRoute
                  exact
                  path='/ask-question'
                  component={AskQuestion}
                />
                <PrivateRoute
                  exact
                  path='/download-app'
                  component={DownloadApp}
                />
                <PrivateRoute
                  exact
                  path={[
                    `/journal`,
                    '/journal/:categoryType/:subCategoryId/:mediaId',
                  ]}
                  component={GlobalJournal}
                />
                {user ? (
                  <Redirect to='/home' />
                ) : (
                  <Redirect
                    to={`/${location?.search ? location?.search : ''}`}
                  />
                )}
              </Switch>
            </Layout>
          </PrivateRoute>
          {user ? (
            <Redirect to='/home' />
          ) : (
            <Redirect to={`/${location?.search ? location?.search : ''}`} />
          )}
        </Switch>
      </MediaControlWrapper>
    </Switch>
  )
}

export default AuthenticatedRoutes
