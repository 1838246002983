import classNames from 'classnames'
import React from 'react'

// todo: https://stackoverflow.com/a/74473418
const CircleDotSVG = () => {
  return (
    <svg
      className='mx-2'
      xmlns='http://www.w3.org/2000/svg'
      width='4'
      height='4'
      viewBox='0 0 4 4'
      fill='none'>
      <circle cx='2' cy='2' r='2' fill='#BF9000' />
    </svg>
  )
}

const ModuleMiniDetails = ({ details = {}, className = 'mb-12px' }) => {
  const { label1 = '', label2 = '', label3 = '' } = details
  return (
    <div
      className={classNames(
        's11 font-saffron-700 d-flex align-items-center',
        className,
      )}>
      <span className='text-capitalize'>{label1}</span>
      <CircleDotSVG />
      <span>{label2}</span>
      {label3 && (
        <>
          <CircleDotSVG />
          <span>{label3}</span>
        </>
      )}
    </div>
  )
}

export default ModuleMiniDetails
