import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { Container, Button, Modal, Spinner } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import {
  ABSpinner,
  useErrorService,
  useUserService,
  plans,
} from '@abroad/components'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import queryString from 'query-string'
import { PaymentSuccessIcon } from '@abroad/components'
import {
  ApplyPromoForm,
  PaymentDetailsForm,
  MediaPageTitle,
  OrderSummaryText,
  PageHeader,
} from '../components'
import API from '../utils/API'
import {
  checkUserGetOldPlan,
  convertOldPlanWithNewPlan,
} from '../utils/utility'

const UserPayment = () => {
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(false)
  const [needUpgrade, setNeedUpgrade] = useState(null)
  const [promoFormResult, setPromoFormResult] = useState()
  const [isPromoCodeAvailable, setIsPromoCodeAvailable] = useState(false)
  const [showTakeAssessmentModal, setShowTakeAssessmentModal] = useState(false)
  let { search } = useLocation()
  const query = new URLSearchParams(search)
  let history = useHistory()
  let planCode = query.get('plan') || query.get('planCode')
  const [successModal, setSuccessModal] = useState(false)
  const [link, setLink] = useState(null)
  const applyPromoFormRef = useRef(null)
  if (planCode === plans.free.code) {
    planCode = plans.growth.code
  }
  const { user, setUser } = useUserService()
  const [isplanSwitchLoading, setIsplanSwitchLoading] = useState(false)
  const queryParams = queryString.parse(search)

  useEffect(() => {
    const needPlanUpgrade = async () => {
      try {
        const { data } = await API.user.needPlanUpgrade(`?newPlan=${planCode}`)
        if (!data?.needUpgrade) {
          history.replace('/survey')
        } else {
          setNeedUpgrade(data)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        history.replace('/survey')
      }
    }
    if (planCode) {
      // replace url & setPlan with new plan value
      const isOldPlanMatch = checkUserGetOldPlan(planCode)
      if (isOldPlanMatch) {
        const { newQueries } = convertOldPlanWithNewPlan(
          planCode,
          queryParams,
          query,
        )

        history.replace({ search: queryString.stringify(newQueries) })
        return
      }
    }
    if (planCode && plans[planCode]?.sequence > plans.free.sequence) {
      needPlanUpgrade()
    } else {
      history.replace('/survey')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planCode])

  const setPromoCodeResult = (result) => {
    setPromoFormResult(result)
  }

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{'Payment | Abroad'}</title>
        <meta name='title' content={'Payment | Abroad'}></meta>
        <meta property='og:title' content={'Payment | Abroad'}></meta>
        <meta property='twitter:title' content={'Payment | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/user-payment`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/user-payment`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/user-payment`}></meta>
      </Helmet>
    )
  }

  if (isLoading) {
    return (
      <>
        {renderHelmet()}
        <ABSpinner />
      </>
    )
  }

  const userSwitchToFreePlan = async (event) => {
    event.preventDefault()
    setIsplanSwitchLoading(true)
    try {
      const { status } = await API.user.switchToFreePlan()

      if (status) {
        const { data } = await API.survey.getLink()
        if (data) {
          setLink(data?.link ? data?.link : null)
          setShowTakeAssessmentModal(true)
          setIsplanSwitchLoading(false)
          setUser({ ...user, planCode: plans.free.code })
        } else {
          setIsplanSwitchLoading(false)
          setUser({ ...user, planCode: plans.free.code })
        }
        //history.push('/home')
      }
    } catch (e) {
      setIsplanSwitchLoading(false)
      Error.showError(e)
    }
  }

  const renderFreePlanLink = () => {
    const planExpireDate = moment(user?.expiresOn).format('YYYY-MM-DD')
    const currentDate = moment().format('YYYY-MM-DD')

    if (
      moment(planExpireDate).isSameOrBefore(currentDate) ||
      (!user?.expiresOn && user?.planCode !== plans.free.code)
    ) {
      return (
        <span className='text-gray-800 font-open-sans'>
          &nbsp;If you are not yet ready to enroll,&nbsp;
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={(event) => {
              userSwitchToFreePlan(event)
            }}
            className='under-line text-gray-800 font-open-sans pointer-cursor'
            // eslint-disable-next-line no-script-url
            href='javascript:void(0)'>
            click here to take our free assessment.
          </a>
          {isplanSwitchLoading && (
            <Spinner
              animation='border'
              className='text-saffron-700 report-loader ms-2'
            />
          )}
        </span>
      )
    }
  }

  const renderPaymentInformation = () => {
    return (
      <div className='mb-2-rem'>
        {needUpgrade?.newPlan.name && (
          <OrderSummaryText
            label='Program'
            text={needUpgrade?.newPlan.name}
            labelClasses='text-dark-900-5 fw-semibold'
            textClasses='text-dark-900-5 fw-semibold'
            separatorClasses={promoFormResult ? 'opacity-50' : ''}
          />
        )}
        {needUpgrade?.newPlan.price && (
          <OrderSummaryText
            label='Cost'
            text={`$${needUpgrade?.newPlan.price} USD`}
            labelClasses={
              promoFormResult
                ? 'text-dark-900-5 fw-semibold'
                : 'text-dark-900 fw-bold'
            }
            textClasses={
              promoFormResult
                ? 'text-dark-900-5 fw-semibold'
                : 'text-dark-900 fw-bold'
            }
            separatorClasses='opacity-50'
            isShowSeparator={promoFormResult ? true : false}
          />
        )}
        {promoFormResult && (
          <React.Fragment>
            <OrderSummaryText
              label='Discount'
              text={`$${promoFormResult.discount} USD`}
              labelClasses='text-green-100 fw-semibold'
              textClasses='text-green-100 fw-semibold'
            />
            <OrderSummaryText
              label='Total'
              text={`$${promoFormResult.finalPrice} USD`}
              labelClasses='text-dark-900 fw-bold'
              textClasses='text-dark-900 fw-bold'
              isShowSeparator={false}
            />
          </React.Fragment>
        )}
      </div>
    )
  }

  return (
    <>
      {renderHelmet()}
      <main>
        <PageHeader />
        <section className='position-relative user-payment abroad-fs-exclude'>
          <PaymentSuccessModal show={successModal} link={link} />
          <PaymentSuccessModal
            show={showTakeAssessmentModal}
            link={link}
            hideTitleText={true}
          />
          <promoCodeStatusModal />
          <MediaPageTitle
            title='Payment Details'
            description={
              'To complete your enrollment in this program, please make your payment below.'
            }
            extraDescription={renderFreePlanLink()}
          />
          <Container fluid>
            <div className='payment-details mt-4 justify-content-between flex-column-reverse flex-lg-row'>
              <PaymentDetailsForm
                applyPromoFormRef={applyPromoFormRef}
                isPromoCodeAvailable={isPromoCodeAvailable}
                promoFormResult={promoFormResult}
                planCode={needUpgrade?.newPlan.code}
                oldPlan={needUpgrade?.oldPlan.name}
                newPlan={needUpgrade?.newPlan.name}
                setLink={(e) => {
                  setLink(e)
                  setSuccessModal(true)
                }}
                renderPromoForm={() => {
                  return (
                    <React.Fragment>
                      {needUpgrade?.newPlan.code && (
                        <ApplyPromoForm
                          ref={applyPromoFormRef}
                          setPromoCodeResult={setPromoCodeResult}
                          isPromoCodeApplied={(status) => {
                            setIsPromoCodeAvailable(status)
                          }}
                          planCode={needUpgrade?.newPlan.code}
                        />
                      )}
                    </React.Fragment>
                  )
                }}
                renderPaymentInformation={renderPaymentInformation}
              />
            </div>
          </Container>
        </section>
      </main>
    </>
  )
}

const PaymentSuccessModal = ({ show, link, hideTitleText = false }) => {
  const history = useHistory()
  return (
    <Modal
      show={show}
      onHide={() => {}}
      centered
      className='delete-intention-box'>
      <Modal.Body className='pb-0 pt-3 px-4 border-0 text-center'>
        <PaymentSuccessIcon />
        {!hideTitleText && (
          <h4 className='pay-sucess-txt pt-2'>Payment Successful!</h4>
        )}
        {link ? (
          <p className={classNames('font-18', { 'pt-2': hideTitleText })}>
            You may now begin your awareness assessment.
          </p>
        ) : (
          <p className='font-18'>
            Your account has been successfully upgraded.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className='border-0 d-flex justify-content-center px-4 space-x-3'>
        <Button
          type='submit'
          className={classNames('px-2 px-lg-4', {
            'secondary-popup-button': !link,
          })}
          variant={link ? 'black' : 'none'}
          onClick={() => {
            if (link) {
              window.open(link, '_self')
            } else {
              history.push('/survey')
            }
          }}>
          {link ? <>Start now</> : <>Close</>}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserPayment
