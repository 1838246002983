// guide: this file contains bottom playbar UI, play prev/next come from MediaControler.js
import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react'
import { Row, Col, Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { NavLink, useHistory } from 'react-router-dom'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import TagManager from 'react-gtm-module'
import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'
import { VolumeIcon, VolumeMuteIcon, useUserService } from '@abroad/components'
import {
  BackwardIcon,
  PreviousIcon,
  NextIcon,
  ForwardIcon,
} from '../assetsComponents'
import { LayoutContext } from '../../utils/contexts'
import API from '../../utils/API'
import { checkIsProgramAudio, isSafari } from '../../utils/utility'
import { ModuleAudio } from '../common'
import ProgressBar from './ProgressBar'
import AudioElement from './AudioElement'
import PlayBarImage from './PlayBarImage'
import VolumeBar from './VolumeBar'
import AudioControl from './AudioControl'
import TrackAudioPlayer from '../../constants/events'
import MediaControler from './MediaControler'
import PlaybarJournal from './PlaybarJournal'
import classNames from 'classnames'
momentDurationFormatSetup(moment)

const MediaControlBar = React.memo(
  ({
    isLoading,
    playPrev,
    playNext,

    bufferSize,
    getMedia,
    setBufferSize,
    volumeLevel,
    setVolumeLevel,

    toggleIsFavorite,
    clickedTime,
    setClickedTime,
    ...rest
  }) => {
    const {
      audio,
      setAudio,
      showPlayBar,
      mediaObject,
      duration,
      curTime,
      setTempTime,
      contextJourneyId,
      contextJourneyAudioId,
      journeyGroupId,
      isMediaLoading,
      minimizePlayBar,
      setMinimizePlayBar,
    } = useContext(LayoutContext)
    const [isSMAudioControlVisible, setIsSMAudioControlVisible] =
      useState(false)
    const { user } = useUserService()
    const userId = user?.id
    const isProgramAudio = checkIsProgramAudio(mediaObject?.category?.key)
    let timeout = useRef(null)
    // let getUrl = useRouteMatch('/media/:mediaCategory/:categoryId/:mediaId')
    // const isMediaLibraryAudio = getUrl?.params?.mediaCategory === 'category'
    // const mediaId = getUrl?.params?.mediaId

    const history = useHistory()

    useEffect(() => {
      if (mediaObject !== null) {
        if (
          audio &&
          audio.currentSrc !== mediaObject?.mediaUrl &&
          audio.dataset.id === mediaObject?.id
        ) {
          audio.src = mediaObject?.mediaUrl
        } else {
          const audioEle = document.getElementById('audio')
          setAudio(audioEle)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaObject])

    // useEffect(() => {
    //   if (mediaObject) {
    //     if (isLGScreen && isMediaLibraryAudio && mediaId === mediaObject?.id) {
    //       setShowPlayBar(false)
    //     } else {
    //       setShowPlayBar(true)
    //     }
    //   }
    // }, [setShowPlayBar, mediaId, mediaObject, isMediaLibraryAudio])

    useEffect(() => {
      return () => {
        if (timeout.current) clearTimeout(timeout.current)
      }
    }, [])
    // useEffect(() => {
    //   if (mediaObject !== null) {
    //     const volumeAudioEle = document.getElementById('volumeAudio')
    //     setVolumeAudio(volumeAudioEle)
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [mediaObject])

    // const updateStatusAPICall = (completedTime) => {
    //   if (mediaObject?.category.key === 'breakthrough_program') { // TODO: set dynamic conditional when code is uncomented
    //     return API.programs.updateMediaStatus(
    //       {
    //         duration: completedTime,
    //       },
    //       mediaObject?.subCategory._id,
    //       mediaObject?.id,
    //     )
    //   } else {
    //     return API.mediaLibrary.updateMediaStatus(
    //       {
    //         duration: completedTime,
    //       },
    //       mediaObject?.id,
    //     )
    //   }
    // }

    const updateStatus = async (completedTime) => {
      try {
        await API.mediaLibrary.updateMediaStatus(
          {
            duration: completedTime,
          },
          mediaObject?.id,
          contextJourneyId,
          contextJourneyAudioId,
        )
      } catch (e) {
        // update Status fail
      }
    }

    const audioProgress = (percentage) => {
      return curTime > (duration * percentage) / 100
    }

    const audio10PercentageStatus = audioProgress(10)
    const audio20PercentageStatus = audioProgress(20)
    const audio30PercentageStatus = audioProgress(30)
    const audio40PercentageStatus = audioProgress(40)
    const audio50PercentageStatus = audioProgress(50)
    const audio60PercentageStatus = audioProgress(60)
    const audio70PercentageStatus = audioProgress(70)
    const audioPercentageStatus = audioProgress(80)

    useEffect(() => {
      if (
        (audio10PercentageStatus ||
          audio20PercentageStatus ||
          audio30PercentageStatus ||
          audio40PercentageStatus ||
          audio50PercentageStatus ||
          audio60PercentageStatus ||
          audio70PercentageStatus ||
          audioPercentageStatus) &&
        !mediaObject?.isCompleted
      ) {
        updateStatus(audio.currentTime)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      audio10PercentageStatus,
      audio20PercentageStatus,
      audio30PercentageStatus,
      audio40PercentageStatus,
      audio50PercentageStatus,
      audio60PercentageStatus,
      audio70PercentageStatus,
      audioPercentageStatus,
    ])

    useEffect(() => {
      if (audio) {
        audio.load()

        audio.addEventListener('canplaythrough', (event) => {
          // audio.autoPlay = true
          // audio.playsInline= true
          audio.loop = false
          const promise = document.querySelector('audio').play()
          if (promise !== undefined) {
            promise
              .then((_) => {
                if (audio && !isSafari) {
                  audio.play()
                }
              })
              .catch((err) => {
                // Auto play disabled error
              })
          }
        })

        if ('mediaSession' in navigator) {
          navigator.mediaSession.setActionHandler('previoustrack', function () {
            if (!mediaObject?.isFirst) {
              playPrev()
            }
          })

          navigator.mediaSession.setActionHandler('nexttrack', function () {
            if (!mediaObject?.isLast && !mediaObject?.disabledControls) {
              playNext()
            }
          })

          navigator.mediaSession.setActionHandler('seekforward', function () {
            audio.currentTime = Math.min(audio.currentTime + 15, audio.duration)
          })

          navigator.mediaSession.setActionHandler('seekbackward', function () {
            audio.currentTime = Math.max(audio.currentTime - 15, 0)
          })
        }
      }
      return () => {
        if (audio) {
          audio.removeEventListener('canplaythrough', () => {})
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio])

    const handler = useCallback(() => {
      audio.pause()
      if (!mediaObject?.isLast && !mediaObject?.disabledControls) {
        playNext()
      }
    }, [audio, mediaObject, playNext])

    useEffect(() => {
      if (
        audio &&
        audio.currentSrc !== mediaObject?.mediaUrl &&
        audio.dataset.id === mediaObject?.id
      ) {
        audio.addEventListener('ended', handler)
      }
      return () => {
        if (audio) {
          audio.removeEventListener('ended', handler)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio, mediaObject])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const errorHandler = (e) => {
      if (!isLoading) {
        setTempTime(audio.currentTime)
        getMedia()
      }
    }

    useEffect(() => {
      if (audio) {
        audio.addEventListener('error', errorHandler)
      }
      return () => {
        if (audio) {
          audio.removeEventListener('error', errorHandler)
        }
      }
    }, [audio, errorHandler, mediaObject])

    const renderLikeIcon = () => (
      <span
        onClick={toggleIsFavorite}
        className={`icon icon-bookmark${
          mediaObject?.isFavorite ? '-gold' : ''
        } font-16 text-white cursor-pointer text-decoration-none`}
      />
    )

    const renderMobileBar = () => {
      return (
        <ModuleAudio
          audio={audio}
          isMediaLoading={isMediaLoading}
          isLoading={audio?.readyState < 3 || isLoading}
          isPlaying={!audio?.paused}
          curTime={curTime}
          duration={duration}
          playPrev={playPrev}
          playNext={playNext}
          setClickedTime={(time) => setClickedTime(time)}
          bufferSize={bufferSize}
          isPreviousDisabled={
            mediaObject?.disabledControls || mediaObject?.isFirst
          }
          isNextDisabled={mediaObject?.disabledControls || mediaObject?.isLast}
        />
      )
    }

    const getNavLinkUrl = () => {
      let url
      if (isProgramAudio) {
        url = `/module/${mediaObject?.category._id}/${mediaObject?.subCategory._id}`
      } else {
        url = `/media/category/${mediaObject?.subCategory._id}/${mediaObject?.id}`
      }

      const searchParams = new URLSearchParams()

      if (contextJourneyId) {
        searchParams.append('journeyId', contextJourneyId)
      }
      if (contextJourneyAudioId) {
        searchParams.append('sequenceId', contextJourneyAudioId)
      }
      if (journeyGroupId) {
        searchParams.append('journeyGroupId', journeyGroupId)
      }

      return `${url}?${searchParams.toString()}`
    }

    const showAudioControl = (e) => {
      setIsSMAudioControlVisible(!isSMAudioControlVisible)
      if (!isSMAudioControlVisible) {
        resetTimeout(e)
      }
    }

    const resetTimeout = (e) => {
      e.stopPropagation()
      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }
      timeout.current = setTimeout(() => {
        setIsSMAudioControlVisible(false)
      }, 6000)
    }

    const gtmVolumeMuteAction = (mediaObject, userId) => {
      TagManager.dataLayer({
        dataLayer: {
          event: TrackAudioPlayer.event.audioPlayer,
          eventProps: {
            category: TrackAudioPlayer.category.audioPlayer,
            action: 'User mute the media',
            label: 'User mute the media',
            value: `Mute icon media of category ID - ${mediaObject?.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
            userId,
          },
        },
      })
    }

    const gtmVolumeUnmuteAction = (mediaObject, userId) => {
      TagManager.dataLayer({
        dataLayer: {
          event: TrackAudioPlayer.event.audioPlayer,
          eventProps: {
            category: TrackAudioPlayer.category.audioPlayer,
            action: 'User unmute the media',
            label: 'User unmute the media',
            value: `Unmute icon media of category ID - ${mediaObject?.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
            userId,
          },
        },
      })
    }

    const gtmForward15secAction = (mediaObject, userId) => {
      TagManager.dataLayer({
        dataLayer: {
          event: TrackAudioPlayer.event.audioPlayer,
          eventProps: {
            category: TrackAudioPlayer.category.audioPlayer,
            action: 'User forward 15 sec. media',
            label: 'Forward 15 sec. media',
            value: `Forward 15 sec. media of category ID - ${mediaObject?.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page (+15 sec.)`,
            userId,
          },
        },
      })
    }

    const gtmBackward15secAction = (mediaObject, userId) => {
      TagManager.dataLayer({
        dataLayer: {
          event: TrackAudioPlayer.event.audioPlayer,
          eventProps: {
            category: TrackAudioPlayer.category.audioPlayer,
            action: 'User backward 15 sec. media',
            label: 'Backward 15 sec. media',
            value: `Backward 15 sec. media of category ID - ${mediaObject?.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page (-15 sec.)`,
            userId,
          },
        },
      })
    }

    const infoIconRef = useRef(null)
    const renderInfoTooltip = () => {
      if (!mediaObject?.description) {
        return (
          <span className='icon icon-info font-16 cursor-pointer hover:text-saffron-700' />
        )
      }
      const placement = 'top'
      return (
        <OverlayTrigger
          key={placement}
          placement={placement}
          trigger={['hover', 'focus']}
          rootClose
          container={infoIconRef.current}
          overlay={
            <Tooltip
              id={`tooltip-${placement}`}
              className='text-start mediabar-tooltip'>
              <div className='text-black s7'>{mediaObject?.description}</div>
            </Tooltip>
          }
          //show
        >
          <span className='icon icon-info font-16 cursor-pointer hover:text-saffron-700' />
        </OverlayTrigger>
      )
    }

    const largeMediaBar = () => {
      return (
        <div className='d-none d-lg-flex text-white playbar-height bg-abroad-blue playbar-top-border'>
          <Row className='w-100 px-3 transition align-items-center h-100 justify-content-between g-0'>
            <Col lg='3' className='d-flex align-items-center space-x-4'>
              <div className='d-flex align-items-center'>
                <span
                  className='icon icon-left-arrow text-white cursor-pointer me-3'
                  onClick={() => setMinimizePlayBar(true)}
                />
                <div className='position-relative border-radius-5 play-bar-image'>
                  {isMediaLoading ? (
                    <ShimmerThumbnail
                      className='shimmer-thumbnail-mediaplayer'
                      width={64}
                      height={64}
                      rounded
                    />
                  ) : (
                    <PlayBarImage
                      imageUrl={
                        isProgramAudio
                          ? mediaObject?.subCategory.imageFilename
                          : mediaObject?.imageFilename
                      }
                    />
                  )}
                  {!isMediaLoading && isProgramAudio && (
                    <div className='position-absolute bg-black bg-opacity-25 inset-0 img-link'>
                      <NavLink
                        to={getNavLinkUrl()}
                        className='d-flex h-100 w-100 justify-content-center align-items-center text-decoration-none'>
                        <span className='icon icon-left-arrow rotate-90 text-white cursor-pointer' />
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
              <div className='playbar-title'>
                {isMediaLoading ? (
                  <ShimmerTitle
                    line={2}
                    gap={10}
                    variant='secondary'
                    className='mb-0'
                  />
                ) : (
                  <>
                    {isProgramAudio ? (
                      <>
                        <NavLink
                          to={getNavLinkUrl()}
                          className='s6 d-block text-white text-truncate'>
                          {mediaObject?.title}
                        </NavLink>
                        <NavLink
                          to={getNavLinkUrl()}
                          className='s8 d-block font-gray-100 opacity-50 text-truncate'>
                          {mediaObject?.expert}
                        </NavLink>
                      </>
                    ) : (
                      <>
                        <div className='s6 d-block text-white text-truncate'>
                          {mediaObject?.title}
                        </div>
                        <div className='s8 d-block font-gray-100 opacity-50 text-truncate'>
                          {mediaObject?.expert}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col lg='6' className='pt-2'>
              <Container>
                <div className='controls d-none d-lg-block space-y-2'>
                  <Row className='g-0 justify-content-center'>
                    <Col sm='7'>
                      <div className='d-flex m-auto w-7/12 justify-content-center align-items-center'>
                        <BackwardIcon
                          fontSize='0.875rem'
                          className='me-1'
                          wrapperClassName='mr-32px'
                          onClick={() => {
                            audio.currentTime = audio.currentTime - 15
                            gtmBackward15secAction(mediaObject, userId)
                          }}
                          isDisabled={isMediaLoading}
                        />
                        <PreviousIcon
                          wrapperClassName='mr-32px'
                          isPreviousDisabled={
                            mediaObject?.disabledControls ||
                            mediaObject?.isFirst ||
                            isMediaLoading
                          }
                          fontSize='1.5rem'
                          onClick={() => {
                            if (
                              mediaObject?.disabledControls ||
                              !mediaObject?.isFirst
                            ) {
                              playPrev()
                            }
                          }}
                        />
                        <AudioControl isLoading={isLoading || isMediaLoading} />
                        <NextIcon
                          wrapperClassName='ml-32px'
                          isNextDisabled={
                            mediaObject?.disabledControls ||
                            mediaObject?.isLast ||
                            isMediaLoading
                          }
                          fontSize='1.5rem'
                          onClick={() => {
                            if (!mediaObject?.isLast) {
                              playNext()
                            }
                          }}
                        />
                        <ForwardIcon
                          wrapperClassName='ml-32px'
                          className='ms-1'
                          fontSize='0.875rem'
                          onClick={() => {
                            audio.currentTime = audio.currentTime + 15
                            gtmForward15secAction(mediaObject, userId)
                          }}
                          isDisabled={isMediaLoading}
                        />
                      </div>
                    </Col>
                  </Row>
                  <ProgressBar
                    isDisabled={isMediaLoading}
                    curTime={curTime}
                    duration={duration}
                    setClickedTime={(time) => setClickedTime(time)}
                    bufferSize={bufferSize}
                    identifier={mediaObject?.id}
                  />
                </div>
              </Container>
            </Col>
            <Col
              lg='3'
              ref={infoIconRef}
              className='d-flex justify-content-end align-items-center space-x-6'>
              <span
                className='icon icon-journal font-16 cursor-pointer text-white hover:text-saffron-700'
                onClick={() =>
                  history.push(
                    `/journal/${isProgramAudio ? 'program' : 'library'}/${
                      mediaObject?.subCategory._id
                    }/${mediaObject?.id}`,
                  )
                }
              />
              {renderLikeIcon()}
              {renderInfoTooltip()}
              <div className='vol-bar-root'>
                {volumeLevel === 0 ? (
                  <VolumeMuteIcon
                    onClick={() => {
                      setVolumeLevel(0.3)
                      gtmVolumeUnmuteAction(mediaObject, userId)
                    }}
                  />
                ) : (
                  <VolumeIcon
                    onClick={() => {
                      setVolumeLevel(0)
                      gtmVolumeMuteAction(mediaObject, userId)
                    }}
                  />
                )}
                <VolumeBar
                  volumeCurTime={volumeLevel}
                  setVolumeClickedTime={(time) => {
                    setVolumeLevel(time)
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      )
    }

    const minimizedMediaBar = () => {
      return (
        <div className='d-none d-lg-flex row g-0'>
          <div className='col-2 g-0 bg-abroad-blue playbar-top-border'>
            <Row className='w-100 transition align-items-center h-100 justify-content-between g-0'>
              <Col lg='12'>
                <div className='d-flex align-items-center playbar-height'>
                  <div className='position-relative m-3 mx-4'>
                    <div className='border-radius-5 play-bar-image'>
                      {isMediaLoading ? (
                        <ShimmerThumbnail
                          className='shimmer-thumbnail-mediaplayer'
                          width={64}
                          height={64}
                          rounded
                        />
                      ) : (
                        <PlayBarImage
                          imageUrl={
                            isProgramAudio
                              ? mediaObject?.subCategory.imageFilename
                              : mediaObject?.imageFilename
                          }
                        />
                      )}
                      {!isMediaLoading && isProgramAudio && (
                        <div className='position-absolute bg-black bg-opacity-25 inset-0 img-link'>
                          <NavLink
                            to={getNavLinkUrl()}
                            className='d-flex h-100 w-100 justify-content-center align-items-center text-decoration-none'>
                            <span className='icon icon-left-arrow rotate-90 text-white cursor-pointer' />
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex me-3 align-items-center w-100 justify-content-between'>
                    {renderLikeIcon()}
                    <AudioControl isLoading={isLoading || isMediaLoading} />
                    <span
                      className='icon icon-right-arrow text-white cursor-pointer'
                      onClick={() => setMinimizePlayBar(false)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const _renderPlaybarComponent = () => {
      return (
        <>
          <div>
            <CSSTransition
              unmountOnExit
              classNames='play-bar'
              in={showPlayBar}
              timeout={500}>
              <div
                className={classNames('position-fixed left-0 right-0 z1048')}>
                {minimizePlayBar ? minimizedMediaBar() : largeMediaBar()}
                <div
                  onClick={showAudioControl}
                  className='d-flex flex-column d-lg-none bg-abroad-blue playbar-top-border'>
                  <div className='d-flex text-white'>
                    <div className='py-2 px-3 w-100 h-100 d-flex justify-content-between align-items-center space-x-2'>
                      <div className='d-flex align-items-center mini-playbar'>
                        <div className='border-radius-5 play-bar-image'>
                          {isMediaLoading ? (
                            <ShimmerThumbnail
                              className='shimmer-thumbnail-mediaplayer'
                              rounded
                              width={48}
                              height={48}
                            />
                          ) : (
                            <>
                              {isProgramAudio ? (
                                <NavLink
                                  to={getNavLinkUrl()}
                                  onClick={(e) => e.stopPropagation()}>
                                  <PlayBarImage
                                    imageUrl={
                                      isProgramAudio
                                        ? mediaObject?.subCategory.imageFilename
                                        : mediaObject?.imageFilename
                                    }
                                    width={48}
                                    height={48}
                                  />
                                </NavLink>
                              ) : (
                                <PlayBarImage
                                  imageUrl={
                                    isProgramAudio
                                      ? mediaObject?.subCategory.imageFilename
                                      : mediaObject?.imageFilename
                                  }
                                  width={48}
                                  height={48}
                                />
                              )}
                            </>
                          )}
                        </div>
                        <div className='ms-3 title-link'>
                          {isMediaLoading ? (
                            <div className='title-shimmer'>
                              <ShimmerTitle
                                line={2}
                                gap={10}
                                variant='secondary'
                                className='mb-0'
                              />
                            </div>
                          ) : (
                            <>
                              <div className='s8 text-truncate'>
                                {mediaObject?.title}
                              </div>
                              <div className='s-captions'>
                                {mediaObject?.expert}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div>
                        <TransitionGroup>
                          <CSSTransition
                            key={isSMAudioControlVisible}
                            timeout={300}
                            classNames='fade-in'
                            unmountOnExit>
                            <div
                              onClick={(e) => {
                                e.stopPropagation()
                                clearTimeout(timeout.current)
                                timeout.current = null
                              }}>
                              {isSMAudioControlVisible ? (
                                <PlaybarJournal
                                  isDisabled={isMediaLoading}
                                  iconProps={{ height: 32, width: 32 }}
                                  onJournalDataChange={() => {
                                    timeout.current = setTimeout(() => {
                                      setIsSMAudioControlVisible(false)
                                    }, 6000)
                                  }}
                                />
                              ) : (
                                <AudioControl
                                  isLoading={
                                    audio?.readyState < 3 ||
                                    isLoading ||
                                    isMediaLoading
                                  }
                                />
                              )}
                            </div>
                          </CSSTransition>
                        </TransitionGroup>
                      </div>
                    </div>
                  </div>
                  <CSSTransition
                    unmountOnExit
                    classNames='sm-audio-control'
                    in={isSMAudioControlVisible}
                    timeout={500}>
                    <div onClick={resetTimeout}>{renderMobileBar()}</div>
                  </CSSTransition>
                </div>
              </div>
            </CSSTransition>
          </div>
        </>
      )
    }

    return (
      <>
        <AudioElement
          mediaId={mediaObject?.id}
          mediaUrl={mediaObject?.mediaUrl}
        />
        {_renderPlaybarComponent()}
      </>
    )
  },
)

export default MediaControler(MediaControlBar)
