import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Imgix from 'react-imgix'

import { useErrorService, FeatherLoader } from '@abroad/components'

import ModuleMiniDetails from './ModuleMiniDetails'
// import ModuleActions from './ModuleActions'
import API from '../../utils/API'

const ProgramsList = () => {
  const [programs, setPrograms] = useState([])
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()

  useEffect(() => {
    const getPrograms = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.programs.getMainPrograms()
        setPrograms(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getPrograms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div className='text-center'>
        <FeatherLoader />
      </div>
    )
  }

  return (
    <div className='container-fluid g-0'>
      {programs.map((program) => {
        return (
          <>
            <div
              className='row g-0 program-wrp cursor-pointer p-32px custom-gray-bg-100 mt-32px'
              onClick={() => history.push(`/program/${program._id}`)}>
              <div className='col col-lg-4 col-12'>
                <div className='position-relative overflow-hidden'>
                  <Imgix
                    className='border-radius-5 img-cover w-100 program-img-zoom'
                    imgixParams={{
                      fit: 'crop',
                      crop: 'edges',
                      ar: '4:5',
                    }}
                    src={`${process.env.REACT_APP_IMG_SOURCE}/${program?.imageFilename}`}
                    alt='image'
                  />
                  <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10'></div>
                </div>
              </div>
              <div className='col col-lg-8 col-12 pl-lg-32px pt-lg-0 pt-3 d-flex flex-column justify-content-center'>
                {/* <ModuleActions
                  // todo ----
                  // toggleIsFavorite={toggleIsFavorite}
                  isFavorite={false}
                  showPlayIcon={false}
                /> */}
                <ModuleMiniDetails
                  className='mb-lg-12px'
                  details={{
                    label1: `${program?.expert}`,
                    label2: `${program?.subcategoryCount} Modules`,
                    label3: `${program?.mediaCount} Audios`,
                  }}
                />
                <div className='s2 my-12px'>
                  <span className='fst-normal'>{program.title}</span>
                  {': '}
                  <span className='fst-italic'>{program.subTitle}</span>
                </div>
                <div className='s7'>{program.description}</div>
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}

export default ProgramsList
