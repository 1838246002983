// guide: this file contains bottom playbar UI, play prev/next come from MediaControler.js
import React, { useContext, useState, useRef } from 'react'
import { LayoutContext } from '../../utils/contexts'
import { checkIsProgramAudio } from '../../utils/utility'
import JournalModal from './JournalModal'

// todo: remove - deprecated
const PlaybarJournal = React.memo(
  ({ isDisabled, iconProps, onJournalDataChange }) => {
    const { mediaObject } = useContext(LayoutContext)
    const [showJournalModal, setShowJournalModal] = useState(false)
    const oldMediaObject = useRef(null)
    const isProgramAudio =
      checkIsProgramAudio(oldMediaObject.current?.category?.key) ||
      checkIsProgramAudio(oldMediaObject.current?.category)

    return (
      <>
        <span
          className='icon icon-journal font-16 cursor-pointer text-white hover:text-saffron-700'
          onClick={() => {
            if (!isDisabled) {
              setShowJournalModal(true)
              oldMediaObject.current = mediaObject
            }
          }}
          {...iconProps}
        />
        <JournalModal
          show={showJournalModal}
          onHide={() => {
            setShowJournalModal(false)
          }}
          journalData={oldMediaObject.current?.journal}
          mediaId={oldMediaObject.current?.id}
          onJournalDataChange={(data, id) => {
            if (oldMediaObject.current.id === mediaObject.id) {
              mediaObject.journal = data
            }
            onJournalDataChange(data, id)
          }}
          closingQuestion={
            oldMediaObject.current?.closingQuestion ||
            oldMediaObject.current?.question
          }
          isMediaLibraryAudio={!isProgramAudio}
        />
      </>
    )
  },
)

PlaybarJournal.defaultProps = {
  onJournalDataChange: () => {},
}

export default PlaybarJournal
