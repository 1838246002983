import React from 'react'
import { Col, Row } from 'react-bootstrap'
import RequestedUserListingModal from './RequestedUserListingModal'

const DirectReports = ({
  totalRequestsCompletedCount,
  totalRequestsCount,
  workingRelationshipPercentage,
  personalRelationshipPercentage,
  requestedUsers,
}) => {
  const renderResponseConnection = (value, label, isShowTooltip) => {
    return (
      <Col
        lg={4}
        md={4}
        sm={12}
        className='mb-2 d-flex flex-column text-center'>
        <p className='mb-4 text-saffron-300 s2'>{value}</p>
        <div className='d-flex justify-content-center align-items-center'>
          <RequestedUserListingModal
            className={isShowTooltip ? '' : 'invisible'}
            requestedUsers={requestedUsers}
            index={value}
          />
          <span className='s10a align-self-center'>{label}</span>
        </div>
      </Col>
    )
  }

  return (
    <div className='direct-reports mb-52px'>
      <p className='text-center mb-35px s5'>
        Your personal and professional connection scores with your direct
        reports. This measures
        <br className='d-lg-block d-none' />
        how close your colleagues feel to you, which impacts engagement and your
        results.
      </p>
      <Row className='d-flex justify-content-between'>
        {/* renderResponseConnection(value, label, isShowTooltip) */}
        {renderResponseConnection(
          `${totalRequestsCompletedCount}/${totalRequestsCount}`,
          'RESPONSES COMPLETE',
          true,
        )}
        {renderResponseConnection(
          `${workingRelationshipPercentage}%`,
          'PROFESSIONAL CONNECTION',
        )}
        {renderResponseConnection(
          `${personalRelationshipPercentage}%`,
          'PERSONAL CONNECTION',
        )}
      </Row>
    </div>
  )
}

export default DirectReports
