import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import { ShimmerTable, ShimmerTitle } from 'react-shimmer-effects'
import { useErrorService, useUserService } from '@abroad/components'
import CompareAssessmentEvent from '../../constants/events'
import { getAssessmentCurrentData } from '../../utils/date'
import API from '../../utils/API'
import { surveyType } from '../../utils/constants'

const type = 'personal'

const SurveyPreviousResults = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [previousResults, setPreviousResults] = useState([])
  const [ids, setIds] = useState([])
  const Error = useErrorService()
  const { id } = useParams()
  const { user } = useUserService()
  const history = useHistory()

  const onChange = (id) => {
    if (ids.includes(id)) {
      setIds((prev) => prev.filter((i) => i !== id))
    } else {
      setIds((prev) => prev.concat(id))
    }
  }

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await API.survey.getSurveys(type, surveyType.AWARENESS) // (surveyType, filterBy))
        if (data && data?.surveys?.length > 0) {
          const sortedSurveys = data?.surveys?.sort((a, b) => {
            return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1
          })
          setPreviousResults(sortedSurveys)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (id) getSurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const gtmCompareAssessmentAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: CompareAssessmentEvent.event.compareAssessment,
        eventProps: {
          category: CompareAssessmentEvent.category.compareAssessmentCategory,
          action: 'User compare two assessment',
          label: 'compare two assessment',
          value: `User compare two assessment in survey page (Personal Assessment)`,
          userId: user?.id,
        },
      },
    })
  }

  if (isLoading) {
    return (
      <div className='previous-result'>
        <div className='w-25 mb-18px'>
          <ShimmerTitle line={1} />
        </div>
        <ShimmerTable row={2} col={3} />
      </div>
    )
  }

  return (
    <div className='previous-result'>
      <div className='d-flex justify-content-between mb-18px'>
        <h3 className='previous-result-title mb-0'>Previous Results</h3>
        <span
          className={classNames('s11 align-self-center', {
            'pe-none text-saffron-700-5': ids.length !== 2,
            'cursor-pointer text-saffron-700': ids.length === 2,
          })}
          onClick={() => {
            const params = ids.reduce((string, value, index) => {
              if (index === 0) {
                return `survey=${value}`
              } else {
                return string.concat(`&survey=${value}`)
              }
            }, '')
            gtmCompareAssessmentAction()
            history.push(`/survey/comparison-report?${params}`)
          }}>
          Compare
        </span>
      </div>
      {previousResults?.map((survey, index) => (
        <div
          key={index}
          className={classNames('result-link d-flex justify-content-between', {
            'last-result-link': index + 1 === previousResults?.length,
          })}>
          {survey?._id === id ? (
            <span>
              {/* getAssessmentCurrentData(dateValue, format)  */}
              {getAssessmentCurrentData(survey?.createdAt, 'MMMM Do YYYY')}{' '}
              (Current)
            </span>
          ) : (
            <Link to={`/survey/${survey?._id}`} className='result-active-link'>
              {getAssessmentCurrentData(survey?.createdAt, 'MMMM Do YYYY')}
            </Link>
          )}
          <Form.Check
            id={`checkbox-${survey.id}`}
            onChange={() => onChange(survey._id)}
            type='checkbox'
          />
        </div>
      ))}
    </div>
  )
}

export default SurveyPreviousResults
