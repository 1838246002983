import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, useErrorService } from '@abroad/components'
import API from '../../utils/API'

const RemoveCard = ({ cardId, removeCardHandler }) => {
  const [showModal, setShowModal] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const Error = useErrorService()
  const handleClose = () => setShowModal(false)

  const deleteCardHandler = async () => {
    try {
      setIsRemoving(true)
      const { status } = await API.payment.detachCard({
        paymentMethodId: cardId,
      })
      if (status) {
        removeCardHandler(cardId)
      }
    } catch (e) {
      handleClose()
      setIsRemoving(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='payment-box'>
        <Modal.Header className='border-0 pb-0 px-4'>
          <Modal.Title className='text-uppercase s7'>
            Are you sure, you want to permanently remove this card?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end px-4 space-x-3'>
          <Button
            variant='secondary-light'
            onClick={handleClose}
            className='px-3 secondary-popup-button'
            size='sm'>
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            disabled={isRemoving}
            isLoading={isRemoving}
            onClick={deleteCardHandler}
            className='px-3 text-uppercase border-radius-normal'>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        // eslint-disable-next-line no-script-url
        href='javascript:void(0)'
        onClick={(e) => {
          e.preventDefault()
          setShowModal(true)
        }}
        disabled={isRemoving}
        className={isRemoving ? 'pointer-event-none' : ''}>
        {isRemoving ? 'Removing...' : 'Remove a card'}
      </a>
    </>
  )
}

export default RemoveCard
