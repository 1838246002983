import React from 'react'
import classNames from 'classnames'
import { plans } from '@abroad/components'
import CoachingPlanBtn from './CoachingPlanBtn'
import GrowthPlanBtn from './GrowthPlanBtn'

const LearnAboutCoaching = ({
  wrapperClasses = '',
  title,
  titleFn = () => {},
  titleClasses = '',
  detailsText,
  detailsTextFn = () => {},
  detailsTextClasses = '',
  buttonText = 'LEARN MORE ABOUT COACHING',
  buttonClasses = '',
  plan,
  isDiscardWidth = false,
}) => {
  return (
    <div
      className={classNames(
        'learning-coaching border-radius-5',
        wrapperClasses,
      )}>
      <div className={isDiscardWidth ? '' : 'w-36rem'}>
        {title ? (
          <h3 className={classNames('s2 text-black mb-0', titleClasses)}>
            {title}
          </h3>
        ) : (
          titleFn()
        )}
        {detailsText ? (
          <h5
            className={classNames(
              'mt-12px s6a mb-0 text-black',
              detailsTextClasses,
            )}>
            {detailsText}
          </h5>
        ) : (
          detailsTextFn()
        )}
      </div>
      {plan === plans.coaching.code ? (
        <CoachingPlanBtn
          buttonText={buttonText}
          buttonClasses={classNames(
            'btn s8 text-uppercase onbording-action-btn py-12px',
            buttonClasses,
          )}
        />
      ) : (
        <GrowthPlanBtn
          buttonText={buttonText}
          buttonClasses={classNames(
            'btn s8 text-uppercase onbording-action-btn py-12px',
            buttonClasses,
          )}
        />
      )}
    </div>
  )
}

export default LearnAboutCoaching
