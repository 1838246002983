import React, { useState, useContext } from 'react'
import TagManager from 'react-gtm-module'
import { useErrorService, useUserService } from '@abroad/components'
import API from '../../utils/API'
import ModuleMediaEvent from '../../constants/events'
import { LayoutContext } from '../../utils/contexts'

const FavBTMedia = ({ categoryId, mediaId, isFavoriteAudio, programName }) => {
  const { setMediaObject, mediaObject } = useContext(LayoutContext)
  const [isFavorite, setIsFavorite] = useState(isFavoriteAudio)
  const Error = useErrorService()
  const { user } = useUserService()
  const userId = user?.id

  const onClick = async () => {
    const favorite = isFavorite
    setIsFavorite(!isFavorite)
    try {
      await API.programs.favoriteModuleAudio(categoryId, mediaId, {
        isFavorite: !favorite,
      })
      if (mediaObject?._id === mediaId) {
        setMediaObject({
          ...mediaObject,
          isFavorite: !favorite,
        })
      }
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: ModuleMediaEvent.event.moduleAudio,
          eventProps: {
            category: ModuleMediaEvent.category.moduleAudioCategory,
            action: 'User like/dislike module audio',
            label: 'Module audio like/dislike',
            value: `User ${
              !favorite ? 'like' : 'dislike'
            } module audio from ${programName} Page, categoryId is ${categoryId}, mediaId is ${mediaId}.`,
            userId,
          },
        },
      })
    } catch (err) {
      setIsFavorite(favorite)
      Error.showError(err)
    }
  }
  return (
    <span
      onClick={onClick}
      className={`icon icon-bookmark${
        isFavorite ? '-gold' : ''
      } text-black cursor-pointer align-self-center me-3`}
    />
  )
}

export default FavBTMedia
