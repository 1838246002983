import React, { useState, useEffect } from 'react'
import {
  Col,
  Container,
  Form,
  Button as RBButton,
  Row,
  Spinner,
} from 'react-bootstrap'
import OtpInput from 'react-otp-input'
import {
  useErrorService,
  Button,
  useNotificationService,
} from '@abroad/components'
import { useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { string, object } from 'yup'
import { Formik } from 'formik'
import {
  EmailField,
  OnboardTitle,
  OnboardSubTitle,
  ReturnBtn,
  AbroadGoldenIcon,
} from '../components/onboarding'
import API from '../utils/API'

const ForgotPassword = () => {
  let { state } = useLocation()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailSend, setIsEmailSend] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const Notification = useNotificationService()

  const validationSchema = object().shape({
    email: string()
      .email('Please enter a valid Email address')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      .max(255)
      .required('Required'),
  })

  const forgotPassword = async (email) => {
    try {
      setIsLoading(true)
      const { data } = await API.onboarding.forgotPassword({ email })
      Notification.showNotification(data.message, 'success')
      setIsLoading(false)
      setIsEmailSend(true)
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  const sendForgotPasswordMail = (values) => {
    setUserEmail(values.email)
    forgotPassword(values.email)
  }

  return (
    <>
      <Helmet>
        <title>{'Forgot Password | Abroad'}</title>
        <meta name='title' content={'Forgot Password | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/forgot-password`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/forgot-password`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/forgot-password`}></meta>
      </Helmet>
      <main>
        <Container fluid className='px-0'>
          <Row className='vh-100 row g-0'>
            <Col className='onboard bg-abroad-blue'>
              <div className='h-100 d-flex flex-column align-items-center'>
                <AbroadGoldenIcon />
                <section className='onboard-wrapper mt-32px h-75'>
                  <OnboardTitle className='mb-2'>
                    {!isEmailSend ? (
                      <>Forgot Your Password?</>
                    ) : (
                      <>Reset Password</>
                    )}
                  </OnboardTitle>
                  <OnboardSubTitle className='mb-40px opacity-75'>
                    {!isEmailSend ? (
                      <>
                        We will send you instructions on how <br /> to reset
                        your password by email.
                      </>
                    ) : (
                      <>
                        Please enter the two factor authentication PIN that was
                        sent to your email.
                      </>
                    )}
                  </OnboardSubTitle>

                  <Formik
                    initialValues={{
                      email: state?.email ? state?.email : '',
                    }}
                    onSubmit={sendForgotPasswordMail}
                    validationSchema={validationSchema}>
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        {!isEmailSend && (
                          <>
                            <EmailField />
                            <Button
                              variant='saffron'
                              className='btn w-100 s9 onbording-action-btn'
                              disabled={isLoading}
                              size='lg'
                              isLoading={isLoading}
                              type='submit'>
                              Submit
                            </Button>
                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                  {isEmailSend && (
                    <OTPScreen
                      forgotPassword={() => forgotPassword(userEmail)}
                      isLoading={isLoading}
                      userEmail={userEmail}
                    />
                  )}
                </section>
                <ReturnBtn to='/' />
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

const OTPScreen = ({ forgotPassword, isLoading, userEmail }) => {
  const [otp, setOtp] = useState('')
  const handleChange = (otp) => setOtp(otp)
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [hasErrored, setHasErrored] = useState(false)
  const Notification = useNotificationService()
  const Error = useErrorService()
  const history = useHistory()

  useEffect(() => {
    if (otp) {
      setHasErrored(false)
    }
  }, [otp])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (otp.length !== 6) {
      setHasErrored(true)
      Notification.showNotification('Enter correct OTP!', 'danger')
      return
    }
    setIsBtnLoading(true)
    try {
      const { data } = await API.onboarding.verifyForgotPasswordOtp({
        email: userEmail,
        otp,
      })
      if (data?.status) {
        setIsBtnLoading(false)
        history.push('/password-reset', { token: data.token, email: userEmail })
      } else {
        setHasErrored(true)
        Notification.showNotification('Incorrect OTP!', 'danger')
        setIsBtnLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsBtnLoading(false)
    }
  }

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className='w-100 align-items-center justify-content-center'>
        <OtpInput
          hasErrored={hasErrored}
          errorStyle='two-fa-otp-error'
          value={otp}
          onChange={handleChange}
          numInputs={6}
          isInputNum={true}
          containerStyle='two-fa-otp-input justify-content-between'
          inputStyle={{ color: 'white' }}
        />
      </div>
      <div className='d-flex flex-column onbording-action-btn'>
        <Button
          variant='saffron'
          className='btn w-100 s9 mb-4'
          disabled={isLoading || isBtnLoading}
          type='submit'>
          {isBtnLoading ? (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            <>Submit</>
          )}
        </Button>
        <RBButton
          disabled={isLoading || isBtnLoading}
          onClick={() => forgotPassword()}
          variant='outline-dark'
          className='btn w-100 s9'>
          {isLoading ? 'Resending...' : 'Resend OTP'}
        </RBButton>
      </div>
    </form>
  )
}

export default ForgotPassword
