import React, { useState, useEffect, useContext } from 'react'
import TagManager from 'react-gtm-module'
import { useUserService } from '@abroad/components'
import { MediaPlayPauseIcon } from '../assetsComponents'
import { useLocation } from 'react-router-dom'
import TrackAudioPlayer from '../../constants/events'
import { LayoutContext } from '../../utils/contexts'

const AudioControl = ({ isLoading, moduleInfo, identifier }) => {
  const { mediaObject, audio, setMediaObject } = useContext(LayoutContext)
  let paused = audio?.paused
  const [isAudioPause, setIsAudioPause] = useState(
    moduleInfo?.id !== mediaObject?.id ? true : paused,
  )
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const source = query.get('source')
  const { user } = useUserService()
  const userId = user?.id

  useEffect(() => {
    setIsAudioPause(paused)
  }, [paused])

  useEffect(() => {
    if (identifier === 'media-page') {
      if (moduleInfo?.id === mediaObject?.id) {
        setIsAudioPause(paused)
      } else {
        setIsAudioPause(true)
      }
    }
  }, [mediaObject, moduleInfo, identifier, paused])

  const gtmPlayIconAction = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: TrackAudioPlayer.event.audioPlayer,
        eventProps: {
          category: TrackAudioPlayer.category.audioPlayer,
          action: 'User click on play icon in media',
          label: 'play icon in media',
          value: `play icon media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
          userId,
        },
      },
    })
  }

  const gtmPauseIconAction = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: TrackAudioPlayer.event.audioPlayer,
        eventProps: {
          category: TrackAudioPlayer.category.audioPlayer,
          action: 'User click on pause icon in media',
          label: 'pause icon in media',
          value: `pause icon media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
          userId,
        },
      },
    })
  }

  return (
    <>
      <MediaPlayPauseIcon
        showPlay={isAudioPause}
        isLoading={audio?.readyState < 3 || isLoading}
        onClick={() => {
          if (audio) {
            if (isAudioPause) {
              gtmPlayIconAction()
              if (
                identifier === 'media-page' &&
                moduleInfo?.id !== mediaObject?.id
              ) {
                if (source === 'session') {
                  setMediaObject({
                    ...moduleInfo,
                    disabledControls: true,
                    source: 'srq',
                  })
                } else {
                  setMediaObject(moduleInfo)
                }
              } else {
                audio.play()
                setIsAudioPause(false)
              }
            } else {
              gtmPauseIconAction()
              audio.pause()
              setIsAudioPause(true)
            }
          }
        }}
      />
    </>
  )
}

export default AudioControl
