import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { useErrorService } from '@abroad/components'
import CardSelectionCheckbox from './CardSelectionCheckbox'
import API from '../../utils/API'

const CreditCardsListing = ({
  changeStepHandler,
  selectedCardId,
  setSelectedCardId,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [cards, setCards] = useState([])
  const Error = useErrorService()

  useEffect(() => {
    const getUserCardListing = async () => {
      setIsLoading(true)
      try {
        const { data: response } = await API.payment.getUserCardListing()
        if (response.data && response.data?.length > 0) {
          setCards(response.data)
          if (!selectedCardId) {
            setSelectedCardId(response.data[0]?.id)
          }
          setIsLoading(false)
        } else {
          changeStepHandler()
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        changeStepHandler()
        Error.showError(e)
      }
    }
    getUserCardListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <>
        <div className='user-payment m-0 mw-100'>
          <Row>
            {[1, 2, 3].map((number, index) => (
              <Col lg={4} md={6} sm={12} key={index}>
                <ShimmerThumbnail
                  rounded
                  className='mb-4 credit-card-shimmer'
                />
              </Col>
            ))}
          </Row>
        </div>
      </>
    )
  }

  return (
    <div className='user-payment m-0 mw-100'>
      <div className='d-flex justify-content-between mb-3'>
        <h3 className='s3 mb-0'>Select Any Card</h3>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          // eslint-disable-next-line no-script-url
          href='javascript:void(0)'
          onClick={(e) => {
            e.preventDefault()
            changeStepHandler()
          }}
          className='text-dark-900 add-card-link'>
          + Add a new card
        </a>
      </div>
      <Row>
        {cards.map(({ card, id }, index) => (
          <Col lg={4} md={6} sm={12} key={index}>
            <div className='d-flex justify-context-between mb-4'>
              <CardSelectionCheckbox
                selectedId={selectedCardId}
                setSelectedId={setSelectedCardId}
                checkboxId={id}
                disabled={isLoading}
              />
              <div className='w-100'>
                <Card>
                  <Card.Header>
                    <h3 className='s3'>{card?.brand}</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text className='mb-2'>{`**** **** **** ${card?.last4}`}</Card.Text>
                    <Card.Text className='mb-0'>
                      {card?.exp_month}/{card?.exp_year}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default CreditCardsListing
