import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Col, Form as RBForm, Row } from 'react-bootstrap'
import { ShimmerButton, ShimmerTitle } from 'react-shimmer-effects'
import { string, number, object } from 'yup'
import { Button } from '@abroad/components'
import { InputField } from '../form'

const validationSchema = object().shape({
  line1: string().notRequired(),
  line2: string().notRequired(),
  state: string().notRequired(),
  country: string().notRequired(),
  postal_code: number().integer().notRequired(),
})

export const defaultAddress = {
  line1: '',
  line2: '',
  state: '',
  country: '',
  postal_code: '',
}

const BillingAddress = ({ onSuccess, isLoading, addressObject }) => {
  const [billingAddress, setBillingAddress] = useState(defaultAddress)
  const [intialStateAddressObj, setIntialStateAddressObj] =
    useState(defaultAddress)

  const renderShimmerTitle = () => {
    return (
      <ShimmerTitle
        line={1}
        variant='primary'
        className='input-shimmer mb-2-rem'
      />
    )
  }

  useEffect(() => {
    if (addressObject) {
      setBillingAddress((prev) => ({
        ...prev,
        ...addressObject,
      }))
      setIntialStateAddressObj((prev) => ({
        ...prev,
        ...addressObject,
      }))
    }
  }, [addressObject])

  if (isLoading) {
    return (
      <div className='text-center border-0'>
        {renderShimmerTitle()}
        {renderShimmerTitle()}
        {renderShimmerTitle()}
        {renderShimmerTitle()}
        <ShimmerButton side='md' />
      </div>
    )
  }

  const checkAddressChangeHandler = (values) => {
    const initialObjectKeys = Object.keys(intialStateAddressObj).sort()
    const addressObjectKeys = Object.keys(values).sort()
    const areEqual = initialObjectKeys.every((key, index) => {
      const objValue1 = intialStateAddressObj[key]
      const objValue2 = values[addressObjectKeys[index]]
      return objValue1 === objValue2
    })
    return areEqual ? false : true
  }

  const onSubmit = async (values) => {
    const isAddressObjChanged = checkAddressChangeHandler(values)
    if (isAddressObjChanged) {
      onSuccess(values, isAddressObjChanged)
    } else {
      onSuccess(null, isAddressObjChanged) // addressObj = null
    }
  }

  return (
    <>
      <Formik
        initialValues={billingAddress}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}>
        {({ handleSubmit, values, handleBlur }) => (
          <>
            <RBForm
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit(e)
              }}
              as={Form}
              className='user-payment mw-100 m-0'>
              <div className='form-group credit-card-wrp mb-2-rem'>
                <label>Address Line 1</label>
                <InputField name='line1' type='text' size='md' />
              </div>
              <div className='form-group credit-card-wrp mb-2-rem'>
                <label>Address Line 2</label>
                <InputField name='line2' type='text' size='md' />
              </div>
              <div className='form-group credit-card-wrp mb-2-rem'>
                <label>Country</label>
                <InputField name='country' type='text' size='md' />
              </div>
              <Row className='justify-content-between mb-2-rem'>
                <Col className='col-6'>
                  <>
                    <label>State</label>
                    <InputField name='state' type='text' size='md' />
                  </>
                </Col>
                <Col className='col-5'>
                  <>
                    <label>Pincode</label>
                    <InputField name='postal_code' type='number' size='md' />
                  </>
                </Col>
              </Row>
              <div className='text-left mt-3'>
                <Button
                  variant='saffron'
                  type='submit'
                  size='sm'
                  className='text-uppercase border-radius-normal'>
                  Next
                </Button>
              </div>
            </RBForm>
          </>
        )}
      </Formik>
    </>
  )
}

export default BillingAddress
