import React from 'react'
import classNames from 'classnames'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'
import { numberToWords } from 'number-to-words'

const IntentionCard = ({ intention, index }) => {
  return (
    <Link
      to={{
        pathname: `/coaching/intentions/${intention?._id}`,
        state: { isLocal: intention?.isLocal },
      }}
      className='text-decoration-none'>
      <Card className='custom-gray-bg-100 border-0 border-radius-5'>
        <Card.Body className='px-4 py-3 space-y-3'>
          <Card.Title
            className={classNames('s10a d-flex justify-content-between', {
              'font-saffron-700': !intention?.isLocal,
              'font-black': intention?.isLocal,
            })}>
            {`INTENTION ${numberToWords.toWords(index)}`}
            <span
              className={classNames('icon icon-right-arrow s10a', {
                'font-saffron-700': !intention?.isLocal,
                'font-black': intention?.isLocal,
              })}></span>
          </Card.Title>
          <Card.Text className='s6 font-black'>{intention?.title}</Card.Text>
        </Card.Body>
      </Card>
    </Link>
  )
}

export default IntentionCard
