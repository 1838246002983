export default {
  '5fddc237a483be3860dea6f2': {
    'MODULE ONE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.2,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.23,
      },
    },
    'MODULE TWO': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.7,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.7,
      },
    },
    'MODULE THREE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.45,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.45,
      },
    },
    'MODULE FOUR': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.5,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.5,
      },
    },
    'MODULE FIVE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.35,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.35,
      },
    },
    'MODULE SIX': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.35,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.35,
      },
    },
    'MODULE SEVEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.58,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.58,
      },
    },
    'MODULE EIGHT': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.5,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.5,
      },
    },
    'MODULE NINE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.36,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.36,
      },
    },
    'MODULE TEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.3,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.3,
      },
    },
    'MODULE ELEVEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.53,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.53,
      },
    },
    'MODULE TWELVE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.32,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.32,
      },
    },
    'MODULE THIRTEEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.455,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.455,
      },
    },
    'MODULE FOURTEEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.32,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.32,
      },
    },
    'MODULE FIFTEEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.33,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.33,
      },
    },
    'MODULE SIXTEEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.48,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.48,
      },
    },
    'MODULE SEVENTEEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.53,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.53,
      },
    },
    'MODULE EIGHTEEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.27,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.27,
      },
    },
    'MODULE NINETEEN': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.32,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.32,
      },
    },
    'MODULE TWENTY': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.375,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.375,
      },
    },
  },
  '639c067da4d9940fd87f216d': {
    'MODULE ONE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.315,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.315,
      },
    },
    'MODULE TWO': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.41,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.41,
      },
    },
    'MODULE THREE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.42,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.42,
      },
    },
    'MODULE FOUR': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.41,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.41,
      },
    },
    'MODULE FIVE': {
      LGScreen: {
        'fp-x': 0,
        'fp-y': 0.36,
      },
      MDScreen: {
        'fp-x': 0,
        'fp-y': 0.36,
      },
    },
  },
}
