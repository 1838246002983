import React from 'react'
import classNames from 'classnames'
import { PlayIcon } from '../assetsComponents'

const FavoritePlayIcon = ({
  favoriteAudios,
  isLoading,
  getMedia,
  className = '',
}) => {
  return (
    <>
      {favoriteAudios?.length > 0 && !isLoading && (
        <PlayIcon
          wrapperClasses={classNames(
            'd-flex justify-content-end',
            {
              'pw-none': isLoading,
            },
            className,
          )}
          onClick={() => {
            // play first audio
            getMedia(
              favoriteAudios[0]?._id,
              favoriteAudios[0]?.subCategoryId?._id,
              favoriteAudios[0]?.isProgram,
            )
          }}
          isGoldIcon={true}
          iconClasses='font-48'
        />
      )}
    </>
  )
}

export default FavoritePlayIcon
