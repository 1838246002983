import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useUserService, plans, ModalProvider } from '@abroad/components'
import {
  FreePlanHome,
  GrowthPlanHome,
  CoachingPlanHome,
  FeedbackSubmittedModal,
  CategoryModal,
} from '../components'
import AuthService from '../utils/AuthService'
import { groupId, localStorageItemName, uniqueId } from '../utils/constants'
import API from '../utils/API'
import PrivateRoute from '../routes/PrivateRoute'
import {
  PageHeader,
  UpgradePlanModal,
  UpgradePlanModalProvider,
} from '../components/common'
import { CustomHomeLASurvey } from '../components/Home'

const Home = () => {
  const [showModal, setShowModal] = useState(false)
  const isInIFrame = window.self !== window.top
  const userData = AuthService.getUserData()
  const { search, pathname } = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(search)
  const querySource = query.get('source')
  const surveyRequestId = localStorage.getItem(localStorageItemName)
  const groupRequestId = localStorage.getItem(groupId)
  const uniqueRequestId = localStorage.getItem(uniqueId)
  const { user } = useUserService()
  const location = useLocation()

  useEffect(() => {
    AuthService.setUserData(userData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getEmailBySurveyRequestId = async () => {
      try {
        const { data } = await API.survey.getEmailBySurveyRequestId(
          surveyRequestId,
        )
        if (data && data.receiverEmail === userData?.email) {
          const { data } = await API.survey.getTypeFormLink(surveyRequestId)
          window.open(data.link, '_self')
        }
        localStorage.removeItem(localStorageItemName)
      } catch (e) {}
    }

    const getEmailForLeaderRequest = async () => {
      try {
        const { data } = await API.survey.getEmailForLeaderRequest(
          groupRequestId,
          uniqueRequestId,
        )
        if (data && data.receiverEmail === userData?.email) {
          const { data } = await API.survey.getTypeformLinkForLeader(
            groupRequestId,
            uniqueRequestId,
          )
          window.open(data.link, '_self')
        }
        localStorage.removeItem(groupId)
        localStorage.removeItem(uniqueId)
      } catch (e) {}
    }

    if (surveyRequestId) getEmailBySurveyRequestId()
    if (groupRequestId && uniqueRequestId) getEmailForLeaderRequest()
  }, [surveyRequestId, groupRequestId, uniqueRequestId, userData])

  useEffect(() => {
    if (
      (querySource === '360' || querySource === 'leader') &&
      user?.surveyTaken
    ) {
      setShowModal(true)
      history.replace('/home')
    }
  }, [history, querySource, user.surveyTaken])

  useEffect(() => {
    if (
      !['/home/values-principles', '/home/purpose-vision'].includes(pathname) &&
      querySource !== '360' &&
      querySource !== 'leader'
    ) {
      history.replace('/home')
    }
  }, [history, querySource, pathname])

  const renderHomeComponents = () => {
    if (!user?.surveyTaken) {
      return <FreePlanHome surveyTaken={false} />
    }
    if (userData.planCode === plans.free.code) {
      return <FreePlanHome surveyTaken={userData.surveyTaken} />
    }
    if (userData.planCode === plans.growth.code) {
      return <GrowthPlanHome />
    }
    return <CoachingPlanHome />
  }

  return (
    <>
      <Helmet>
        <title>{'Home | Abroad'}</title>
        <meta name='title' content={'Home | Abroad'}></meta>
        <meta property='og:title' content={'Home | Abroad'}></meta>
        <meta property='twitter:title' content={'Home | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/home`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/home`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/home`}></meta>
      </Helmet>
      <main>
        <FeedbackSubmittedModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <section>
          <Container className='px-0' fluid>
            <PageHeader title='Home' />
            <Switch location={location}>
              <PrivateRoute exact path='/home'>
                {!isInIFrame && querySource === null && (
                  <ModalProvider>
                    <UpgradePlanModalProvider>
                      {renderHomeComponents()}
                      <CategoryModal />
                      <UpgradePlanModal />
                    </UpgradePlanModalProvider>
                  </ModalProvider>
                )}
                {(querySource === '360' || querySource === 'leader') && (
                  <CustomHomeLASurvey />
                )}
              </PrivateRoute>
              <Redirect to='/' />
            </Switch>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Home
