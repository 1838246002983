import React from 'react'
import { Table } from 'react-bootstrap'
import RemoveCard from './RemoveCard'

const CardListingTable = ({ cards, removeCardHandler }) => {
  return (
    <Table hover responsive className='custom-table'>
      <thead>
        <tr>
          <th width='34%'>Card Number</th>
          <th width='33%'>Card Expiry Month And Year</th>
          <th width='33%'>Action</th>
        </tr>
      </thead>
      <tbody>
        {cards.map((card, index) => (
          <tr key={index}>
            <td width='34%'>{`**** **** **** ${card?.card?.last4}`}</td>
            <td width='33%'>
              {card?.card?.exp_month}/{card?.card?.exp_year}
            </td>
            <td width='33%'>
              <RemoveCard
                cardId={card?.id}
                removeCardHandler={removeCardHandler}
                key={card?.id}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default CardListingTable
