import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Accordion, Card, Modal } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { useLocation } from 'react-router-dom'
import {
  Button,
  useErrorService,
  paymentType,
  getNextPaymentDate,
} from '@abroad/components'
import CardListing from './CardListing'
import BillingAddress from './BillingAddress'
import API from '../../utils/API'
import { isLGScreen } from '../../utils/utility'

const PAY_SOW_STEPS = {
  PAY_INFO: 1,
  PAY_METHOD: 2,
  PAY_BILLING: 3,
  PAY_CONFIRM: 4,
}

const PayButton = ({
  payment,
  onSuccess,
  addressObject,
  setAddressObject,
  isLoading = false,
  setIsToggleCardListing,
  isToggleCardListing,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isPaying, setIsPaying] = useState(false)
  const [steps, setSteps] = useState(PAY_SOW_STEPS.PAY_INFO)
  const [cardId, setCardId] = useState(null)
  const [isAddressObjChanged, setIsAddressObjChanged] = useState(false)
  const Error = useErrorService()
  const location = useLocation()
  const query = new URLSearchParams(location?.search)
  const sowId = query.get('sowId')
  const handleClose = () => {
    if (isPaying) return
    setShowModal(false)
    setCardId(null)
    setSteps(PAY_SOW_STEPS.PAY_INFO)
  }

  useEffect(() => {
    if (sowId === payment?._id) {
      setShowModal(true)
    }
  }, [sowId, payment])

  const paymentHandler = async () => {
    setIsPaying(true)
    try {
      const { status } = await API.payment.paySOW({
        paymentMethodId: cardId,
        ...(isAddressObjChanged && { address: addressObject }),
        sowId: payment?._id,
      })
      if (status) {
        onSuccess(payment?._id)
        setIsPaying(false)
        handleClose()
      }
    } catch (e) {
      setIsPaying(false)
      Error.showError(e)
    }
  }

  const CustomToggle = ({ children, eventKey, ...rest }) => {
    const decoratedOnClick = useAccordionButton(eventKey)

    return (
      <div {...rest} onClick={decoratedOnClick}>
        {children}
      </div>
    )
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className={isLGScreen ? 'custom__modal' : ''}>
        <Modal.Header className='px-4'>
          <Modal.Title className='text-uppercase font-montserrat font-16 font-bold leading-5 letter-spacing-02 s7'>
            Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-4 promo-accordion'>
          <Accordion defaultActiveKey={steps} key={steps}>
            <Card className='mb-3'>
              <CustomToggle
                as={Card.Header}
                eventKey={PAY_SOW_STEPS.PAY_INFO}
                className={classNames(
                  'border-0 pay-card-header cursor-pointer font-14 px-3',
                  {
                    'pointer-event-none': isPaying,
                  },
                )}
                onClick={() => setSteps(PAY_SOW_STEPS.PAY_INFO)}>
                Payment Information
              </CustomToggle>
              <Accordion.Collapse eventKey={PAY_SOW_STEPS.PAY_INFO}>
                <Card.Body className='px-3 d-flex flex-column'>
                  <span className='s5 mb-2'>
                    Description: {payment?.planName} with{' '}
                    {payment?.coachId?.fullName}
                  </span>
                  <span className='s5 mb-2'>
                    Amount: {/* added initialFee && amount */}
                    {payment?.initialFee &&
                    payment?.amount &&
                    payment?.paymentCycle === Object.values(paymentType)[0]
                      ? `$${payment?.initialFee + payment?.amount} Today + $${
                          payment?.amount
                        } from ${getNextPaymentDate(
                          payment?.invoiceEvery,
                        )}, every ${payment?.invoiceEvery} month(s).`
                      : null}
                    {/* added amount */}
                    {payment?.initialFee === 0 &&
                    payment?.amount &&
                    payment?.paymentCycle === Object.values(paymentType)[0]
                      ? `$${payment?.amount} from Today, every ${payment?.invoiceEvery} month(s).`
                      : null}
                    {/* added initialFee & payment type is onetime */}
                    {payment?.paymentCycle === Object.values(paymentType)[1] &&
                    (payment?.initialFee || payment?.initialFee === 0)
                      ? `$${payment?.initialFee}`
                      : null}
                  </span>

                  {payment?.paymentCycle !== Object.values(paymentType)[1] && (
                    <span className='s6 mb-2'>
                      * Your plan will be renewed on{' '}
                      {getNextPaymentDate(payment?.invoiceEvery)} for{' '}
                      {`$${payment?.amount}`}.
                    </span>
                  )}
                  <div className='text-left'>
                    <Button
                      variant='saffron'
                      size='sm'
                      onClick={() => setSteps(PAY_SOW_STEPS.PAY_METHOD)}
                      className='text-uppercase border-radius-normal'>
                      Confirm
                    </Button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className='mb-3'>
              <CustomToggle
                as={Card.Header}
                eventKey={PAY_SOW_STEPS.PAY_METHOD}
                className={classNames(
                  'border-0 pay-card-header cursor-pointer font-14 px-3',
                  {
                    'pointer-event-none':
                      steps === PAY_SOW_STEPS.PAY_INFO || isPaying,
                  },
                )}
                onClick={() => setSteps(PAY_SOW_STEPS.PAY_METHOD)}>
                Payment Method
              </CustomToggle>
              <Accordion.Collapse eventKey={PAY_SOW_STEPS.PAY_METHOD}>
                <Card.Body className='px-3 d-flex flex-column'>
                  <CardListing
                    key={steps}
                    setCardId={setCardId}
                    cardId={cardId}
                    setIsToggleCardListing={setIsToggleCardListing}
                    isToggleCardListing={isToggleCardListing}
                  />
                  <div className='text-left'>
                    <Button
                      variant='saffron'
                      size='sm'
                      disabled={!cardId}
                      onClick={() => setSteps(PAY_SOW_STEPS.PAY_BILLING)}
                      className='text-uppercase border-radius-normal'>
                      Next
                    </Button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <CustomToggle
                as={Card.Header}
                eventKey={PAY_SOW_STEPS.PAY_BILLING}
                className={classNames(
                  'border-0 pay-card-header cursor-pointer font-14 px-3',
                  {
                    'pointer-event-none':
                      [
                        PAY_SOW_STEPS.PAY_INFO,
                        PAY_SOW_STEPS.PAY_METHOD,
                      ].includes(steps) || isPaying,
                  },
                )}
                onClick={() => setSteps(PAY_SOW_STEPS.PAY_BILLING)}>
                Billing Address - Optional
              </CustomToggle>
              <Accordion.Collapse eventKey={PAY_SOW_STEPS.PAY_BILLING}>
                <Card.Body className='px-3 d-flex flex-column'>
                  <BillingAddress
                    onSuccess={(newAddressObj, isAddressObjChanged) => {
                      if (isAddressObjChanged) {
                        setAddressObject(newAddressObj)
                        setIsAddressObjChanged(isAddressObjChanged)
                      } else {
                        setIsAddressObjChanged(isAddressObjChanged)
                      }
                      setSteps(PAY_SOW_STEPS.PAY_CONFIRM)
                    }}
                    addressObject={addressObject}
                    isLoading={isLoading}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between justify-content-lg-end px-4 space-x-3'>
          <Button
            variant='saffron'
            size='md'
            disabled={isPaying || steps !== PAY_SOW_STEPS.PAY_CONFIRM}
            isLoading={isPaying}
            onClick={paymentHandler}
            className='text-uppercase border-radius-normal'>
            Pay
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant='saffron'
        size='md'
        className='border-radius-normal'
        disabled={isPaying}
        isLoading={isPaying}
        onClick={() => setShowModal(true)}>
        Pay
      </Button>
    </>
  )
}

export default PayButton
