import React from 'react'
import classNames from 'classnames'
import { Button } from '@abroad/components'

const GrowthPlanBtn = ({
  buttonClasses = '',
  buttonText = 'Upgrade To Growth Plan',
}) => {
  return (
    <Button
      variant='saffron'
      className={classNames('btn s10a text-uppercase', buttonClasses)}
      onClick={() => {
        // redirect to abroad.io growth-plan
        window.open('https://www.abroad.io/growth-plan', '_blank')
      }}>
      {buttonText}
    </Button>
  )
}

export default GrowthPlanBtn
