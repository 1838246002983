import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import {
  Button,
  useNotificationService,
  useUserService,
  ABSpinner,
  useErrorService,
} from '@abroad/components'
import {
  OnboardTitle,
  OnboardSubTitle,
  AbroadGoldenIcon,
} from '../components/onboarding'
import API from '../utils/API'

const TwoFactorAuthSetup = ({ location, history }) => {
  const ref = React.useRef(null)
  const { user, setUser } = useUserService()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const [otp, setOtp] = useState('')
  const [hasErrored, setHasErrored] = useState(false)
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const handleChange = (otp) => setOtp(otp)
  const [qr, setQr] = useState(null)
  const [secret, setSecret] = useState(null)

  useEffect(() => {
    const getTwoFactorAuthData = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.onboarding.getTwoFactorAuthData()
        setQr(data.qr)
        setSecret(data.secret)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getTwoFactorInfo = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.onboarding.get2FAInfo(user?.id)
        const userIsAdmin = data?.isAdmin || data?.admin
        const userIsCoach = data?.isCoach || data?.coach
        setUser({
          ...user,
          isAdmin: userIsAdmin,
          isCoach: userIsCoach,
          twoFactorMode: data?.twoFactorMode,
          isTwoFactorVerified: data?.isTwoFactorVerified,
        })
        const query = new URLSearchParams(location?.search)
        const nextUrl = query.get('next')
        if (
          !data?.isTwoFactorVerified &&
          (userIsAdmin || userIsCoach) &&
          data?.twoFactorMode === 'APP'
        ) {
          history.push(`/2fa-login${nextUrl ? `?next=${nextUrl}` : ''}`)
        } else if (
          !data?.isTwoFactorVerified &&
          (userIsAdmin || userIsCoach) &&
          data?.twoFactorMode === 'EMAIL'
        ) {
          history.push(`/2fa-email${nextUrl ? `?next=${nextUrl}` : ''}`)
        } else if (!data?.isTwoFactorVerified && (userIsAdmin || userIsCoach)) {
          getTwoFactorAuthData()
        } else {
          history.push('/home')
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (user) {
      getTwoFactorInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (otp.length !== 6) {
      setHasErrored(true)
      Notification.showNotification('Enter correct OTP!', 'danger')
      return
    }
    setIsBtnLoading(true)
    try {
      const { data } = await API.onboarding.setup2fa({
        code: otp,
        secret: secret,
      })
      setIsBtnLoading(false)
      setIsLoading(false)
      setUser({
        ...user,
        isTwoFactorVerified: data?.isTwoFactorVerified,
      })
      if (data?.isTwoFactorVerified) {
        history.push('/home')
      } else {
        setHasErrored(true)
        Notification.showNotification('Incorrect OTP!', 'danger')
      }
    } catch (e) {
      setIsBtnLoading(false)
      Error.showError(e)
    }
  }

  useEffect(() => {
    if (otp) {
      setHasErrored(false)
    }
  }, [otp])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{'Two Factor Auth Setup | Abroad'}</title>
        <meta name='title' content={'Two Factor Auth Setup | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Two Factor Auth Setup | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Two Factor Auth Setup | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/2fa-setup`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/2fa-setup`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/2fa-setup`}></meta>
      </Helmet>
      <Container fluid className='px-0'>
        <Row
          className='vh-100 row g-0 bg-abroad-blue twofa-setup'
          ref={ref}
          lg={2}>
          <Col className='d-none d-lg-flex justify-content-center signup-left-section bg-abroad-blue'>
            <section className='h-75'>
              <div className='d-flex flex-column left-section'>
                <OnboardTitle className='word-break s2'>
                  Secure Your Account with Two-Factor Authentication (2FA)
                </OnboardTitle>
                <div className='text-white s5 mt-4 word-break'>
                  <span className='opacity-75'>
                    Protect your account in seconds with Two-Factor
                    Authentication. Simply scan the QR code with your preferred
                    Authenticator App, enter the provided PIN, and hit 'Confirm'
                    to activate. Alternatively, you can opt for an email OTP
                    instead by{' '}
                  </span>
                  <Link to='/2fa-email' className='opacity-100 fw-semibold'>
                    clicking here
                  </Link>
                </div>
              </div>
            </section>
          </Col>
          <Col className='onboard bg-abroad-blue'>
            <div className='h-100 d-flex flex-column align-items-center'>
              <AbroadGoldenIcon />
              <section className='onboard-wrapper mt-40px h-75'>
                {qr ? (
                  <div className='text-center'>
                    <img src={qr} alt='Info' className='qr-code' />
                  </div>
                ) : (
                  <div className='d-flex justify-content-center align-items-center my-3'>
                    <Spinner animation='border' className='text-saffron-700' />
                  </div>
                )}
                <div className='justify-content-center align-items-center'>
                  <OnboardSubTitle className='mt-40px mb-36px'>
                    Please enter the two factor authentication code from the
                    application
                  </OnboardSubTitle>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className='w-100 align-items-center justify-content-center mt-40px'>
                      <OtpInput
                        hasErrored={hasErrored}
                        errorStyle='two-fa-otp-error'
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        isInputNum={true}
                        containerStyle='two-fa-otp-input justify-content-between'
                        inputStyle={{ color: 'white' }}
                      />
                    </div>
                    <Button
                      isLoading={isBtnLoading}
                      disabled={isBtnLoading}
                      type='submit'
                      className='btn w-100 s9 mb-4 mt-40px'
                      variant='saffron'>
                      Confirm
                    </Button>
                  </form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TwoFactorAuthSetup
