import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { ShimmerBadge, ShimmerTable } from 'react-shimmer-effects'
import TagManager from 'react-gtm-module'
import { useErrorService, useUserService } from '@abroad/components'
import FavoriteFilterTabs from './FavoriteFilterTabs'
import FavoritePlayIcon from './FavoritePlayIcon'
import { TableInstance, Table, tableConst } from '../table'
import API from '../../utils/API'
import favoriteEvent from '../../constants/events'
import { titleCase } from '../../utils/utility'
import { LayoutContext } from '../../utils/contexts'
import { conditionMatched } from '../layout/Layout'
import { FreePlanNoSurvey } from '../Home/FreePlanHome'

const audiosCount = 20
const audioLimit = 25

const getMediaOrModuleSubDetails = (media) => {
  return (
    <div className='s11 d-flex text-dark-900-5 custom-ellipsis-text'>
      <div className='d-flex'>
        <span>{titleCase(media?.categoryId?.title)}</span>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='4'
          height='4'
          viewBox='0 0 4 4'
          fill='none'
          className='mx-1 align-self-center'>
          <circle cx='2' cy='2' r='2' fill='black' fill-opacity='0.5' />
        </svg>
      </div>
      <span className='custom-ellipsis-text'>
        {titleCase(
          media?.isProgram
            ? media?.subCategoryId?.subTitle
            : media?.subCategoryId?.title,
        )}
      </span>
    </div>
  )
}

const FavoriteAudios = () => {
  const hasMore = useRef(false)
  const [favoriteAudios, setFavoriteAudios] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const [showSurveyNotTakenPage, setShowSurveyNotTakenPage] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [reqData, setReqData] = useState(null)
  const [isInitiallyLoad, setIsInitiallyLoad] = useState(true)
  const [bookmarkHover, setBookmarkHover] = useState(false)
  const [isAnyFavoriteMedia, setIsAnyFavoriteMedia] = useState(false)
  const [isUnfavoriteLoading, setIsUnfavoriteLoading] = useState(false)
  const Error = useErrorService()
  const { user } = useUserService()
  const userId = user?._id
  const {
    isMediaLoading,
    setIsMediaLoading,
    mediaObject,
    setMediaObject,
    setShowPlayBar,
  } = useContext(LayoutContext)

  const getFavorites = useCallback(async () => {
    setIsLoading(true)
    const params = `?page=${reqData?.page}&limit=${audioLimit}${
      reqData?.categoryId ? `&categoryId=${reqData?.categoryId}` : ''
    }`
    try {
      const { data } = await API.mediaLibrary.getFavorites(params)
      if (data) {
        if (data?.medias?.length === 0 && isInitiallyLoad) {
          setIsAnyFavoriteMedia(false)
        } else {
          setIsAnyFavoriteMedia(true)
        }
        if (data?.loadMore) {
          hasMore.current = true
        } else {
          hasMore.current = false
        }
        if (reqData.page > 1) {
          setFavoriteAudios((prev) => [...prev, ...data?.medias])
        } else {
          setFavoriteAudios(data?.medias)
        }
        setIsInitiallyLoad(false)
        setIsLoading(false)
      } else {
        setIsInitiallyLoad(false)
        setIsLoading(false)
      }
    } catch (e) {
      if (e?.code === 'not_taken_survey') {
        setShowSurveyNotTakenPage(true)
        setIsLoading(false)
        setIsInitiallyLoad(false)
      } else {
        Error.showError(e)
        setIsLoading(false)
        setIsInitiallyLoad(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getFavorites(reqData)
    }
  }, [getFavorites, reqData])

  const fetchData = useCallback(({ categoryId, page, limit }) => {
    setIsLoading(true)
    if (page === 1) {
      setFavoriteAudios([])
    }
    const req = {
      limit,
      page: page,
      categoryId,
    }
    setReqData(req)
  }, [])

  const getAPIFunction = (isProgram, mediaId, subCategoryId) => {
    if (isProgram) {
      return API.programs.favoriteModuleAudio(subCategoryId, mediaId, {
        isFavorite: false,
      })
    } else {
      // for media library audio
      return API.mediaLibrary.toggleFavorite(
        {
          isFavorite: false,
        },
        mediaId,
      )
    }
  }

  const gtmUnfavoriteAudioAction = (title) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: favoriteEvent.event.favoritedAudioDislike,
        eventProps: {
          category: favoriteEvent.category.favoritedAudioDislikeCategory,
          action: 'User dislike favorited audio',
          label: 'Dislike favorited audio',
          value: `isFavorite - false, favorite audio title is ${title} on Favorites Page`,
          userId,
        },
      },
    })
  }

  const unfavoriteAudio = useCallback(
    async (isProgram, mediaId, subCategoryId, title) => {
      try {
        setIsUnfavoriteLoading(true)
        const { status } = await getAPIFunction(
          isProgram,
          mediaId,
          subCategoryId,
        )
        if (status) {
          if (favoriteAudios?.length === 1 && !selectedId) {
            setIsAnyFavoriteMedia(false)
          }
          setFavoriteAudios((prev) =>
            prev.filter((media) => media?._id !== mediaId),
          )
          gtmUnfavoriteAudioAction(title)
          setIsUnfavoriteLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsUnfavoriteLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedId, favoriteAudios],
  )

  const getMedia = async (mediaId, mediaSubCategoryId, isProgramMedia) => {
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      let mediaData
      if (isProgramMedia) {
        mediaData = await API.programs.getMediaById(
          mediaSubCategoryId,
          mediaId,
          null, //journeyId
          null, // sequenceId
        )
        mediaData = mediaData?.data
      } else {
        mediaData = await API.mediaLibrary.getMediaById(
          mediaSubCategoryId,
          mediaId,
          null, //journeyId
          null, // sequenceId
        )
        mediaData = mediaData?.data
      }
      if (mediaData) {
        const mediaObjectInstance = {
          ...mediaData,
          disabledControls: true,
        }
        setMediaObject(mediaObjectInstance)
      }
      setIsMediaLoading(false)
    } catch (e) {
      if (e.code === 'upgrade_plan' && !mediaObject) {
        setShowPlayBar(false)
      }
      setIsMediaLoading(false)
      Error.showError(e)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'index',
        Header: '#',
        width: '5%',
        accessor: (properties) => properties,
        Cell: ({ row }) => (
          <span className='hover:td-play-icon'>
            <span>{row.index + 1}</span>
            <span className='icon icon-play font-16 icon-outline-gold text-start'></span>
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'title',
        Header: 'Title',
        width: '40%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <div className='d-flex'>
            <Imgix
              className='border-radius-5'
              imgixParams={{ fit: 'scale', w: 42, h: 42 }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/${
                cell.value?.subCategoryId?.imageFilename ||
                cell.value?.imageFilename
              }`}
              height={window.innerWidth > 2000 ? 60 : 48}
              width={window.innerWidth > 2000 ? 60 : 48}
              alt='image'
            />
            <div className='ms-2 media-details d-flex flex-column w-100 align-self-center'>
              <span className='mb-1 custom-ellipsis-text'>
                {titleCase(cell.value?.title)}
              </span>
              {getMediaOrModuleSubDetails(cell.value)}
            </div>
          </div>
        ),
        disableSortBy: true,
      },
      {
        id: 'expert',
        Header: 'Expert',
        width: '25%',
        accessor: (properties) => properties?.expert,
        Cell: ({ cell }) => <span>{cell?.value}</span>,
        disableSortBy: true,
      },
      {
        id: 'lenght',
        Header: 'Lenght',
        width: '23%',
        accessor: (properties) => properties?.duration,
        Cell: ({ cell }) => (
          <span>
            {cell?.value ? `${Math.round(cell?.value / 60)} Mins` : ''}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'action',
        Header: '',
        width: '7%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <div className='text-end'>
            <span
              onMouseEnter={(e) => {
                setBookmarkHover(true)
              }}
              onMouseLeave={(e) => {
                setBookmarkHover(false)
              }}
              className={classNames(
                'icon font-16 cursor-pointer icon-bookmark-gold',
                {
                  'pe-none': isUnfavoriteLoading || isMediaLoading,
                },
              )}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                unfavoriteAudio(
                  cell?.value?.isProgram,
                  cell?.value?._id,
                  cell?.value?.subCategoryId?._id,
                  cell?.value?.title,
                ) // toggleIsFavorite(isProgram, mediaId, subCategoryId, title)
              }}></span>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [unfavoriteAudio, isMediaLoading, isUnfavoriteLoading],
  )

  if (showSurveyNotTakenPage) {
    return <FreePlanNoSurvey />
  }

  if (!isAnyFavoriteMedia && !isLoading && !isInitiallyLoad)
    return (
      <h3 className='mt-32px text-start s5'>
        You haven't added any favorites yet. Browse through our content, and tap
        on <br className='d-lg-block d-none' /> the bookmark icon to add items
        here for quick access!
      </h3>
    )

  return (
    <section className='mt-32px custom-offset-lg-1 custom-col-lg-12 px-0'>
      <TableInstance
        columns={columns}
        data={favoriteAudios}
        initialState={{
          globalFilter: {
            categoryId: null,
          },
        }}>
        {({
          preGlobalFilteredRows,
          state: { globalFilter, ...other },
          setGlobalFilter,
          ...rest
        }) => (
          <>
            {isInitiallyLoad && (
              <>
                <div className='mt-40px mb-52px d-flex fevorite'>
                  <ShimmerBadge width={120} />
                  <ShimmerBadge width={120} />
                  <ShimmerBadge width={120} />
                  <ShimmerBadge width={120} />
                </div>
                <ShimmerTable row={3} col={5} />
              </>
            )}
            <Container
              className={classNames('px-0', {
                invisible: isInitiallyLoad,
              })}
              fluid>
              <div
                className={classNames('d-flex', {
                  'flex-column': conditionMatched,
                  'flex-lg-row flex-column align-items-lg-center':
                    !conditionMatched,
                  'justify-content-lg-between':
                    favoriteAudios?.length > audiosCount && !isLoading,
                  'justify-content-lg-end':
                    favoriteAudios?.length <= audiosCount &&
                    !globalFilter?.categoryId &&
                    !isLoading,
                })}>
                <FavoriteFilterTabs
                  isShow={
                    favoriteAudios?.length > audiosCount ||
                    globalFilter?.categoryId
                  }
                  {...{
                    globalFilter,
                    setGlobalFilter,
                  }}
                  isLoading={isLoading}
                  isEmpty={
                    favoriteAudios?.length === 0 &&
                    globalFilter?.categoryId &&
                    !isLoading
                  }
                  setSelectedId={setSelectedId}
                />
                <FavoritePlayIcon
                  isLoading={isLoading}
                  favoriteAudios={favoriteAudios}
                  getMedia={getMedia}
                  className={classNames({
                    'mt-lg-0 mt-3': !conditionMatched,
                    'mt-3': conditionMatched,
                    'ms-4': hasMore,
                  })}
                />
              </div>
              <div className='mt-52px'>
                <Table
                  ref={hasMore}
                  emptyTableMessage='No audio(s) found.'
                  tableInstance={{
                    preGlobalFilteredRows,
                    state: { globalFilter, ...other },
                    setGlobalFilter,
                    ...rest,
                  }}
                  isLoading={isLoading}
                  fetchData={fetchData}
                  trClasses={isUnfavoriteLoading ? 'pe-none' : 'cursor-pointer'}
                  otherOption={tableConst.FAVORITE_PAGE}
                  bookmarkHover={bookmarkHover}
                />
              </div>
            </Container>
          </>
        )}
      </TableInstance>
    </section>
  )
}

export default FavoriteAudios
