import moment from 'moment'

export const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY')
}

export const getAssessmentCurrentData = (date, dateFormat) => {
  if (date) {
    return moment(date).format(dateFormat ? dateFormat : 'MMM Do, YYYY')
  } else {
    return moment().format(dateFormat ? dateFormat : 'MMM Do, YYYY')
  }
}
