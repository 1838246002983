import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import sysend from 'sysend'
import {
  Button,
  useUserService,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import {
  OnboardTitle,
  ReturnBtn,
  AbroadGoldenIcon,
} from '../components/onboarding'
import API from '../utils/API'
import AuthService from '../utils/AuthService'

const TwoFactorEmailLogin = () => {
  const [otp, setOtp] = useState('')
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [hideBtn, setHideBtn] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [time, setTime] = useState(10)
  const [hasErrored, setHasErrored] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const handleChange = (otp) => setOtp(otp)
  const history = useHistory()
  const { user, setUser } = useUserService()
  const Error = useErrorService()
  const Notification = useNotificationService()
  const location = useLocation()
  let timeInterval = null

  useEffect(() => {
    return () => {
      clearInterval(timeInterval)
    }
  }, [timeInterval])

  const setupTwoFactorAuthEmail = async (addTimeInerval = false) => {
    try {
      await API.onboarding.setup2faEmail()
      setIsLoading(false)
      setIsSending(false)
      if (addTimeInerval) {
        setHideBtn(true)
        timeInterval = setInterval(() => {
          setTime((prev) => {
            if (prev === 0) {
              setHideBtn(false)
              clearInterval(timeInterval)
              return 10
            }
            return prev - 1
          })
        }, 1000)
      }
    } catch (e) {
      setIsSending(false)
      setIsLoading(false)
      Error.showError(e)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setupTwoFactorAuthEmail()
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (otp.length !== 6) {
      setHasErrored(true)
      Notification.showNotification('Enter correct OTP!', 'danger')
      return
    }
    setIsBtnLoading(true)
    try {
      const { data } = await API.onboarding.login2faEmail({
        code: otp,
      })
      setUser({
        ...user,
        isTwoFactorVerified: data?.isTwoFactorVerified,
      })
      const cameFrom = location?.state?.cameFrom
      const next = location?.state?.next
      if (
        data?.isTwoFactorVerified &&
        (user?.isAdmin || user?.isCoach) &&
        next
      ) {
        setIsBtnLoading(false)
        if (cameFrom === 'client') {
          const url = new URL(next)
          history.replace(url.pathname)
        } else {
          window.location.replace(next)
        }
      } else if (data?.isTwoFactorVerified) {
        setIsBtnLoading(false)
        history.push('/home')
      } else {
        setHasErrored(true)
        setIsBtnLoading(false)
        Notification.showNotification('Incorrect OTP!', 'danger')
      }
    } catch (e) {
      setIsBtnLoading(false)
      Error.showError(e)
    }
  }

  useEffect(() => {
    if (otp) {
      setHasErrored(false)
    }
  }, [otp])

  const logout = async (e) => {
    e.preventDefault()
    try {
      await API.onboarding.logout()
      setUser(null)
      AuthService.removeData()
      sysend.broadcast('client_logout', { message: 'client_logout' })
      history.push('/')
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <section>
      <Helmet>
        <title>{'Two Factor Email Login | Abroad'}</title>
        <meta name='title' content={'Two Factor Email Login | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Two Factor Email Login | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Two Factor Email Login | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/2fa-email`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/2fa-email`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/2fa-email`}></meta>
      </Helmet>
      <Container fluid className='px-0'>
        <Row className='vh-100 row g-0'>
          <Col className='onboard bg-abroad-blue'>
            <div className='h-100 d-flex flex-column align-items-center'>
              <AbroadGoldenIcon />
              <section className='onboard-wrapper otp-screen h-75'>
                <OnboardTitle>One Time Password</OnboardTitle>
                <div className='justify-content-center align-items-center'>
                  <div className='s6 mt-2 text-white'>
                    Please enter the 2 factor authentication PIN that was sent
                    to your email.
                  </div>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className='w-100 align-items-center justify-content-center mt-40px'>
                      <OtpInput
                        isDisabled={isLoading}
                        hasErrored={hasErrored}
                        errorStyle='two-fa-otp-error'
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        isInputNum={true}
                        containerStyle='two-fa-otp-input justify-content-between'
                        inputStyle={{ color: 'white' }}
                        shouldAutoFocus
                      />
                    </div>
                    <div className='d-flex flex-column onbording-action-btn'>
                      <Button
                        isLoading={isBtnLoading}
                        disabled={isBtnLoading || isLoading}
                        type='submit'
                        className='btn w-100 s9 mb-4'
                        variant='saffron'>
                        Confirm
                      </Button>
                      <Button
                        disabled={isLoading || hideBtn || isSending}
                        onClick={() => {
                          setupTwoFactorAuthEmail(true) // addTimeInerval = true
                        }}
                        variant='outline-dark'
                        className='btn w-100 s9'>
                        {isSending ? 'Resending...' : 'Resend Email'}
                      </Button>
                    </div>
                  </form>
                  <div className='d-flex s5 mt-2 text-white'>
                    {hideBtn && `Resend email in ${time} second`}&nbsp;
                  </div>
                </div>
              </section>
              <ReturnBtn onClick={logout} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TwoFactorEmailLogin
