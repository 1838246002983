import React from 'react'
import { plans } from '@abroad/components'

import RecommendedAudio from './RecommendedAudio'
import { UpgradeNow } from '../common'
import { ProgramsList } from '../breakthrough'
import RecentNotesAndLogInsight from './RecentNotesAndLogInsight'
import RecentlyPlayedAudio from './RecentlyPlayedAudio'
import HomeMiniSurveyResult from './HomeMiniSurveyResult'

const GrowthPlanHome = () => {
  // const [planTakenLoading, setPlanTakenLoading] = useState(true)
  // const [growthPlanCallTaken, setGrowthPlanCallTaken] = useState()

  // useEffect(() => {
  //   const checkGrowthPlanTaken = async () => {
  //     try {
  //       setPlanTakenLoading(true)
  //       const { data } = await API.user.checkGrowthPlanScheduled()
  //       setGrowthPlanCallTaken(data.isGrowthCoachAssignmentScheduled)
  //       setPlanTakenLoading(false)
  //     } catch (e) {
  //       setPlanTakenLoading(false)
  //       Error.showError(e)
  //     }
  //   }
  //   checkGrowthPlanTaken()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <div>
      {/* {planTakenLoading ? (
        <div className='text-center'>
          <FeatherLoader />
        </div>
      ) : (
        growthPlanCallTaken && (
          <GrayInfoCard
            className='mt-32px mb-52px'
            text='Welcome to your growth plan. Within the next two business days, we
          will invite you to scheduled a call with a coach who has been hand
          picked based on what we have learned about you thus far. In the
          meantime, we encourage you to browse our media library.'
          />
        )
      )} */}
      <div className='mb-52px mt-32px'>
        <RecommendedAudio />
      </div>
      <HomeMiniSurveyResult />
      <RecentNotesAndLogInsight />
      <div className='mb-52px'>
        <RecentlyPlayedAudio />
      </div>
      <div>
        <div className='s1 mb-3'>Unlock doors to unparalleled growth</div>
        <div className='s5 mb-52px'>
          Our signature programs, curated by industry-leading experts, offer
          bespoke modules that empower, enlighten, and evolve your leadership
          skills. Get a taste of what's in store with a glimpse into the
          introductory modules.
        </div>
        <ProgramsList />
      </div>
      <UpgradeNow
        wrapperClasses='mt-52px mb-4 border-radius-full'
        title='Ready to Unlock the Full Experience?'
        subTitle={`Dive into each program's expansive journey, filled with tailored modules designed to redefine your leadership prowess. Upgrade to the Coaching Plan and embark on an odyssey of purposeful growth.`}
        buttonClasses='w-lg-30 leading-4'
        plan={plans.coaching.code}
      />
    </div>
  )
}

export default GrowthPlanHome
