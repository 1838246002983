import React from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import SendRequestInput from './SendRequestInput'
import { isLGScreen } from '../../utils/utility'

const UserRequestSection = ({
  requestData,
  canViewResult = false,
  canAddMembers = true,
  title,
  description,
  emailBody,
  type,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col lg={6} md={12} sm={12} className='px-0'>
          <h3 className='s3 mb-3'>{title}</h3>
          <p className='s5 mb-40px'>{description}</p>
          {isLGScreen && (
            <SendRequestInput
              type={type}
              requestData={requestData}
              canViewResult={canViewResult}
              canAddMembers={canAddMembers}
            />
          )}
        </Col>
        <Col lg={2} md={12} sm={12}></Col>
        <Col lg={4} md={12} sm={12} className='px-0'>
          <div
            dangerouslySetInnerHTML={{
              __html: emailBody,
            }}
            className={classNames('preview-email border-radius-5', {
              'mb-40px': !isLGScreen,
            })}
          />
          {!isLGScreen && (
            <SendRequestInput
              type={type}
              requestData={requestData}
              canViewResult={canViewResult}
              canAddMembers={canAddMembers}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default UserRequestSection
