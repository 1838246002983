import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import AssessmentScoreCard from './AssessmentScoreCard'
import { InfoIcon } from '../assetsComponents'

const SurveySubcategoriesTable = ({
  title,
  survey,
  onLearnMoreClick,
  innerBackgroudColor,
  outerBackgroudColor,
  categoryKeyName,
  wrapperClasses,
  tableClasses,
}) => {
  return (
    <div className={wrapperClasses}>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='assessment-chart text-center align-self-lg-center'>
          <AssessmentScoreCard
            title={title}
            percentage={survey?.results?.[categoryKeyName]?.percent}
            category={survey?.categories?.[categoryKeyName]}
            onLearnMoreClick={onLearnMoreClick}
            outerBackgroudColor={outerBackgroudColor}
            innerBackgroudColor={innerBackgroudColor}
            width='15.79rem'
            height='22.437rem'
            linkClasses='s11 mb-0 text-white cursor-pointer'
            variant='table-attached-card'
          />
        </div>
        <div
          className={classNames('assessment-score mt-lg-0 mt-4', tableClasses)}>
          <>
            {survey?.results?.[categoryKeyName]?.order.map(
              (subcategory, index) => (
                <div
                  className='survey-subcat-link cursor-pointer py-18px'
                  key={index}>
                  <div
                    className={classNames('courses-title-row', {
                      'pt-0': index === 0,
                    })}
                    data-id={
                      survey?.results?.[categoryKeyName]?.subcategories[
                        subcategory
                      ]?.title
                    }>
                    <div className='courses-title'>
                      <span className='s6 text-black'>
                        {
                          survey?.results?.[categoryKeyName]?.subcategories[
                            subcategory
                          ]?.title
                        }
                      </span>
                    </div>
                    <div className='courses-score'>
                      <span style={{ color: innerBackgroudColor }}>
                        {Number.isInteger(
                          survey?.results?.[categoryKeyName]?.subcategories[
                            subcategory
                          ]?.percent,
                        )
                          ? `${survey?.results?.[categoryKeyName]?.subcategories[subcategory]?.percent}%`
                          : 'N/A'}
                      </span>
                      <Link
                        to={`/survey/category/${subcategory}`}
                        className='text-decoration-none'>
                        <InfoIcon className='info-icon align-self-center cursor-pointer font-20 ms-3 text-decoration-none' />
                      </Link>
                    </div>
                  </div>
                </div>
              ),
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default SurveySubcategoriesTable
