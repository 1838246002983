import React from 'react'
import TagManager from 'react-gtm-module'
import { useUserService, useErrorService } from '@abroad/components'
import breakthroughModuleEvent from '../../constants/events'
import API from '../../utils/API'

const ModuleFav = ({ isFavorite, moduleId, updateModuleInfo }) => {
  const { user } = useUserService()
  const Error = useErrorService()
  const toggleFavorite = async () => {
    const isFavoriteLocal = isFavorite
    updateModuleInfo(!isFavorite)
    try {
      const { data } = await API.programs.toggleFavorite(
        {
          isFavorite: !isFavoriteLocal,
        },
        moduleId,
      )
      if (data) {
        //GTM code
        TagManager.dataLayer({
          dataLayer: {
            event: breakthroughModuleEvent.event.breakthroughModule,
            eventProps: {
              category:
                breakthroughModuleEvent.category.breakthroughModuleCategory,
              action: 'User like/dislike Module',
              label: 'Breakthrough module like/dislike',
              value: `isFavorite - ${!isFavoriteLocal} of ${moduleId} from Module Page`,
              userId: user?.id,
            },
          },
        })
      }
    } catch (e) {
      updateModuleInfo(isFavoriteLocal)
      Error.showError(e)
    }
  }
  return (
    <div className='d-flex icons my-4'>
      <span
        className={`icon icon-bookmark${
          isFavorite ? '-gold' : ''
        } cursor-pointer`}
        onClick={toggleFavorite}
      />
    </div>
  )
}

export default ModuleFav
