import AES from 'crypto-js/aes'
import encUtf8 from 'crypto-js/enc-utf8'
import { groupId, localStorageItemName, uniqueId } from './constants'
/**
 * Store and get user data
 *
 * @class Auth
 */
class AuthService {
  setRememberCookie(value) {
    document.cookie = `${
      process.env.REACT_APP_COOKIE_PREFIX
    }_remember=${value};expires=${new Date(
      '2050-01-01',
    ).toUTCString()};domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=/`
  }

  getRememberCookie() {
    try {
      if (document.cookie) {
        const rememberMe = document.cookie
          ?.split('; ')
          ?.find((row) =>
            row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_remember`),
          )
          ?.split('=')[1]
        return rememberMe === 'true'
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  getCookiesPolicy() {
    const date = new Date()
    const rememberMe = this.getRememberCookie()
    date.setTime(date.getTime() + (rememberMe ? 30 : 3) * 24 * 60 * 60 * 1000)
    return {
      expires: date,
      // sameSite: 'strict',
      domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
    }
  }

  getStorage() {
    return localStorage
  }

  // setLoginState(isLogin, rememberMe = this.rememberMe) {
  setLoginState(rememberMe = false) {
    this.setRememberCookie(rememberMe)
    // const cipherText = CryptoJS.AES.encrypt(
    //   JSON.stringify(isLogin),
    //   process.env.REACT_APP_CIPHER,
    //   { mode: CryptoJS.mode.CBC },
    // ).toString()
    // this.cookies.set(
    //   `${process.env.REACT_APP_COOKIE_PREFIX}_ab_auth`,
    //   cipherText,
    //   this.getCookiesPolicy(),
    // )
  }

  setUserData(data) {
    const cipherText = AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_CIPHER,
    ).toString()
    const revisited = AES.encrypt(
      JSON.stringify({ visited: true }),
      process.env.REACT_APP_CIPHER,
    ).toString()
    // this.cookies.set(
    //   `${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`,
    //   cipherText,
    //   this.getCookiesPolicy(),
    // ) // ab_u => abroad user
    // this.cookies.set(
    //   `${process.env.REACT_APP_COOKIE_PREFIX}_ab_rs`,
    //   revisited,
    //   this.getRevisitedCookiesPolicy(),
    // )
    // this.cookies.set(
    //   `${process.env.REACT_APP_COOKIE_PREFIX}_userId`,
    //   data.id,
    //   this.getCookiesPolicy(),
    // )
    const cookiePolicy = this.getCookiesPolicy()
    document.cookie = `${
      process.env.REACT_APP_COOKIE_PREFIX
    }_ab_u=${cipherText};expires=${cookiePolicy.expires.toUTCString()};domain=${
      cookiePolicy.domain
    };path=/`
    document.cookie = `${
      process.env.REACT_APP_COOKIE_PREFIX
    }_ab_rs=${revisited};expires=${cookiePolicy.expires.toUTCString()};domain=${
      cookiePolicy.domain
    };path=/`
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_userId=${
      data.id
    };expires=${cookiePolicy.expires.toUTCString()};domain=${
      cookiePolicy.domain
    };path=/`
  }

  getUserData() {
    try {
      // const user = this.cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`)
      if (document.cookie) {
        const user = document.cookie
          ?.split('; ')
          ?.find((row) =>
            row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`),
          )
          ?.split('=')[1]
        const bytes = AES.decrypt(user, process.env.REACT_APP_CIPHER)
        return JSON.parse(bytes.toString(encUtf8))
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  getLoginState() {
    const user = this.getUserData()
    return user ? true : false
  }

  getUserId() {
    const user = this.getUserData()
    return user?._id || user?.id
  }

  removeData() {
    const cookiePolicy = this.getCookiesPolicy()
    const expires = new Date(null).toUTCString()
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_ab_u=;expires=${expires};domain=${cookiePolicy.domain};path=/`
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_userId=;expires=${expires};domain=${cookiePolicy.domain};path=/`
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_remember=;expires=${expires};domain=${cookiePolicy.domain};path=/`
    document.cookie = `ab_auth=;expires=${expires};path=/`
    document.cookie = `ab_u=;expires=${expires};path=/`
    document.cookie = `userId=;expires=${expires};path=/`
    // this.cookies.remove('ab_auth')
    // this.cookies.remove('ab_u')
    // this.cookies.remove('userId')
    // this.cookies.remove(
    //   `${process.env.REACT_APP_COOKIE_PREFIX}_ab_auth`, this.getCookiesPolicy(),
    // )
    // this.cookies.remove(
    //   `${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`, this.getCookiesPolicy(),
    // )
    // this.cookies.remove(
    //   `${process.env.REACT_APP_COOKIE_PREFIX}_userId`,
    //   this.getCookiesPolicy(),
    // )
    localStorage.removeItem(localStorageItemName)
    localStorage.removeItem(groupId)
    localStorage.removeItem(uniqueId)
  }

  isLogedIn() {
    const auth = this.getLoginState()
    if (auth) {
      return true
    } else {
      return false
    }
  }

  isUserRevisited() {
    if (document.cookie) {
      const revisited = document.cookie
        ?.split('; ')
        ?.find((row) =>
          row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_rs`),
        )
        ?.split('=')[1]
      if (revisited) {
        return true
      }
      return false
    } else {
      return false
    }
  }
}

export default new AuthService()
