import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import TagManager from 'react-gtm-module'
import API from '../../utils/API'
import takeAssessmentEvent from '../../constants/events'
import { groupId, localStorageItemName, uniqueId } from '../../utils/constants'

const TakeNewAssessment = ({
  variant,
  className,
  title,
  wrapperProps,
  getTypeFormLink = false,
  getEmailBySurveyRequestId = () => {},
  getEmailByGroupRequestId = () => {},
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [link, setLink] = useState('')
  const Notification = useNotificationService()
  const surveyRequestId = localStorage.getItem(localStorageItemName)
  const groupRequestId = localStorage.getItem(groupId)
  const uniqueRequestId = localStorage.getItem(uniqueId)
  const { user } = useUserService()

  const getLink = async (isEmailMatch) => {
    if (isEmailMatch) {
      if (groupRequestId && uniqueRequestId) {
        return API.survey.getTypeformLinkForLeader(
          groupRequestId,
          uniqueRequestId,
        )
      }
      return API.survey.getTypeFormLink(surveyRequestId)
    }
    return API.survey.getLink()
  }

  useEffect(() => {
    const getUrl = async () => {
      try {
        let isEmailMatch
        if (groupRequestId && uniqueRequestId) {
          isEmailMatch = await getEmailByGroupRequestId()
        } else {
          isEmailMatch = await getEmailBySurveyRequestId()
        }
        const { data } = await getLink(isEmailMatch)
        setLink(data.link)
        setIsLoading(false)
        setError(null)
      } catch (e) {
        if (e?.code === 'survey_limit_reached') {
          setError(e.message)
        }
        setIsLoading(false)
      }
    }
    getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const takeNewAssessment = () => {
    Notification.showNotification(error)
  }

  const gtmAssessmentBtnClickedAction = () => {
    if (title === 'Take the Assessment') {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: takeAssessmentEvent.event.freeUserTakeAssessment,
          eventProps: {
            category:
              takeAssessmentEvent.category.freeUserTakeAssessmentCategory,
            action: 'user take their first survey assessment',
            label: 'Take first assessment',
            value: `user take their first survey assessment`,
            userId: user?.id,
          },
        },
      })
    } else {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: takeAssessmentEvent.event.TakeAssessment,
          eventProps: {
            category: takeAssessmentEvent.category.UserTakeAssessmentCategory,
            action: 'User take assessment',
            label: 'Take assessment',
            value: `User take new survey assessment`,
            userId: user?.id,
          },
        },
      })
    }
    localStorage.removeItem(localStorageItemName)
    localStorage.removeItem(groupId)
    localStorage.removeItem(uniqueId)
  }

  return (
    <a
      {...(link && {
        href: link,
        target: 'blank',
        rel: 'noopener noreferrer',
      })}
      {...(error
        ? { onClick: takeNewAssessment }
        : { onClick: gtmAssessmentBtnClickedAction })}
      {...wrapperProps}>
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        variant={variant}
        className={className}
        {...rest}>
        {title ? title : <>Take new assessment</>}
      </Button>
    </a>
  )
}

TakeNewAssessment.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
}

TakeNewAssessment.defaultProps = {
  variant: 'black',
}

export default TakeNewAssessment
