import { plans, programs } from '@abroad/components'

export const isLGScreen = window.innerWidth >= 992 ? true : false
export const isSMScreen =
  window.innerWidth >= 576 && window.innerWidth < 768 ? true : false
export const isMDScreen =
  window.innerWidth >= 768 && window.innerWidth < 992 ? true : false
export const isCutomSMScreen =
  window.innerWidth <= 576 && window.innerWidth < 768 ? true : false

export const titleCase = (string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
)

export const getAudioProgress = (lastPlayedDuration = 0, totalDuration) => {
  let audioProgress = 0
  for (let i = 10; i <= 80; i = i + 10) {
    if (lastPlayedDuration > (totalDuration * i) / 100) {
      audioProgress = i
    }
  }
  if (audioProgress === 80) return 100
  return audioProgress
}

export const firstNameRegex = /^[A-Za-z]+$/
export const fullNameRegex = /^[A-Za-z ]+$/
export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const checkUserGetOldPlan = (queryPlanCode) => {
  return [
    'breakthrough',
    'evolution',
    'breakthrough6',
    'evolution6',
    'actualize6',
    'actualize',
    'resilience',
  ].includes(queryPlanCode)
}

export const convertOldPlanWithNewPlan = (queryPlanCode) => {
  return plans.coaching?.code
}

// TODO: create common function
export const getJourneyTypePath = (journeyAudioType, urlJourneyType) => {
  const type = journeyAudioType || urlJourneyType
  let path
  switch (type) {
    case 1:
    case '1':
      path = 'individual'
      break
    case 2:
    case '2':
      path = 'organization'
      break
    case 3:
    case '3':
      path = 'group'
      break
    default:
      break
  }
  return path
}

export const getProgramName = (key) => {
  const module = Object.values(programs).filter((values) => values?.key === key)
  return module[0]?.name
}

export const getAllProgramsKeysArray = () => {
  return Object.values(programs).map((values) => values?.key)
}

export const checkIsProgramAudio = (key) => {
  const allModuleKeys = getAllProgramsKeysArray()
  return allModuleKeys.includes(key)
}

export const getParentIdByModuleKey = (key) => {
  const module = Object.values(programs).filter((values) => values?.key === key)
  return module[0]?.id
}

export const getProgramUrl = (key) => {
  const module = Object.values(programs).filter((values) => values?.key === key)
  return module[0]?.pageUrl
}

export const getModuleNameByParentId = (id) => {
  const module = Object.values(programs).filter((values) => values?.id === id)
  return module[0]?.name
}

export const getProgramUrlByParentId = (id) => {
  const module = Object.values(programs).filter((values) => values?.id === id)
  return module[0]?.pageUrl
}

export const getResendEmailParams = (
  queryCompanyCode,
  queryEcosystemCode,
  queryPromo,
) => {
  const searchParams = new URLSearchParams()
  if (queryCompanyCode) {
    searchParams.append('companyCode', queryCompanyCode)
  }
  if (queryEcosystemCode) {
    searchParams.append('networkCode', queryEcosystemCode)
  }
  if (queryPromo) {
    searchParams.append('promo', queryPromo)
  }
  return searchParams
}

export const getExpiresDate = (days = 7) => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = date.toUTCString()
  return expires
}

export const getCurrentExpiresDate = () => {
  const expires = new Date(null).toUTCString()
  return expires
}

export const getCookieByName = (cookieName) => {
  if (!cookieName) return null
  const cookie = document.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith(cookieName))
    ?.split('=')[1]
  return cookie
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const secondsToMin = (sec) => {
  var minutes = Math.floor(sec / 60)
  var seconds = (sec % 60).toFixed(0)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}
