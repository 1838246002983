import React from 'react'
import classNames from 'classnames'

const JournalIcon = ({ active = false, className, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      className={classNames(
        'cursor-pointer',
        {
          active: active,
        },
        className,
      )}
      {...rest}>
      <path
        d='M6.4 2.5H17.6C18.668 2.5 19.5 3.32763 19.5 4.30769V19.6923C19.5 20.6724 18.668 21.5 17.6 21.5H6.4C5.33199 21.5 4.5 20.6724 4.5 19.6923V4.30769C4.5 3.32763 5.33199 2.5 6.4 2.5Z'
        fill={active ? '#BF9000' : 'none'}
        stroke={active ? '#BF9000' : 'black'}
        strokeLinejoin='round'
      />
      <path
        d='M15.2031 2V22'
        className='journel-line'
        stroke={active ? 'white' : 'black'}
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default JournalIcon
