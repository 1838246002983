import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import API from '../../utils/API'
import { MediaBanner } from '../common'

const CustomHomeLASurvey = () => {
  const imgCropFPObject = {
    // free plan user without survey
    'fp-x': 0.5,
    'fp-y': 0.48,
    'fp-z': 1.45,
  }

  const [link, setLink] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getUrl = async () => {
      try {
        const { data } = await API.survey.getLink()
        setLink(data.link)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }
    getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='mt-32px'>
      <div className='s3 mb-12px'>
        Thank You, Your Feedback Has Been Submitted
      </div>
      <div className='s5 mb-52px'>
        If you would like to receive data-driven insights on your personal
        well-being, team cohesion, and motivation, we encourage you to take
        Abroad’s free 15-minute Awareness Assessment. As an added bonus, we will
        provide you with audio content related to your key areas of growth.
      </div>
      <Container fluid>
        <Row>
          <Col className='col-6 px-0'>
            <MediaBanner
              imageFilename='freePlanWithoutSurvey.png'
              imgCropFP={imgCropFPObject}
            />
          </Col>
          <Col className='col-6 bg-abroad-blue d-flex justify-content-center align-items-center begin-section px-0'>
            <Link
              className={classNames('text-white d-flex text-decoration-none', {
                'pointer-events-none': isLoading,
              })}
              to={{
                pathname: link,
              }}
              target='_blank'>
              <div className='text-white s9 mr-12px'>Begin</div>
              <span className='icon icon-right-arrow text-white' />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CustomHomeLASurvey
