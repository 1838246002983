import React, { useContext } from 'react'
import OnboardSubTitle from './OnboardSubTitle'
import OnboardTitle from './OnboardTitle'
import { InviteDetailsContext } from '../../utils/contexts'

const signupTitle = 'Your Journey Begins with Awareness'
const signupSubTitle =
  'Join thousands of leaders and their teams who have taken our awareness assessment to uncover hidden opportunities for growth.'

const SignupLeftSection = () => {
  const { inviteLinkDetails } = useContext(InviteDetailsContext)
  return (
    <section className='h-75'>
      <div className='d-flex flex-column onboard-wrapper'>
        <OnboardTitle className='word-break'>
          {inviteLinkDetails?.header ? inviteLinkDetails?.header : signupTitle}
        </OnboardTitle>
        <OnboardSubTitle
          className='word-break opacity-75 mt-4'
          commonClass='s5'>
          {inviteLinkDetails?.body ? inviteLinkDetails?.body : signupSubTitle}
        </OnboardSubTitle>
      </div>
    </section>
  )
}

export default SignupLeftSection
