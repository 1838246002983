import React from 'react'
import classNames from 'classnames'
import AssessmentScoreCard from './AssessmentScoreCard'
import InfoIconOverlay from './InfoIconOverlay'

const EfficacySubCategoryTable = ({
  managerEfficacyKeyTitle,
  categories,
  clickOnLearnMoreBtn,
  getValueByIdFromLeaderSurvey,
  getCategoryName,
  getSubCategoryValueByKey,
  getCategoryDescription,
  managerEfficacyBGColor,
}) => {
  return (
    <div className='mb-52px'>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='assessment-chart text-center align-self-lg-center'>
          <AssessmentScoreCard
            title={managerEfficacyKeyTitle}
            linkClasses='s11 mb-0 text-dark-900-5 cursor-pointer'
            category={categories?.find(
              (e) => e._id === '62963961a279e16eeb3aa867',
            )}
            onLearnMoreClick={() => {
              clickOnLearnMoreBtn(
                categories?.find((e) => e._id === '62963961a279e16eeb3aa867'),
              )
            }}
            percentage={getValueByIdFromLeaderSurvey(
              '62963961a279e16eeb3aa867',
              'percent',
            )}
            backgroudColor={managerEfficacyBGColor}
            variant='table-attached-card'
            outerBackgroudColor='#E0E7DE'
            innerBackgroudColor='#84A07C'
            width='15.79rem'
            height='22.437rem'
          />
        </div>
        <div className='assessment-score mt-lg-0 mt-4'>
          {getValueByIdFromLeaderSurvey(
            '62963961a279e16eeb3aa867',
            'order',
          ).map((subcategoryId, index) => (
            <div
              className='survey-subcat-link cursor-pointer py-18px'
              key={index}>
              <div
                className={classNames('courses-title-row', {
                  'pt-0': index === 0,
                })}>
                <div className='courses-title'>
                  <span className='s6 text-black'>
                    {getCategoryName(subcategoryId)}
                  </span>
                </div>
                <div className='courses-score'>
                  <span className='text-manager-efficacy s6'>
                    {Number.isInteger(
                      getSubCategoryValueByKey(
                        getValueByIdFromLeaderSurvey(
                          '62963961a279e16eeb3aa867',
                          'subcategories',
                        ),
                        subcategoryId,
                        'percent',
                      ),
                    )
                      ? `${getSubCategoryValueByKey(
                          getValueByIdFromLeaderSurvey(
                            '62963961a279e16eeb3aa867',
                            'subcategories',
                          ),
                          subcategoryId,
                          'percent',
                        )}%`
                      : 'N/A'}
                  </span>
                  <InfoIconOverlay
                    contentText={getCategoryDescription(subcategoryId)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EfficacySubCategoryTable
