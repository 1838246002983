import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Form as RBForm } from 'react-bootstrap'
import { FieldArray, Form, Formik } from 'formik'
import { string, object, array } from 'yup'
import { FeatherLoader, nameRegex } from '@abroad/components'
import { UserSendRequestInput } from '../form'
import { emailRegex } from '../../utils/utility'
import { leader360Type } from '../../utils/constants'
import RequestFooterButtons from './RequestFooterButtons'

// const numberOfLAInput = 3
// const numberOfSimple360Input = 1
const numberOfInputs = 3

const getIntialValues = (numberOfInputs) => {
  return [
    ...Array.from({ length: numberOfInputs }, (x, i) => ({
      firstName: '',
      email: '',
    })),
  ]
}

const initalValueForLA = getIntialValues(numberOfInputs)

const initalValueForSimple360 = getIntialValues(numberOfInputs)

const SendRequestInput = ({
  type,
  requestData,
  canAddMembers = true,
  canViewResult = false,
}) => {
  let boundArrayHelpers
  const [initialValueOfSimple360, setInitialValueOfSimple360] = useState({
    [leader360Type.SIMPLE360]: initalValueForSimple360,
  })
  const [initialValueOfManager, setInitialValueOfManager] = useState({
    [leader360Type.LEADER]: initalValueForLA,
  })
  const [isLoading, setIsLoading] = useState(true)

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers
  }

  const getMapedArrayData = (data) =>
    data.map((user) => ({
      ...user,
      firstName:
        type === leader360Type.SIMPLE360
          ? user.receiverFirstName
          : user.firstName,
      email: type === leader360Type.SIMPLE360 ? user.receiverEmail : user.email,
      isDisabled: true,
      ...(type === leader360Type.LEADER && {
        hasRequestSent: true,
      }),
    }))

  const getArrayData = (data) => [
    ...getMapedArrayData(data),
    ...Array.from({ length: numberOfInputs - data.length }, (x, i) => ({
      firstName: '',
      email: '',
    })),
  ]

  const getMapedData = (data) => {
    if (type === leader360Type.SIMPLE360) {
      setInitialValueOfSimple360({
        [leader360Type.SIMPLE360]: getArrayData(data),
      })
    } else {
      setInitialValueOfManager({ [leader360Type.LEADER]: getArrayData(data) })
    }
    return
  }

  useEffect(() => {
    setIsLoading(true)
    getMapedData(requestData || [])
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getValidationSchema = () => {
    return array().of(
      object().shape(
        {
          firstName: string().when('email', {
            is: (val) => val,
            then: string().matches(nameRegex).max(35).required(),
            otherwise: () => string().matches(nameRegex).max(35).notRequired(),
          }),
          email: string().when('firstName', {
            is: (val) => val,
            then: string()
              .email('Please enter a valid Email address')
              .matches(emailRegex)
              .max(255)
              .required(),
            otherwise: () =>
              string().matches(emailRegex).max(255).notRequired(),
          }),
        },
        ['firstName', 'email'],
      ),
    )
  }

  const addNewInputs = () => {
    boundArrayHelpers.push({ firstName: '', email: '' })
  }

  const disabledParticularInput = (index, data) => {
    boundArrayHelpers.replace(index, data)
  }

  const validationSchema = object().shape({
    [leader360Type.SIMPLE360]: getValidationSchema(),
    [leader360Type.LEADER]: getValidationSchema(),
  })

  const getSimple360AndManagerInputs = (values, type) => {
    return (
      <div
        className={classNames({
          'request-wrapper': type === leader360Type.SIMPLE360,
        })}
        id='leader-360-inputs'>
        {values.map((user, index) => (
          <div className='d-flex w-100 request-inputs' key={index}>
            <Col className='px-0 col-4'>
              <UserSendRequestInput
                name={`${type}[${index}].firstName`}
                className='request-input user-input'
                index={index}
                key={index}
                canAddMembers={canAddMembers}
                canViewResult={canViewResult}
                type={type}
                labelProps={{
                  className: classNames('text-black', {
                    'mt-0': index === 0,
                  }),
                }}
              />
            </Col>
            <Col className='px-0 col-1'></Col>
            <Col className='ps-0 pe-2 col-7'>
              <UserSendRequestInput
                name={`${type}.${index}.email`}
                index={index}
                key={index}
                canAddMembers={canAddMembers}
                canViewResult={canViewResult}
                type={type}
                inputType='email'
                labelProps={{
                  className: classNames('text-black', {
                    'mt-0': index === 0,
                  }),
                }}
              />
            </Col>
          </div>
        ))}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-25'>
        <FeatherLoader width={60} />
      </div>
    )
  }

  return (
    <>
      <Formik
        initialValues={
          type === leader360Type.SIMPLE360
            ? initialValueOfSimple360
            : initialValueOfManager
        }
        validationSchema={validationSchema}
        onSubmit={() => {}}
        enableReinitialize>
        {({ handleSubmit, values }) => (
          <RBForm
            as={Form}
            onSubmit={handleSubmit}
            className='clearfix abroad-fs-exclude'>
            <section>
              <FieldArray
                name={
                  type === leader360Type.SIMPLE360
                    ? leader360Type.SIMPLE360
                    : leader360Type.LEADER
                }
                render={(arrayHelpers) => {
                  bindArrayHelpers(arrayHelpers)
                  return (
                    <>
                      {getSimple360AndManagerInputs(
                        type === leader360Type.SIMPLE360
                          ? values?.[leader360Type.SIMPLE360]
                          : values?.[leader360Type.LEADER],
                        type === leader360Type.SIMPLE360
                          ? leader360Type.SIMPLE360
                          : leader360Type.LEADER,
                      )}
                      <RequestFooterButtons
                        type={type}
                        addNewInputs={addNewInputs}
                        disabledParticularInput={disabledParticularInput}
                        canAddMembers={canAddMembers}
                        canViewResult={canViewResult}
                      />
                    </>
                  )
                }}
              />
            </section>
          </RBForm>
        )}
      </Formik>
    </>
  )
}

export default SendRequestInput
