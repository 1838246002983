import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { plans } from '@abroad/components'

import { UpgradePlanModalContext } from '../../utils/contexts'
import GrowthPlanBtn from './GrowthPlanBtn'
import CoachingPlanBtn from './CoachingPlanBtn'

const UpgradePlanModal = () => {
  const { show, onHide, data } = useContext(UpgradePlanModalContext)
  const buttonClasses = 'btn s8 text-uppercase py-12px'

  return (
    <Modal
      show={show}
      size='lg'
      onHide={onHide}
      aria-labelledby='upgrade-plan-modal'
      centered>
      <Modal.Body className='p-32px bg-abroad-blue text-white'>
        <div className='mb-12px'>
          <pre className='s2'>{data?.header}</pre>
        </div>
        <div className='s6a font-weight-500'>{data?.subHeader}</div>
        <div className='d-flex justify-content-between mt-52px align-items-center'>
          {data?.plan === plans.coaching.code ? (
            <CoachingPlanBtn
              buttonText={data?.btnText}
              buttonClasses={buttonClasses}
            />
          ) : (
            <GrowthPlanBtn
              buttonText={data?.btnText}
              buttonClasses={buttonClasses}
            />
          )}
          <div>
            <span
              className='icon icon-cross cursor-pointer'
              onClick={() => onHide()}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UpgradePlanModal
