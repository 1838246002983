import React, { useState } from 'react'
import { AddCardForm } from '@abroad/components'
import CreditCardsListing from './CreditCardsListing'
import API from '../../utils/API'

const ADD_CARD_STEPS = {
  ADD_CARD: 1,
  CARD_LISTING: 2,
}
const CardListing = ({
  cardId,
  setCardId,
  setIsToggleCardListing = () => {},
  isToggleCardListing = false,
}) => {
  const [steps, setSteps] = useState(ADD_CARD_STEPS.CARD_LISTING)
  return (
    <>
      {steps === ADD_CARD_STEPS.CARD_LISTING && (
        <CreditCardsListing
          changeStepHandler={() => {
            setSteps(ADD_CARD_STEPS.ADD_CARD)
            setCardId(null)
          }}
          selectedCardId={cardId}
          setSelectedCardId={setCardId}
        />
      )}
      {steps === ADD_CARD_STEPS.ADD_CARD && (
        <div className='user-payment mw-100 m-0'>
          <AddCardForm
            onSuccess={(cardId) => {
              setIsToggleCardListing(!isToggleCardListing)
              setCardId(cardId)
              setSteps(ADD_CARD_STEPS.CARD_LISTING)
            }}
            onGoBack={() => {
              setSteps(ADD_CARD_STEPS.CARD_LISTING)
            }}
            API={API}
          />
        </div>
      )}
    </>
  )
}

export default CardListing
