import React, { useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'

const PDFDownloadIcon = ({ pdfData, downloadKeySkillHandler }) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const { sessionId } = useParams()
  const Error = useErrorService()
  const handleDownloadPDF = async (pdfData) => {
    try {
      setIsDownloadLoading(true)
      const { data } = await API.programs.getURLForSkillPDF(
        pdfData?.subCategoryId,
        pdfData?._id,
        sessionId,
      )
      downloadKeySkillHandler(`${pdfData?.title} (PDF)`)
      if (data) {
        window.open(data.url.url)
        setIsDownloadLoading(false)
      }
    } catch (e) {
      setIsDownloadLoading(false)
      Error.showError(e)
    }
  }
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      className={classNames('d-flex align-self-center', {
        'download-icon-rotate pe-none': isDownloadLoading,
      })}
      onClick={() => handleDownloadPDF(pdfData)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20 39.5C30.7696 39.5 39.5 30.7696 39.5 20C39.5 9.23045 30.7696 0.5 20 0.5C9.23045 0.5 0.5 9.23045 0.5 20C0.5 30.7696 9.23045 39.5 20 39.5Z'
        stroke='black'
      />
      <line
        x1='11.5'
        y1='28.5'
        x2='28.5'
        y2='28.5'
        stroke='black'
        strokeLinecap='round'
      />
      <path
        d='M20.8462 25.6565C20.4539 26.2793 19.5461 26.2793 19.1538 25.6566L15.7693 20.2829C15.3499 19.617 15.8285 18.75 16.6155 18.75L23.3845 18.75C24.1715 18.75 24.6501 19.617 24.2307 20.2829L20.8462 25.6565Z'
        fill='black'
      />
      <line
        x1='20'
        y1='20.4287'
        x2='20'
        y2='11.0001'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default PDFDownloadIcon
