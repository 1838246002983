import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { Dropdown } from 'react-bootstrap'
import NotificationSection from './NotificationSection'
import NotificationIcon from './NotificationIcon'
import { CustomDropdownToggle } from '../../common'
import { NotificationCountContext } from '../../layout/contexts/NotificationProvider'
import { isLGScreen } from '../../../utils/utility'

const NotificationDropDown = ({ classes = '', showSidebar = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const screenSize = window.innerWidth - 32

  const { count: notificationCount } = useContext(NotificationCountContext)

  const onToggle = (isOpen) => {
    setIsDropdownOpen(isOpen)
  }

  const clickNotificationhandler = () => setIsDropdownOpen(false)

  const style = { width: screenSize }

  return (
    <React.Fragment>
      <Dropdown
        show={isDropdownOpen}
        className='z3 align-self-center'
        onToggle={onToggle}>
        <Dropdown.Toggle as={CustomDropdownToggle} id='dropdown-profile'>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            // eslint-disable-next-line no-script-url
            href='javascript:void(0)'
            className='text-dark-900 text-decoration-none d-flex'>
            <div
              className={classNames('notification-icon-wrapper', {
                active: isDropdownOpen,
              })}>
              <NotificationIcon
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                isActive={isDropdownOpen}
                isShowGoldCircle={
                  notificationCount &&
                  notificationCount !== 0 &&
                  !isDropdownOpen
                }
              />
            </div>
          </a>
        </Dropdown.Toggle>
        <Dropdown.Menu
          align={isLGScreen ? 'right' : 'center'}
          style={!isLGScreen ? style : {}}
          className={classNames('header-drop-down notification-dropdown p-2', {
            'sidebar-close-dropdown': !showSidebar,
            'sidebar-open-dropdown': showSidebar,
          })}>
          <NotificationSection
            clickNotificationhandler={clickNotificationhandler}
            isDropdownOpen={isDropdownOpen}
          />
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropDown
