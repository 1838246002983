import React from 'react'
import classNames from 'classnames'

const NotificationIcon = ({ isActive, isShowGoldCircle, onClick }) => {
  return (
    <div
      className={classNames('notification-icon-wrapper', {
        active: isActive,
      })}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill={isActive ? '#bf9000' : 'none'}
        className='cursor-pointer align-self-center notification-icon text-black'
        onClick={onClick}>
        <path
          d='M9.29125 18.4706C8.63632 18.4706 6.59584 18.4706 5.08407 18.4706C4.27859 18.4706 3.75569 17.5502 4.11591 16.768L5.04135 14.7584C5.34226 14.1049 5.49891 13.3852 5.49891 12.6546C5.49891 11.7491 5.49891 10.404 5.49891 9.05882C5.49891 6.70588 6.58244 2 12.0001 2C17.4177 2 18.5012 6.70588 18.5012 9.05882C18.5012 10.404 18.5012 11.7491 18.5012 12.6546C18.5012 13.3852 18.6579 14.1049 18.9588 14.7584L19.8842 16.768C20.2444 17.5502 19.7205 18.4706 18.9151 18.4706H14.7089M9.29125 18.4706C9.29125 20.8235 10.3748 22 12.0001 22C13.6254 22 14.7089 20.8235 14.7089 18.4706M9.29125 18.4706C10.9838 18.4706 14.7089 18.4706 14.7089 18.4706'
          stroke={isActive ? '#bf9000' : 'black'}
          strokeLinejoin='round'
        />
        {isShowGoldCircle && <circle cx='17' cy='4' r='4' fill='#BF9000' />}
      </svg>
    </div>
  )
}

export default NotificationIcon
