import React, { useState } from 'react'
import classNames from 'classnames'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'

const RestoreIntentionLink = ({
  onSuccess,
  setIsRestoring,
  isDeleting,
  intentionId,
  intention,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()

  const archiveIntention = async () => {
    try {
      const archived = false
      setIsLoading(true)
      setIsRestoring(true)
      const { data } = await API.intention.toggleArchive(intentionId, archived)
      if (data) {
        setIsLoading(false)
        setIsRestoring(false)
        onSuccess(intentionId, intention)
      }
    } catch (e) {
      setIsLoading(false)
      setIsRestoring(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <span
        className={classNames('restore-link text-saffron-700 s10a', {
          'cursor-pointer': !isLoading,
          'pe-none': isLoading || isDeleting,
        })}
        onClick={archiveIntention}>
        {isLoading ? 'restoring...' : 'restore'}
      </span>
    </>
  )
}

export default RestoreIntentionLink
