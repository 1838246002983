// guide: this file contains middel player UI, play prev/next come from MediaControler.js
import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import TagManager from 'react-gtm-module'
import {
  BackwardIcon,
  PreviousIcon,
  NextIcon,
  ForwardIcon,
  VolumeIcon,
  VolumeMuteIcon,
  useUserService,
} from '@abroad/components'
import AudioControl from './AudioControl'
import TrackAudioPlayer from '../../constants/events'
import ProgressBarMiddle from './ProgressBarMiddle'
import MediaControler from './MediaControler'
import VolumeBar from './VolumeBar'
import { LayoutContext } from '../../utils/contexts'

momentDurationFormatSetup(moment)

const MediaControlBarMiddle = React.memo(
  ({
    moduleInfo,

    isLoading,
    playPrev,
    playNext,

    setClickedTime,
    bufferSize,
    formatDuration,
  }) => {
    const {
      volumeLevel,
      setVolumeLevel,
      mediaObject,
      audio,
      duration,
      curTime,
    } = useContext(LayoutContext)
    const { user } = useUserService()
    const userId = user?.id
    if (!mediaObject) {
      return null
    }

    return (
      <>
        <div>
          <div className='middle-audio-bar'>
            <Row className='justify-content-center d-none d-lg-flex media-action'>
              <Col className='p-0 m-0'>
                <div className='controls d-none d-lg-block'>
                  <ProgressBarMiddle
                    curTime={moduleInfo?.id === mediaObject?.id ? curTime : 0}
                    duration={
                      moduleInfo?.id === mediaObject?.id
                        ? duration
                        : moduleInfo?.duration
                    }
                    setClickedTime={(time) => {
                      if (moduleInfo?.id === mediaObject?.id) {
                        setClickedTime(time)
                      }
                    }}
                    bufferSize={
                      moduleInfo?.id === mediaObject?.id ? bufferSize : 0
                    }
                  />
                  <div className='d-flex justify-content-between'>
                    <span className='font-open-sans font-light'>
                      {formatDuration(
                        moduleInfo?.id === mediaObject?.id ? curTime : 0,
                      )}
                    </span>
                    <span className='font-open-sans font-light'>
                      {formatDuration(
                        moduleInfo?.id === mediaObject?.id
                          ? duration
                          : moduleInfo?.duration,
                      )}
                    </span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center middle-control-bar-icons'>
                    <BackwardIcon
                      onClick={() => {
                        //GTM code
                        TagManager.dataLayer({
                          dataLayer: {
                            event: TrackAudioPlayer.event.audioPlayer,
                            eventProps: {
                              category: TrackAudioPlayer.category.audioPlayer,
                              action: 'User backward 15 sec. media',
                              label: 'Backward 15 sec. media',
                              value: `Backward 15 sec. media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page (-15 sec.)`,
                              userId,
                            },
                          },
                        })
                        audio.currentTime = audio.currentTime - 15
                      }}
                      isDisabled={moduleInfo?.id !== mediaObject?.id}
                      className='hover:text-saffron-700'
                    />
                    <PreviousIcon
                      isPreviousDisabled={
                        mediaObject.disabledControls ||
                        mediaObject?.isFirst ||
                        moduleInfo?.id !== mediaObject?.id
                      }
                      onClick={() => {
                        if (
                          mediaObject.disabledControls ||
                          !mediaObject?.isFirst ||
                          moduleInfo?.id !== mediaObject?.id
                        ) {
                          playPrev()
                        }
                      }}
                    />
                    <AudioControl
                      identifier='media-page'
                      moduleInfo={moduleInfo}
                      isLoading={isLoading}
                    />
                    <NextIcon
                      isNextDisabled={
                        mediaObject.disabledControls ||
                        mediaObject?.isLast ||
                        moduleInfo?.id !== mediaObject?.id
                      }
                      onClick={() => {
                        if (
                          !mediaObject?.isLast ||
                          moduleInfo?.id !== mediaObject?.id
                        ) {
                          playNext()
                        }
                      }}
                    />
                    <ForwardIcon
                      onClick={() => {
                        //GTM code
                        TagManager.dataLayer({
                          dataLayer: {
                            event: TrackAudioPlayer.event.audioPlayer,
                            eventProps: {
                              category: TrackAudioPlayer.category.audioPlayer,
                              action: 'User forward 15 sec. media',
                              label: 'Forward 15 sec. media',
                              value: `Forward 15 sec. media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page (+15 sec.)`,
                              userId,
                            },
                          },
                        })
                        audio.currentTime = audio.currentTime + 15
                      }}
                      isDisabled={moduleInfo?.id !== mediaObject?.id}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className='d-none mt-5 d-lg-flex media-action justify-content-end'>
            <div className='vol-bar-root'>
              {volumeLevel === 0 ? (
                <VolumeMuteIcon
                  onClick={() => {
                    setVolumeLevel(0.3)
                    //GTM code
                    TagManager.dataLayer({
                      dataLayer: {
                        event: TrackAudioPlayer.event.audioPlayer,
                        eventProps: {
                          category: TrackAudioPlayer.category.audioPlayer,
                          action: 'User unmute the media',
                          label: 'User unmute the media',
                          value: `Unmute icon media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
                          userId,
                        },
                      },
                    })
                  }}
                />
              ) : (
                <VolumeIcon
                  onClick={() => {
                    setVolumeLevel(0)
                    //GTM code
                    TagManager.dataLayer({
                      dataLayer: {
                        event: TrackAudioPlayer.event.audioPlayer,
                        eventProps: {
                          category: TrackAudioPlayer.category.audioPlayer,
                          action: 'User mute the media',
                          label: 'User mute the media',
                          value: `Mute icon media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
                          userId,
                        },
                      },
                    })
                  }}
                />
              )}
              <VolumeBar
                indentifier='-media-page'
                volumeCurTime={volumeLevel}
                setVolumeClickedTime={(time) => {
                  setVolumeLevel(time)
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default MediaControler(MediaControlBarMiddle)
