import React from 'react'
import classnames from 'classnames'

const BackwardIcon = ({
  isDisabled,
  wrapperClassName,
  className,
  fontSize = '22px',
  ...rest
}) => {
  return (
    <span className={classnames('d-flex align-items-start', wrapperClassName)}>
      <span
        className={classnames(
          'icon icon-backward text-white text-dark-900 hover:text-saffron-700',
          {
            'cursor-pointer': !isDisabled,
            'pointer-event-none': isDisabled,
          },
          className,
        )}
        style={{
          fontSize,
          ...(isDisabled && {
            color: 'rgba(255,255,255, 0.50)',
          }),
        }}
        {...rest}
      />
      <span className='s-captions'>15</span>
    </span>
  )
}

export default BackwardIcon
