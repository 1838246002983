import React from 'react'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import { BackwardIcon } from '@abroad/components'

import Header from '../layout/Header'

const versionEnum = {
  normal: 'normal',
  small: 'small',
}

const PageHeader = ({
  title,
  titleFn = () => {},
  version = versionEnum.normal,
  showBackArrow = false,
  backArrowAction = () => {},
  placement = 'bottom',
  wrapperClasses = '',
}) => {
  return (
    <Row
      className={classNames(
        'page-header',
        {
          'mt-3': versionEnum?.normal === version,
        },
        {
          'mt-4': versionEnum?.small === version,
        },
        wrapperClasses,
      )}>
      <Col className='text-start d-flex justify-content-between align-items-center'>
        <div>
          {version === versionEnum.normal ? (
            <div className='d-flex align-items-center'>
              {showBackArrow && (
                <BackwardIcon
                  onClick={backArrowAction}
                  className='me-2'
                  fontSize='1rem'
                />
              )}
              {title ? <div className='s1'>{title}</div> : titleFn()}
            </div>
          ) : (
            <div className='d-flex align-items-center'>
              {showBackArrow && (
                <BackwardIcon
                  onClick={backArrowAction}
                  className='me-2'
                  fontSize='1rem'
                />
              )}
              <span className='s-captions'>{title}</span>
            </div>
          )}
        </div>
        <Header />
      </Col>
    </Row>
  )
}

export default PageHeader
