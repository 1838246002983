import React, { useState, useEffect, useContext } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import {
  ShimmerButton,
  ShimmerTable,
  ShimmerThumbnail,
} from 'react-shimmer-effects'
import TagManager from 'react-gtm-module'
import {
  useErrorService,
  coachRecommendsUrl,
  NavTabs,
  useUserService,
} from '@abroad/components'
import {
  InfoCard,
  PlayIcon,
  JourneyAudiosListingTable,
  GroupJourneyDropdown,
} from '../components'
import API from '../utils/API'
import JouneysEvent from '../constants/events'
import { LayoutContext } from '../utils/contexts'
import { mediaModuleTypeObject } from '../utils/constants'
import { getAllProgramsKeysArray } from '../utils/utility'

const allProgramsKeyArray = getAllProgramsKeysArray()

const Journeys = ({ pageUrl = '', pages, setPages }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isGroupFetching, setIsGroupFetching] = useState(true)
  const [isShowGroupDropdown, setIsShowGroupDropdown] = useState(false)
  const [mediasList, setMediasList] = useState([])
  const [notes, setNotes] = useState('')
  const [journeyId, setJourneyId] = useState()
  const [groupList, setGroupList] = useState([])
  const [groupObject, setGroupObject] = useState()
  const {
    setMediaObject,
    setShowPlayBar,
    setContextJourneyId,
    setContextJourneyAudioId,
    setIsJourneyModuleAudio,
    setJourneyAudioType,
    journeyGroupId,
    setJourneyGroupId,
    isMediaLoading,
    setIsMediaLoading,
    mediaObject,
  } = useContext(LayoutContext)
  const Error = useErrorService()
  const location = useLocation()
  const history = useHistory()
  const { user } = useUserService()
  const userId = user?.id

  useEffect(() => {
    if (
      `${location?.pathname}${location?.hash}` ===
      `/${coachRecommendsUrl}#organization`
    ) {
      history.push('/home')
    }
  }, [location, history])

  useEffect(() => {
    const getUserJourneyMedia = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.journey.getJourney({
          tabType: pageUrl === 'individual' ? 'client' : 'company',
        })
        if (data) {
          setMediasList(Object.keys(data).length === 0 ? [] : data?.audios)
          setNotes(Object.keys(data).length === 0 ? '' : data?.notes)
          setJourneyId(data?._id)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getListOfGroups = async () => {
      try {
        setIsGroupFetching(true)
        const { data } = await API.journey.getListOfGroups()
        if (data) {
          setGroupList(
            data.map((e) => ({
              label: e?.teamName,
              value: e?.teamId,
            })),
          )
          if (journeyGroupId) {
            setGroupObject({
              value: journeyGroupId,
            })
          } else {
            const [firstObj] = data
            setGroupObject({
              label: firstObj?.teamName,
              value: firstObj?.teamId,
            })
            setJourneyGroupId(firstObj?.teamId)
          }
          setIsGroupFetching(false)
        }
      } catch (e) {
        setIsGroupFetching(false)
        Error.showError(e)
      }
    }
    if (pageUrl !== 'group') {
      getUserJourneyMedia()
    } else if (pageUrl === 'group') {
      getListOfGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUrl])

  useEffect(() => {
    const getJourneyByTeamId = async (media) => {
      try {
        setIsLoading(true)
        const { data } = await API.journey.getJourneyByTeamId(media?.value)
        if (data) {
          setMediasList(Object.keys(data).length === 0 ? [] : data?.audios)
          setNotes(Object.keys(data).length === 0 ? '' : data?.notes)
          setJourneyId(data?.id)
          setJourneyGroupId(media?.value)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    // add some extra condition of "groupObject?.value", because some time gets object as: {label: undefined, value: undefined}
    if (
      groupObject &&
      Object.keys(groupObject).length > 0 &&
      groupObject?.value
    ) {
      getJourneyByTeamId(groupObject)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObject])

  useEffect(() => {
    if (pages?.length === 0) return
    if (
      groupObject &&
      Object.keys(groupObject).length > 0 &&
      groupObject?.value &&
      pageUrl === 'group'
    ) {
      const updatedLinks = pages.map((page) =>
        page?.optionalName === 'group'
          ? {
              ...page,
              isDiscardTab: groupList?.length >= 1,
              name: 'group',
            }
          : page,
      )
      setPages(updatedLinks)
      setIsShowGroupDropdown(true)
    } else {
      const updatedLinks = pages.map((page) =>
        page?.optionalName === 'group'
          ? { ...page, name: 'group', isDiscardTab: false }
          : page,
      )
      setPages(updatedLinks)
      setIsShowGroupDropdown(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObject, pageUrl])

  const getMedia = async (
    mediaId,
    mediaSubCategoryId,
    isProgramMedia,
    sequenceId,
  ) => {
    if (isMediaLoading) return
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      let mediaData
      if (isProgramMedia) {
        mediaData = await API.programs.getMediaById(
          mediaSubCategoryId,
          mediaId,
          journeyId,
          sequenceId,
        )
        mediaData = mediaData?.data
      } else {
        mediaData = await API.mediaLibrary.getMediaById(
          mediaSubCategoryId,
          mediaId,
          journeyId,
          sequenceId,
        )
        mediaData = mediaData?.data
      }
      if (mediaData) {
        setContextJourneyId(journeyId)
        setContextJourneyAudioId(mediaData?.journey?.sequenceId)
        setIsJourneyModuleAudio(
          mediaData?.journey?.type === mediaModuleTypeObject?.MODULE,
        )
        setJourneyAudioType(mediaData?.journey?.journeyType)
        setMediaObject(mediaData)
      }
      setIsMediaLoading(false)
    } catch (e) {
      if (e.code === 'upgrade_plan' && !mediaObject) {
        setShowPlayBar(false)
      }
      setIsMediaLoading(false)
      Error.showError(e)
    }
  }

  const getModuleFirstMedia = async (moduleId, sequenceId) => {
    if (isMediaLoading) return
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      const { data } = await API.programs.getModuleFirstMedia(
        moduleId,
        journeyId,
        sequenceId,
      )
      if (data) {
        setContextJourneyId(journeyId)
        setContextJourneyAudioId(data?.journey?.sequenceId)
        setIsJourneyModuleAudio(
          data?.journey?.type === mediaModuleTypeObject?.MODULE,
        )
        setJourneyAudioType(data?.journey?.journeyType)
        setMediaObject(data)
      }
      setIsMediaLoading(false)
    } catch (e) {
      if (e.code === 'upgrade_plan' && !mediaObject) {
        setShowPlayBar(false)
      }
      setIsMediaLoading(false)
      Error.showError(e)
    }
  }

  const getAPIFunction = (
    type,
    mediaOrModuleId,
    subCategoryId,
    toggleFavoriteValue,
  ) => {
    if (type === mediaModuleTypeObject.MODULE) {
      return API.programs.toggleFavorite(
        {
          isFavorite: !toggleFavoriteValue,
        },
        mediaOrModuleId,
      )
    } else if (type === mediaModuleTypeObject.MODULE_MEDIA) {
      return API.programs.favoriteModuleAudio(subCategoryId, mediaOrModuleId, {
        isFavorite: !toggleFavoriteValue,
      })
    } else {
      // for media library audio
      return API.mediaLibrary.toggleFavorite(
        {
          isFavorite: !toggleFavoriteValue,
        },
        mediaOrModuleId,
      )
    }
  }

  const toggleIsFavorite = async (
    type,
    mediaOrModuleId,
    subCategoryId,
    toggleFavoriteValue,
  ) => {
    try {
      const { status } = await getAPIFunction(
        type,
        mediaOrModuleId,
        subCategoryId,
        toggleFavoriteValue,
      )
      if (status) {
        const updatedMediasList = mediasList.map((media) =>
          media?.mediaOrModuleId === mediaOrModuleId
            ? {
                ...media,
                mediaOrModuleDetail: {
                  ...media?.mediaOrModuleDetail,
                  isFavorite: !toggleFavoriteValue,
                },
              }
            : media,
        )
        setMediasList(updatedMediasList)
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const gtmPlayJourneysMediaAction = () => {
    if (pageUrl === 'individual') {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: JouneysEvent.event.userJourneysAudio,
          eventProps: {
            category: JouneysEvent.category.userJourneysAudioCategory,
            action: 'User play individual coach recommends audio',
            label: 'Play individual coach recommends audio by user',
            value: `User play individual coach recommends audio, journeyId is ${journeyId}.`,
            userId,
          },
        },
      })
    }
    // not changed for now.
    // else if (pageUrl === 'organization') {
    //   //GTM code
    //   TagManager.dataLayer({
    //     dataLayer: {
    //       event: JouneysEvent.event.orgJourneysAudio,
    //       eventProps: {
    //         category: JouneysEvent.category.orgJourneysAudioCategory,
    //         action: 'User play organization journeys audio',
    //         label: 'Play organization journeys audio by user',
    //         value: `User play organization journeys audio, journeyId is ${journeyId}.`,
    //         userId,
    //       },
    //     },
    //   })
    // }
    else {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: JouneysEvent.event.groupJourneysAudio,
          eventProps: {
            category: JouneysEvent.category.groupJourneysAudioCategory,
            action: 'User play group coach recommends audio',
            label: 'Play group coach recommends audio by user',
            value: `User play group coach recommends audio, journeyId is ${journeyId}, groupId is ${journeyGroupId}.`,
            userId,
          },
        },
      })
    }
  }

  if (isLoading) {
    return (
      <>
        <div className='d-flex journey-tabs'>
          <ShimmerButton size='lg' />
          <ShimmerButton size='lg' />
        </div>
        <div className={pages?.length > 0 ? 'mt-4' : 'mt-32px'}>
          <ShimmerThumbnail rounded />
        </div>
        <div className='mt-40px'>
          <ShimmerTable row={3} col={5} />
        </div>
      </>
    )
  }

  return (
    <>
      {pages?.length > 0 && (
        <>
          {isGroupFetching && pageUrl === 'group' ? (
            <div className='d-flex journey-tabs'>
              <ShimmerButton size='lg' />
              <ShimmerButton size='lg' />
            </div>
          ) : (
            <div className='d-flex mt-3'>
              <NavTabs
                pages={pages}
                pathname={location?.pathname}
                hash={location?.hash}
                NavLink={NavLink}
                tabWrapperClasses='mt-0 header-tabs'
              />
              {isShowGroupDropdown && (
                <GroupJourneyDropdown
                  groupList={groupList}
                  setGroupObject={setGroupObject}
                />
              )}
            </div>
          )}
        </>
      )}
      <section className={pages?.length > 0 ? 'mt-4' : 'mt-32px'}>
        <InfoCard notes={notes} />
        {mediasList?.length > 0 && (
          <>
            <PlayIcon
              wrapperClasses='my-4 d-flex justify-content-end'
              iconClasses='font-48'
              isGoldIcon={true}
              onClick={() => {
                // first audio will play
                gtmPlayJourneysMediaAction()
                if (mediasList[0]?.type === mediaModuleTypeObject?.MODULE) {
                  getModuleFirstMedia(
                    mediasList[0]?.mediaOrModuleId,
                    mediasList[0]?._id,
                  ) // moduleId, sequenceId
                } else {
                  getMedia(
                    mediasList[0]?.mediaOrModuleId, // mediaId
                    mediasList[0]?.mediaOrModuleDetail?.subCategoryId?._id, // mediaSubCategoryId
                    allProgramsKeyArray.includes(
                      mediasList[0]?.mediaOrModuleDetail?.categoryId?.key,
                    ), // isProgramMedia
                    mediasList[0]?._id, // sequenceId
                  )
                }
              }}
            />
            <JourneyAudiosListingTable
              mediasList={mediasList}
              getModuleFirstMedia={getModuleFirstMedia}
              playJourneysMediaHandler={gtmPlayJourneysMediaAction}
              getMedia={getMedia}
              toggleIsFavorite={toggleIsFavorite}
            />
          </>
        )}
      </section>
    </>
  )
}

export default Journeys
