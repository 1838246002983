import React, { useEffect, useState } from 'react'
import { ShimmerButton, ShimmerTable } from 'react-shimmer-effects'
import { AddCardForm, useErrorService } from '@abroad/components'
import CardListingTable from './CardListingTable'
import AddNewCardButton from './AddNewCardButton'
import API from '../../utils/API'

const CARD_STEPS = {
  CARD_LISTING: 1,
  ADD_CARD: 2,
}

const UserCards = ({ isToggleCardListing }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [cards, setCards] = useState([])
  const Error = useErrorService()
  const [steps, setSteps] = useState(CARD_STEPS.CARD_LISTING)

  useEffect(() => {
    const getUserCardListing = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.payment.getUserCardListing()
        if (data) {
          setCards(data?.data)
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (steps === CARD_STEPS.CARD_LISTING) getUserCardListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, isToggleCardListing])

  const renderPageTitle = () => {
    return <h3 className='s3 mt-32px'>My Cards</h3>
  }

  if (isLoading) {
    return (
      <>
        {renderPageTitle()}
        <ShimmerTable row={2} col={3} />
        <ShimmerButton size='md' />
      </>
    )
  }
  return (
    <section>
      {renderPageTitle()}
      {cards?.length > 0 && steps === CARD_STEPS.CARD_LISTING && (
        <CardListingTable
          cards={cards}
          removeCardHandler={(cardId) => {
            setCards((prev) => prev.filter((card) => card?.id !== cardId))
          }}
        />
      )}
      {steps === CARD_STEPS.ADD_CARD && (
        <div className='user-payment position-relative mw-100 m-0'>
          <AddCardForm
            onSuccess={() => setSteps(CARD_STEPS.CARD_LISTING)}
            onGoBack={() => {
              setSteps(CARD_STEPS.CARD_LISTING)
            }}
            API={API}
          />
        </div>
      )}
      {steps === CARD_STEPS.CARD_LISTING && (
        <AddNewCardButton
          btnClasses='mt-3 border-radius-normal'
          onClick={() => setSteps(CARD_STEPS.ADD_CARD)}
        />
      )}
    </section>
  )
}

export default UserCards
