import React from 'react'
import classNames from 'classnames'
import { plans } from '@abroad/components'
import GrowthPlanBtn from './GrowthPlanBtn'
import CoachingPlanBtn from './CoachingPlanBtn'

const UpgradeNow = ({
  wrapperClasses = '',
  title,
  titleFn = () => {},
  titleClasses = '',
  subTitle,
  subTitleFn = () => {},
  subTitleClasses = '',
  buttonText = 'UPGRADE NOW',
  buttonClasses = '',
  plan = plans.growth.code,
}) => {
  return (
    <div
      className={classNames(
        'upgrade-now bg-abroad-blue text-white',
        wrapperClasses,
      )}>
      {title ? (
        <h3
          className={classNames(
            's2 text-center text-white mb-0',
            titleClasses,
          )}>
          {title}
        </h3>
      ) : (
        titleFn()
      )}
      {subTitle ? (
        <h5 className={classNames('mt-3 s5 text-center mb-0', subTitleClasses)}>
          {subTitle}
        </h5>
      ) : (
        subTitleFn()
      )}
      <div className='text-center'>
        {plan === plans.coaching.code ? (
          <CoachingPlanBtn
            buttonText={buttonText}
            buttonClasses={classNames(
              'btn s10a text-uppercase leading-4 py-12px',
              {
                'onbording-action-btn': subTitle,
                'mt-32px': !subTitle,
              },
              buttonClasses,
            )}
          />
        ) : (
          <GrowthPlanBtn
            buttonText={buttonText}
            buttonClasses={classNames(
              'btn s10a text-uppercase leading-4 py-12px',
              {
                'onbording-action-btn': subTitle,
                'mt-32px': !subTitle,
              },
              buttonClasses,
            )}
          />
        )}
      </div>
    </div>
  )
}

export default UpgradeNow
