import React, { useState } from 'react'
import moment from 'moment'
import { isLGScreen } from '../../utils/utility'

const ProgressBar = ({
  curTime,
  duration,
  setClickedTime,
  bufferSize,
  identifier,
  isDisabled,
}) => {
  const [hover, setHover] = useState(false)
  const bufferColor = '#6F6F6F'
  const remainingBarColor = '#6F6F6F'
  const curPercentage = (curTime / duration) * 100

  const formatDuration = (duration) => {
    return moment.duration(duration, 'seconds').format('mm:ss', { trim: false })
  }

  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX
    let bar = document.querySelector(`#media-control-progress-bar${identifier}`)
    if (!bar) {
      bar = document.querySelector(`#media-control-progress-bar-2${identifier}`)
    }
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
    return timePerPixel * clickPositionInBar
  }

  const handleTimeDrag = (e) => {
    setClickedTime(calcClickedTime(e))

    const updateTimeOnMove = (eMove) => {
      setClickedTime(calcClickedTime(eMove))
    }

    document.addEventListener('mousemove', updateTimeOnMove)

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove)
    })
  }

  if (!isLGScreen) {
    return (
      <>
        <div className='bar mb-2'>
          <div
            className={`bar-progress ${isDisabled ? 'disabled' : ''}`}
            id={`media-control-progress-bar-2${identifier}`}
            style={{
              background: !isDisabled
                ? `linear-gradient(to right, rgba(255,255,255,0.30) ${bufferSize}%, #6F6F6F 0), linear-gradient(to right, white ${curPercentage}%, #6F6F6F 0)`
                : '',
            }}
            onMouseDown={handleTimeDrag}>
            {!isDisabled && (
              <span
                className='bar-progress-knob'
                style={{ left: `calc(${curPercentage}% - 7px)` }}
              />
            )}
          </div>
        </div>
        <div className='bar-time d-flex justify-content-between'>
          <span className='s11 opacity-50'>{formatDuration(curTime)}</span>
          <span className='s11 opacity-50'>{formatDuration(duration)}</span>
        </div>
      </>
    )
  }

  const toggleHover = () => {
    setHover(!hover)
  }

  return (
    <div className='bar mb-2'>
      <span className='s11 opacity-50'>{formatDuration(curTime)}</span>
      <div
        className={`bar-progress ${isDisabled ? 'disabled' : ''}`}
        id={`media-control-progress-bar${identifier}`}
        style={{
          background: !isDisabled
            ? `linear-gradient(to right, rgba(255,255,255,0.30) ${bufferSize}%, ${remainingBarColor} 0), linear-gradient(to right, ${
                hover ? '#EFC030' : 'white'
              } ${curPercentage}%, ${bufferColor} 0)`
            : '',
        }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onMouseDown={handleTimeDrag}>
        {!isDisabled && (
          <span
            className='bar-progress-knob'
            style={{ left: `calc(${curPercentage}% - 7px)` }}
          />
        )}
      </div>
      <span className='s11 opacity-50'>{formatDuration(duration)}</span>
    </div>
  )
}

export default ProgressBar
