import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Button } from '@abroad/components'
import {
  OnboardTitle,
  OnboardSubTitle,
  AbroadGoldenIcon,
} from '../components/onboarding'
import { InviteDetailsContext } from '../utils/contexts'

const Welcome = ({ history, location: { state } }) => {
  const { inviteLinkDetails } = useContext(InviteDetailsContext)
  useEffect(() => {
    if (!state?.email) {
      history.replace('/')
    }
  }, [state, history])

  return (
    <>
      <Helmet>
        <title>{'Welcome | Abroad'}</title>
        <meta name='title' content={'Welcome | Abroad'}></meta>
        <meta property='og:title' content={'Welcome | Abroad'}></meta>
        <meta property='twitter:title' content={'Welcome | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/welcome`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/welcome`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/welcome`}></meta>
      </Helmet>
      <main>
        <section>
          <Container fluid className='px-0'>
            <Row className='vh-100 row g-0'>
              <Col className='onboard bg-abroad-blue'>
                <div className='h-100 d-flex flex-column align-items-center'>
                  <AbroadGoldenIcon />
                  <section className='onboard-wrapper'>
                    <OnboardTitle className='mt-32px'>{`Welcome, ${state?.firstName}`}</OnboardTitle>
                    <OnboardSubTitle className='mt-3 opacity-75'>
                      Your Abroad account has successfully been created.
                    </OnboardSubTitle>
                    <Button
                      onClick={() => {
                        history.replace('/home')
                      }}
                      {...(inviteLinkDetails?.buttonColorHex
                        ? { variant: 'none' }
                        : { variant: 'saffron' })}
                      {...(inviteLinkDetails?.buttonColorHex && {
                        style: {
                          backgroundColor: inviteLinkDetails?.buttonColorHex,
                          color: inviteLinkDetails?.fontColorHex,
                          borderColor: inviteLinkDetails?.buttonColorHex,
                        },
                      })}
                      className={classNames(
                        'btn w-100 s9 continue-abroad-btn',
                        {
                          'btn-custom': inviteLinkDetails?.buttonColorHex,
                        },
                      )}>
                      Continue to abroad
                    </Button>
                  </section>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Welcome
