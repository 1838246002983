import React from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { leaderAssessments, leaderAssessmentsUrl } from '@abroad/components'
import { PageHeader } from '../common'
import PendingAssessments from './PendingAssessments'

const title = 'Awaiting Your Leadership Insights'
const description = `Your Leader Assessment is in progress. Thank you for your initiative to deepen your understanding of your leadership qualities. Once at least three responses have been logged, your comprehensive results will become available. To ensure timely feedback, consider inviting more direct reports to participate. The more insights you gather, the richer your learning experience with Abroad will be.`

const PendingLA = ({ canCreateNewLAReq, pages }) => {
  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{`Pending ${leaderAssessments} | Abroad`}</title>
        <meta
          name='title'
          content={`Pending ${leaderAssessments} | Abroad`}></meta>
        <meta
          property='og:title'
          content={`Pending ${leaderAssessments} | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`Pending ${leaderAssessments} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}/pending`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}/pending`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}/pending`}></meta>
      </Helmet>
    )
  }

  return (
    <>
      {renderHelmet()}
      <main>
        <PageHeader title={leaderAssessments} />
        <section className='mt-32px'>
          <h3 className='mb-12px s3'>{title}</h3>
          <p
            className={classNames('s5', {
              'mb-42px': pages?.length > 1,
              'mb-52px': pages?.length === 1,
            })}>
            {description}
          </p>
          <PendingAssessments
            pages={pages}
            canCreateNewLAReq={canCreateNewLAReq}
          />
        </section>
      </main>
    </>
  )
}

export default PendingLA
