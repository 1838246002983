import React, { useContext, useEffect, useState, useMemo } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Imgix from 'react-imgix'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import { useErrorService } from '@abroad/components'
import { isLGScreen } from '../../utils/utility'
import imgCrop from '../../constants/imgCrop'
import { LayoutContext, UpgradePlanModalContext } from '../../utils/contexts'
import { programIds } from '@abroad/components'
import API from '../../utils/API'
import ModuleMiniDetails from './ModuleMiniDetails'
import ModuleActions from './ModuleActions'

const ProgramModule = React.memo(
  ({
    program,
    isLoading = false,
    programId,
    // showFavIcon = true, // deprecated
  }) => {
    const [isFavorite, setIsFavorite] = useState(program?.isFavorite)
    const [playIconLoading, setPlayIconLoading] = useState(false)
    const {
      setMediaObject,
      setShowPlayBar,
      setContextJourneyId,
      mediaObject,
      setIsMediaLoading,
    } = useContext(LayoutContext)

    const { onShow, setModalData } = useContext(UpgradePlanModalContext)

    const pId = useParams().programId || programId
    const Error = useErrorService()

    const imgCropFP = useMemo(() => {
      return imgCrop[pId] ? imgCrop[pId] : null
    }, [pId])

    useEffect(() => {
      if (program) {
        setIsFavorite(program?.isFavorite)
      }
    }, [program])

    const toggleIsFavorite = async (e) => {
      try {
        if (program?.isUpgradeRequired) {
          openUpgradePlanModal(e)
          return
        }
        e.preventDefault()
        e.stopPropagation()
        const prevFav = isFavorite
        setIsFavorite((prev) => !prev)
        const { data } = await API.programs.toggleFavorite(
          {
            isFavorite: !isFavorite,
          },
          program?._id,
        )
        if (!data.status) {
          setIsFavorite(prevFav)
        }
      } catch (e) {
        Error.showError(e)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const getModuleFirstMedia = async (e) => {
      try {
        e.preventDefault()
        e.stopPropagation()
        if (playIconLoading) return
        setPlayIconLoading(true)
        setIsMediaLoading(true)
        setShowPlayBar(true)
        const { data } = await API.programs.getModuleFirstMedia(program?._id)
        if (data) {
          setContextJourneyId(null)
          setMediaObject(data)
          setIsMediaLoading(false)
        }
        setPlayIconLoading(false)
      } catch (e) {
        if (e.code === 'upgrade_plan' && !mediaObject) {
          setShowPlayBar(false)
        }
        setPlayIconLoading(false)
        setIsMediaLoading(false)
        Error.showError(e)
      }
    }

    const openUpgradePlanModal = (e) => {
      e.preventDefault()
      e.stopPropagation()
      let header, subHeader
      switch (pId) {
        case programIds.breakthroughProgramId:
          header = 'Dive Deeper with \nthe Full Breakthrough Program'
          subHeader = `You've tasted the transformative essence of the "Breakthrough" program's introductory module. Ready for more? This experience, masterminded by the former Director of Wellbeing at Yale and the CEO of Abroad, is tailored to redefine the mindsets that could be obstructing your true potential. Delve further to harness fear as a source of creativity, amplify your perception of time, and imbue a growth mindset into every leadership encounter.`
          break
        case programIds.evolutionProgramId:
          header = 'Explore the \nFull Depth of Evolution'
          subHeader = `You've embarked on a journey with the introductory module of the "Evolution" program. Curious to uncover the rest? Developed by renowned mindfulness and trauma experts, the subsequent phases invite you to thoroughly understand and nurture your three centers of intelligence. Progress to pacify inner critics, temper triggers, and evolve from a reactive to a responsive leader.`
          break
        case programIds.resilienceProgramId:
          header = 'Unlock Trauma Informed \nLeadership with Expert Coaching'
          subHeader = `Dive deeply into the "Trauma Informed Leadership" program, a curated experience addressing burnout prevention, emotional resilience, and the cultivation of a trauma-informed work culture. Developed to be intimately paired with expert coaching, this program ensures you understand and apply key concepts effectively in real-world scenarios.`
          break
        default:
          break
      }
      setModalData({
        header,
        subHeader,
        btnText: 'Learn More About Coaching',
        plan: 'coaching',
      })
      onShow()
    }

    if (isLoading) {
      return (
        <div
          ref={(ref) => {
            if (ref) {
              let divider = isLGScreen ? 5 : 2.5
              ref.getElementsByClassName('shimmer')[0].style.height = `${
                ref.getElementsByClassName('shimmer')[0].offsetWidth / divider
              }px`
            }
          }}
          className='w-100'>
          <ShimmerThumbnail rounded className='border-radius-5' />
        </div>
      )
    }

    return (
      <NavLink
        to={`/module/${pId}/${program?._id}`}
        className='text-decoration-none d-block'
        onClick={(e) => {
          setContextJourneyId(null)
          if (program?.isUpgradeRequired) {
            openUpgradePlanModal(e)
          }
        }}>
        <div className='p-32px border-radius-5 bg-gray-100 program-wrp cursor-pointer position-relative'>
          {/* <div class='z20 cursor-pointer inset-0 position-absolute left-0 top-0 pl-32px'> */}
          <div className='z20 cursor-pointer mb-4'>
            <ModuleActions
              className='font-24'
              isFavorite={isFavorite}
              toggleIsFavorite={toggleIsFavorite}
              rightMostIcon={
                !program?.isUpgradeRequired ? 'icon-play' : 'icon-padlock'
              }
              handleRightMostIconClick={(e) =>
                !program?.isUpgradeRequired
                  ? getModuleFirstMedia(e)
                  : openUpgradePlanModal(e)
              }
              playIconLoading={playIconLoading}
            />
          </div>
          <ModuleMiniDetails
            details={{
              label1: `${program?.subTitle?.toLowerCase()}`,
              label2: `${program?.mediaCount} Audios`,
              label3: `${parseInt(program?.totalDuration / 60)} Minutes`,
            }}
          />
          <div className='d-flex justify-content-between align-items-center mb-4 text-black'>
            <div className='s3'>{program?.title}</div>
          </div>
          <div className='position-relative overflow-hidden breakthrough-media breakthrough-card'>
            <Imgix
              className='lazyload border-radius-5 w-100 h-auto img-cover program-img-zoom'
              imgixParams={{
                fit: 'crop',
                crop:
                  imgCropFP && typeof imgCropFP[program?.subTitle] === 'object'
                    ? 'focalpoint'
                    : 'edges',
                ...(imgCropFP &&
                  typeof imgCropFP[program?.subTitle] === 'object' &&
                  (isLGScreen
                    ? imgCropFP[program?.subTitle]['LGScreen']
                    : imgCropFP[program?.subTitle]['MDScreen'])),
                ar: isLGScreen ? '5:1' : '5:2',
              }}
              width={'100%'}
              src={`${process.env.REACT_APP_IMG_SOURCE}/${program?.imageFilename}`}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/${
                  program?.imageFilename
                }?ar=${isLGScreen ? '5:1' : '5:2'}&crop=${
                  imgCropFP && typeof imgCropFP[program?.subTitle] === 'object'
                    ? 'focalpoint'
                    : 'edges'
                }${
                  imgCropFP && typeof imgCropFP[program?.subTitle] === 'object'
                    ? isLGScreen
                      ? `&fp-x=${
                          imgCropFP[program?.subTitle]['LGScreen']['fp-x']
                        }&fp-y=${
                          imgCropFP[program?.subTitle]['LGScreen']['fp-y']
                        }`
                      : `&fp-x=${
                          imgCropFP[program?.subTitle]['MDScreen']['fp-x']
                        }&fp-y=${
                          imgCropFP[program?.subTitle]['MDScreen']['fp-y']
                        }`
                    : ''
                }&fit=crop&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            {/* <div className='position-absolute color-black inset-0 border-radius-5 hover:bg-white hover:bg-opacity-25'></div> */}
          </div>
        </div>
      </NavLink>
    )
  },
)

export default ProgramModule
