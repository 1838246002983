import React from 'react'
import classnames from 'classnames'

const OnboardTitle = ({ className, children }) => {
  return (
    <div className={classnames('s1 text-white', className)}>{children}</div>
  )
}

export default OnboardTitle
