import React from 'react'
import classNames from 'classnames'

const PlayIcon = ({
  wrapperClasses = '',
  iconClasses = '',
  isGoldIcon = false,
  onClick,
}) => {
  return (
    <div className={classNames(wrapperClasses)}>
      <span
        className={classNames(
          'icon cursor-pointer',
          {
            'icon-play-media-gold': isGoldIcon,
            'icon-play-media': !isGoldIcon,
          },
          iconClasses,
        )}
        onClick={onClick}>
        <span className='path1'></span>
        <span className='path2'></span>
      </span>
    </div>
  )
}

export default PlayIcon
