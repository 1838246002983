import React from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import classnames from 'classnames'
import { placeholders } from '../../constants/form'
const Checkbox = ({
  formGroupProps,
  labelProps: { className: labelClassName, ...other } = {},
  label,
  custom,
  ...rest
}) => {
  const [field] = useField(rest)
  const formText = placeholders

  return (
    <Form.Group
      {...formGroupProps}
      className={classnames({ 'custom-checkbox': custom })}>
      <Form.Check
        id={`custom-${rest?.name}`}
        custom={custom}
        type={'checkbox'}
        className={classnames('cursor-pointer', rest?.className)}
        {...field}
        {...rest}>
        <Form.Check.Input
          type={'checkbox'}
          className='z3'
          {...field}
          {...rest}
        />
        <Form.Check.Label
          className={classnames('s8', labelClassName)}
          style={{ paddingTop: '1px' }}
          {...other}>
          {formText[label]}
        </Form.Check.Label>
      </Form.Check>
    </Form.Group>
  )
}

Checkbox.defaultProps = {
  custom: true,
}

export default Checkbox
